import { getReport } from 'api/customAxios';
import PaginationLIB from 'components/pagination/paginationLIB';
import debounce from 'lodash.debounce';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export default function VictimInfo() {
  const [victimSearchKeyword, setVictimSearchKeyword] = useState('');
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [victimData, setVictimData] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const debouncedsetVictimSearchKeyword = debounce(setVictimSearchKeyword, 100);

  const handlePageChange = (cPage) => {
    setPageNo(cPage - 1);
  };

  const getVictimList = () => {
    try {
      (async () => {
        const data = await getReport(pageNo, pageSize)(victimSearchKeyword);
        setVictimData(data.content);
        setTotalElements(data?.totalElements);
        setTotalPages(data?.totalPages);
      })();
    } catch (e) {
      alert(`
      ${e}
      내부 오류로인해 게시글을 받아오지 못했습니다.
      `);
    }
  };

  const getSearchVictim = (e) => {
    e.preventDefault();
    try {
      (async () => {
        const data = await getReport(0, 10)(victimSearchKeyword);
        setVictimData(data.content);
        setTotalElements(data.totalElements);
        setTotalPages(data.totalPages);
      })();
    } catch (e) {
      alert(`
      ${e}
      내부 오류로인해 게시글을 받아오지 못했습니다.
      `);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getVictimList, [pageNo, pageSize]);

  return (
    /*
        기획에 없던 페이지 임시 추가입니다.
    */
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1em',
      }}
    >
      <p>알림: 기존엔 신고일자를 따로 받지않았었습니다.</p>
      <p>
        새롭게 신고일자를 추가함에따라 기존 27개의 신고데이터는 2023-03-27로
        신고일자를 일치시켜 임시로 데이터를 넣었습니다.
      </p>
      <p style={{ fontWeight: 'bold' }}>
        처음 신고된 27개의 데이터의 신고일자는 실제 신고일자와 다르니
        참고해주세요.
      </p>
      <p style={{ fontWeight: 'bold', color: 'red' }}>
        또한 현재 관리자페이지는 세세한 화면대응은 해두지 않은 상태이기에 모니터
        크기에따라 화면이 깨져 보일수가 있습니다.
      </p>
      <SearchArea onSubmit={getSearchVictim}>
        <div>
          <input
            onChange={(e) => {
              debouncedsetVictimSearchKeyword(e.target.value);
            }}
            placeholder="이름으로 검색하세요."
          />
        </div>
        <SearchBtn type="submit">검색</SearchBtn>
      </SearchArea>
      <div
        style={{
          display: 'flex',
          gap: '1em',
          alignItems: 'center',
          width: '80%',
          fontWeight: 'bold',
        }}
      >
        <span
          style={{
            width: '105px',
          }}
        >
          성명
        </span>
        <span
          style={{
            width: '100px',
          }}
        >
          생년월일
        </span>
        <span
          style={{
            width: '140px',
          }}
        >
          피해내용
        </span>
        <span
          style={{
            width: '110px',
          }}
        >
          피해금액
        </span>
        <span
          style={{
            width: '110px',
          }}
        >
          피해발생 시간
        </span>
        <span
          style={{
            width: '180px',
          }}
        >
          처음 접촉방식 및 시간
        </span>
        <span
          style={{
            width: '110px',
          }}
        >
          휴대폰 기종
        </span>
        <span
          style={{
            width: '110px',
          }}
        >
          시큐어 앱 접촉 경로 및 검색키워드
        </span>
        <span
          style={{
            width: '110px',
          }}
        >
          상담가능번호
        </span>
        <span
          style={{
            width: '80px',
          }}
        >
          신고일자
        </span>
      </div>
      {victimData.map((victim) => (
        <InfoArea key={victim.id}>
          <div style={{ display: 'flex', gap: '1em' }}>
            <p style={{ width: '100px', fontWeight: 'bold' }}>{victim.name}</p>
            <p style={{ width: '100px' }}>{victim.birthday}</p>
            <p
              style={{
                width: '150px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {victim.content}
            </p>
            <p
              style={{
                width: '110px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {victim.damageOccurrence}
            </p>
            <p
              style={{
                width: '180px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                marginRight: '1em',
              }}
            >
              {victim.damageOccurrenceTime}
            </p>
            <p
              style={{
                width: '110px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {victim.firstContactTime}
            </p>
            <p
              style={{
                width: '90px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {victim.phoneModel}
            </p>
            <p
              style={{
                width: '110px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {victim.secureAppContactPath}
            </p>
            <p
              style={{
                width: '110px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {victim.victimNumber}
            </p>
            <p
              style={{
                width: '110px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {victim.createdDate?.slice(0, 10)}
            </p>
          </div>
        </InfoArea>
      ))}

      <div>
        <PaginationLIB
          handlePageChange={handlePageChange}
          totalElements={totalElements}
          totalPages={totalPages}
          pageNo={pageNo}
          pageSize={pageSize}
        />
      </div>
    </div>
  );
}

const SearchArea = styled.form`
  background-color: #ececec;
  width: 945.45px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4em 0 0.4em 0;
  margin-bottom: 0.6em;
  border-radius: 10px;
  gap: 10px;

  div {
    position: relative;
    input {
      width: 560.72px;
      height: 28.63px;
      padding-left: 1.1em;
    }

    span {
      position: absolute;
      left: 3%;
      bottom: 32%;
      color: #7a7a7a;
      font-size: 0.9rem;

      b {
        color: #1588dc;
      }
    }
  }
`;

const SearchBtn = styled.button`
  background: #1588dc;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;

  padding: 0.6em 2.2em;
  font-size: 0.8rem;
  font-weight: 300;
`;

const InfoArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  border-bottom: 0.5px solid grey;
  padding: 0.4em 0;
`;
