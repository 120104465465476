import { css } from 'styled-components';

export const columnDisplay = css`
  display: flex;
  flex-direction: column;
`;

export const columnDisplayCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const alignCenterDisplay = css`
  display: flex;
  align-items: center;
`;

export const betweenDisplay = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const centerDisplay = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
