import { getAnalysisList } from 'api/customAxios';
import BK from 'assets/browserImg/mainpageImg/analImg/bk.png';
import { apkDataState } from 'atoms/apkDataState';
import { apkSearchKeywordState } from 'atoms/apkSearchKeywordState';
import { totalElementsState } from 'atoms/totalElementsState';
import { totalPagesState } from 'atoms/totalPagesState';
import useFocus from 'hooks/useFocus';
import useNavi from 'hooks/useNavi';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import List from './list';

export default function AnalysisInfo() {
  const navigate = useNavi();
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(4);
  const [apkData, setApkData] = useRecoilState(apkDataState); // 검색용
  const [apkListData, setApkListData] = useState([]); // 4개짜리
  const [totalElements, setTotalElements] = useRecoilState(totalElementsState);
  const [totalPages, setTotalPages] = useRecoilState(totalPagesState);
  const [inputFocus, setSearchInputFocus] = useFocus();
  const [apkSearchKeyword, setApkSearchKeyword] = useRecoilState(
    apkSearchKeywordState,
  );
  const debouncedsetApkSearchKeyword = debounce(setApkSearchKeyword, 200);
  const [searchBool, setSearchBool] = useState(false);

  const getApkList = () => {
    try {
      (async () => {
        const { data } = await getAnalysisList(pageNo, 4)('');
        setApkListData(data);
      })();
    } catch (e) {
      alert(`
      ${e}
      내부 오류로인해 게시글을 받아오지 못했습니다.
      `);
    }
  };
  useEffect(getApkList, [pageNo]);

  const changeToSearchP = useCallback(() => {
    navigate('/analysis');
  }, [navigate]);

  useEffect(() => {
    if (searchBool) {
      setSearchBool(false);
      changeToSearchP();
    }
  }, [searchBool, changeToSearchP]);
  const getSearchApk = (e) => {
    e.preventDefault();
    try {
      (async () => {
        const { data } = await getAnalysisList(1, 9)(apkSearchKeyword);
        setApkData(data);
        setTotalElements(data.totalElements);
        setTotalPages(data.totalPages);
        setSearchBool(true);
      })();
    } catch (e) {
      alert(`
      ${e}
      내부 오류로인해 게시글을 받아오지 못했습니다.
      `);
    }
  };

  // 임시
  const arr = new Array(4).fill(0);
  return (
    <AnalysisInfoArea>
      <div>
        <SearchForm onSubmit={getSearchApk}>
          <input
            placeholder="피해파일명을 입력해주세요"
            ref={inputFocus}
            onBlur={() => {
              setSearchInputFocus((focus) => !focus);
            }}
            onChange={(e) => {
              debouncedsetApkSearchKeyword(e.target.value);
            }}
          />
          <button type="submit">검색</button>
        </SearchForm>
        <div>
          {apkListData?.content?.map((apk) => (
            <List key={apk.id} apk={apk} />
          ))}
        </div>
      </div>
      <ImgBox>
        <img src={BK} alt="NO_IMAGE" />
      </ImgBox>
      {/* 캐러셀에서 임대표님 나오신게 1번으로 */}
    </AnalysisInfoArea>
  );
}

const AnalysisInfoArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImgBox = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 85%;
  }
`;

const SearchForm = styled.form`
  width: 557.09px;
  height: 77.09px;
  background-color: #b8b8b8;
  border: 1px solid #ffffff !important;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  input {
    width: 424px;
    height: 43.63px;

    padding: 0 1em;
    &::placeholder {
      font-size: 0.8rem;
    }
  }

  button {
    font-size: 0.9rem;
    background-color: #1588dc;
    border-radius: 10px;
    color: #ffffff;
    padding: 0.95em 2em;
  }
`;
