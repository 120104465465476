import Cont from 'assets/browserImg/sol/cont.png';
import InfoImg from 'assets/browserImg/sol/infoImg.png';
import Chart from 'assets/browserImg/sol/ser.png';
import SolutionInfo from 'assets/browserImg/sol/solutionInfo.png';
import TitleImg from 'assets/browserImg/sol/titleImg.png';
import TitleImg2 from 'assets/browserImg/sol/titleImg2.png';
import TOP_BK from 'assets/browserImg/sol/top_bk.png';
import { solutionInfoOnState } from 'atoms/solutionInfoOnState';
import { solutionInfoState } from 'atoms/solutionInfoState';
import Layout from 'browser/components/layout';
import Carousel from 'components/carousel/carousel';
import NextArrow from 'components/carousel/nextArrow';
import PrevArrow from 'components/carousel/prevArrow';
import ImgBox from 'components/imgBox/imgBox';
import useNavi from 'hooks/useNavi';
import React from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { carouselMap } from './carouselMap';
import Emergency from './tap/emergency';
import Forensics from './tap/forensics';
import Magnifier from './tap/magnifier';
import Packet from './tap/packet';
import Tracking from './tap/tracking';

const settings = {
  dots: true, // 개수 표시 점
  infinite: true, // 무한 캐러셀
  speed: 500, // 다음 컨텐츠 까지의 속도
  slidesToShow: 1, // 화면에 보이는 컨텐츠 수
  slidesToScroll: 1, // 스크롤 시 넘어가는 컨텐츠 수
  centerMode: true, // 현재 컨텐츠 가운데 정렬
  centerPadding: '10px', // 중앙 컨텐츠 padding 값
  autoplay: true, // 자동 캐러셀
  autoplaySpeed: 2000, // 자동 캐러셀 속도
  draggable: true, // 드래그
  fade: false, // 사라졌다 나타나는 효과
  arrows: true, // 좌,우 버튼
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  vertical: false, // 세로 캐러셀
  initialSlide: 1, // 첫 컨텐츠 번호
  pauseOnFocus: true, // focus시 정지
  pauseOnHover: true, // hover시 정지
  responsive: [
    // 반응형 옵션
    {
      breakpoint: 480, // (숫자)px 이하일 경우
      settings: {
        slidesToShow: 1,
        arrows: true,
      },
    },
  ],
};

export default function Solution() {
  const [solutionInfoOn, setSolutionInfoOn] =
    useRecoilState(solutionInfoOnState);
  const [solutionInfo, setSolutionInfo] = useRecoilState(solutionInfoState);
  const changeSolInfo = (sol) => {
    setSolutionInfoOn(true);
    setSolutionInfo(sol);
  };
  const navigate = useNavi();

  return (
    <Layout>
      <SolutionArea>
        <ImgBox
          imgPath={TOP_BK}
          // marginBottom={'-5.8px'}
          // marginTop={'-2px'}
          bkColor={'#ffffff'}
        />
        <SolNavArea>
          <SolNavItem
            solutionInfoOn={solutionInfoOn}
            solutionInfo={solutionInfo}
            solInfo={'악성코드 분석'}
            onClick={() => changeSolInfo('악성코드 분석')}
            style={{ left: '-19px' }}
          >
            <button>악성코드 분석</button>
            <PointerBox
              solutionInfo={solutionInfo}
              solInfo={'악성코드 분석'}
              solutionInfoOn={solutionInfoOn}
            >
              <Pointer
                solutionInfo={solutionInfo}
                solInfo={'악성코드 분석'}
                solutionInfoOn={solutionInfoOn}
              />
            </PointerBox>
          </SolNavItem>
          <SolNavItem
            solutionInfo={solutionInfo}
            solutionInfoOn={solutionInfoOn}
            solInfo={'패킷 변조 솔루션'}
            onClick={() => changeSolInfo('패킷 변조 솔루션')}
          >
            <button>패킷 변조 솔루션</button>{' '}
            <PointerBox
              solutionInfo={solutionInfo}
              solInfo={'패킷 변조 솔루션'}
              solutionInfoOn={solutionInfoOn}
            >
              <Pointer
                solutionInfo={solutionInfo}
                solInfo={'패킷 변조 솔루션'}
                solutionInfoOn={solutionInfoOn}
              />
            </PointerBox>
          </SolNavItem>
          <SolNavItem
            solutionInfo={solutionInfo}
            solInfo={'긴급 대처 솔루션'}
            solutionInfoOn={solutionInfoOn}
            onClick={() => changeSolInfo('긴급 대처 솔루션')}
          >
            <button>긴급 대처 솔루션</button>
            <PointerBox
              solutionInfo={solutionInfo}
              solInfo={'긴급 대처 솔루션'}
              solutionInfoOn={solutionInfoOn}
            >
              <Pointer
                solutionInfo={solutionInfo}
                solInfo={'긴급 대처 솔루션'}
                solutionInfoOn={solutionInfoOn}
              />
            </PointerBox>
          </SolNavItem>
          <SolNavItem
            solutionInfo={solutionInfo}
            solutionInfoOn={solutionInfoOn}
            solInfo={'영상 추적 솔루션'}
            onClick={() => changeSolInfo('영상 추적 솔루션')}
          >
            <button>영상 추적 솔루션</button>
            <PointerBox
              solutionInfo={solutionInfo}
              solInfo={'영상 추적 솔루션'}
              solutionInfoOn={solutionInfoOn}
            >
              <Pointer
                solutionInfo={solutionInfo}
                solInfo={'영상 추적 솔루션'}
                solutionInfoOn={solutionInfoOn}
              />
            </PointerBox>
          </SolNavItem>
          <SolNavItem
            solutionInfo={solutionInfo}
            solInfo={'모바일 포렌식'}
            solutionInfoOn={solutionInfoOn}
            onClick={() => changeSolInfo('모바일 포렌식')}
          >
            <button>모바일 포렌식</button>
            <PointerBox
              solutionInfo={solutionInfo}
              solInfo={'모바일 포렌식'}
              solutionInfoOn={solutionInfoOn}
            >
              <Pointer
                solutionInfo={solutionInfo}
                solInfo={'모바일 포렌식'}
                solutionInfoOn={solutionInfoOn}
              />
            </PointerBox>
          </SolNavItem>
        </SolNavArea>
        {!solutionInfoOn ? (
          <>
            <ImgBoxWrapper>
              <img src={TitleImg} alt="NO_IMAGE" />
            </ImgBoxWrapper>
            <ImgBoxWrapper>
              <img src={SolutionInfo} alt="NO_IMAGE" style={{ width: '62%' }} />
            </ImgBoxWrapper>
            <div
              style={{
                borderRight: '1px solid #1588dc',
                height: 30,
                marginTop: '-3px',
              }}
            />
            <ImgBoxWrapper>
              <img src={TitleImg2} alt="NO_IMAGE" style={{ width: '70%' }} />
            </ImgBoxWrapper>
            <CarouselWrapper>
              <Carousel carouselMap={carouselMap} settings={settings} />
            </CarouselWrapper>
          </>
        ) : (
          <div>
            {/* magnifier 악성코드 분석 */}
            {solutionInfo === '악성코드 분석' && <Magnifier />}

            {/* packet 패킷 변조 솔루션 */}
            {solutionInfo === '패킷 변조 솔루션' && <Packet />}

            {/* emergency 긴급 대처 솔루션 */}
            {solutionInfo === '긴급 대처 솔루션' && <Emergency />}

            {/* tracking 영상 추적 솔루션 */}
            {solutionInfo === '영상 추적 솔루션' && <Tracking />}

            {/* forensics 모바일 포렌식 */}
            {solutionInfo === '모바일 포렌식' && <Forensics />}
          </div>
        )}
        <ImgBoxArea solutionInfoOn={solutionInfoOn}>
          <ImgBoxWrapper style={{ padding: '3em 0' }}>
            <img src={Chart} alt="NO_IMAGE" style={{ maxWidth: '56%' }} />
          </ImgBoxWrapper>
          <ImgBoxWrapper style={{ marginBottom: '-2px' }}>
            <img src={InfoImg} alt="NO_IMAGE" style={{ width: '100%' }} />
          </ImgBoxWrapper>
        </ImgBoxArea>
        <ContImgWrapper>
          <ImgBoxWrapper>
            <img src={Cont} alt="NO_IMAGE" style={{ width: '100%' }} />
          </ImgBoxWrapper>
          <ContBtn onClick={() => navigate('/contact')} />
        </ContImgWrapper>
      </SolutionArea>
    </Layout>
  );
}

const SolutionArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SolNavArea = styled.ul`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1em 16em;
  border-bottom: 1px solid #ebebeb !important;
  margin-bottom: 2.5em;
`;

const SolNavItem = styled.li`
  position: relative;

  button {
    color: ${({ solutionInfo, solInfo, solutionInfoOn }) =>
      solutionInfoOn && solutionInfo === solInfo ? '#1588dc' : '#7a7a7a'};
    background-color: #ffffff;
    font-weight: ${({ solutionInfo, solInfo, solutionInfoOn }) =>
      solutionInfoOn && solutionInfo === solInfo ? '500' : ''};
    cursor: pointer;
    transition: 0.1s;
  }
`;

const ImgBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 80%;
  }
`;

const CarouselWrapper = styled.div`
  background: #e6f4ff;
  border-radius: 50px;

  min-width: 814.54px;
  margin: 2em 0;
`;

const PointerBox = styled.div`
  position: absolute;

  border-bottom: ${({ solutionInfoOn, solutionInfo, solInfo }) =>
    solutionInfoOn && solutionInfo === solInfo
      ? '3px solid#1588dc !important'
      : 'none'};

  width: 180px;
  left: -35px;
  bottom: -18px;

  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: ${({ solutionInfoOn, solutionInfo, solInfo }) =>
    solutionInfoOn && solutionInfo === solInfo ? '1' : '0'};
  transition: opacity 0.3s ease;
`;
const Pointer = styled.span`
  width: 0px;
  height: 0px;
  border-bottom: calc(5px * 1.732) solid #1588dc !important;
  border-left: ${({ solutionInfoOn, solutionInfo, solInfo }) =>
    solutionInfoOn && solutionInfo === solInfo
      ? '5px solid transparent !important'
      : 'none'};
  border-right: ${({ solutionInfoOn, solutionInfo, solInfo }) =>
    solutionInfoOn && solutionInfo === solInfo
      ? '5px solid transparent !important'
      : 'none'};
  /* display: ${({ solutionInfo, solInfo }) =>
    solutionInfo === solInfo ? 'inline-block' : 'none'}; */
  margin-bottom: 0.2em;
  opacity: ${({ solutionInfo, solInfo }) =>
    solutionInfo === solInfo ? '1' : '0'};
  transition: opacity 0.3s ease;
`;

const ContImgWrapper = styled.div`
  position: relative;
`;

const ContBtn = styled.div`
  position: absolute;
  top: 26.8%;
  right: 24%;
  background-color: rgba(1, 1, 1, 0);
  width: 170px;
  height: 100px;
  z-index: 1;
  cursor: pointer;
`;

const ImgBoxArea = styled.div`
  display: flex;
  flex-direction: ${({ solutionInfoOn }) =>
    solutionInfoOn ? 'column-reverse' : 'column'};
`;
