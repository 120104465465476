import styled from 'styled-components';

export default function TapImgBoxLayout({ children }) {
  return <TapImgWrapper>{children}</TapImgWrapper>;
}
const TapImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;

  margin-top: 6em;
`;
