import useNaviCustom from 'hooks/useNaviCustom';
import React from 'react';
import styled from 'styled-components';

export default function Card({ apk }) {
  const [pathname, navigate] = useNaviCustom();
  return (
    <CardArea>
      <ImgBox>
        <img
          src={`https://secureappimgbucket.s3.ap-northeast-2.amazonaws.com/${apk?.filepath}`}
          alt="NO_IMAGE"
        />
      </ImgBox>
      <InfoWrapper>
        <Title>{apk.desc}</Title>
        <Desc>{apk.title}</Desc>
        <DetailBtn
          type="button"
          onClick={() => {
            navigate(`/analysis/${apk.id}`);
          }}
        >
          + 내용보기
        </DetailBtn>
      </InfoWrapper>
    </CardArea>
  );
}

const CardArea = styled.div`
  background-color: #ececec;
  height: 250.18px;
  width: 296px;
  margin: 0.7em 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const ImgBox = styled.div`
  width: 87.27px;
  height: 87.27px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */

  img {
    max-width: 100%;
  }
`;

const Title = styled.p`
  width: 238.72px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  text-align: center;
  font-size: 1.115rem;
  color: #7a7a7a;
`;

const Desc = styled(Title)`
  max-width: 100%;
  color: #434343;
  font-size: 1.25rem;
  font-weight: 700;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const DetailBtn = styled.button`
  background: #ffffff;
  border: 1px solid #72bbf0 !important;
  border-radius: 5px;
  font-size: 0.8rem;
  padding: 0.5em 0.5em;
  font-weight: 500;
  color: #1588dc;
  margin-top: 0.2em;
  cursor: pointer;
`;
