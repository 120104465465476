import s1 from 'assets/mobileImg/solution/tap/packet/s1.png';
import s2 from 'assets/mobileImg/solution/tap/packet/s2.png';
import s3 from 'assets/mobileImg/solution/tap/packet/s3.png';
import s4 from 'assets/mobileImg/solution/tap/packet/s4.png';
import s5 from 'assets/mobileImg/solution/tap/packet/s5.png';
import s6 from 'assets/mobileImg/solution/tap/packet/s6.png';
import s7 from 'assets/mobileImg/solution/tap/packet/s7.png';
import s8 from 'assets/mobileImg/solution/tap/packet/s8.png';
import s9 from 'assets/mobileImg/solution/tap/packet/s9.png';
import V from 'assets/video/packet.mp4';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function Packet() {
  return (
    <div>
      <SImgBox style={{ paddingTop: '2em' }}>
        <img src={s1} alt="NO_IMAGE" />
      </SImgBox>
      <VWrapper>
        <video
          width="100%"
          //   height="350px"
          preload="auto"
          autoPlay
          playsInline
          muted="muted"
          poster="img.jpg"
          src={V}
        />
      </VWrapper>
      <SImgBox>
        <img src={s2} alt="NO_IMAGE" />
      </SImgBox>
      <SImgBox>
        <img src={s3} alt="NO_IMAGE" />
      </SImgBox>
      <SImgBox>
        <img src={s4} alt="NO_IMAGE" />
      </SImgBox>
      <SImgBox>
        <img src={s5} alt="NO_IMAGE" />
      </SImgBox>
      <SImgBox>
        <img src={s6} alt="NO_IMAGE" />
      </SImgBox>
      <SImgBox>
        <img src={s7} alt="NO_IMAGE" />
      </SImgBox>
      <SImgBox>
        <img src={s8} alt="NO_IMAGE" />
      </SImgBox>
      <SImgBox>
        <img src={s9} alt="NO_IMAGE" />
      </SImgBox>
    </div>
  );
}
const SImgBox = styled.div`
  width: 100%;

  padding: 0.5em 1.4em;
  img {
    min-width: 100%;
    max-width: 100%;
  }
`;
const VWrapper = styled.div`
  padding: 0 1.4em;
  margin-bottom: 1em;
`;
