import { navState } from 'atoms/navState';
import { useCallback, useLayoutEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';

export default function useHorizontalScroll() {
  const nav = useRecoilValue(navState);

  const elRef = useRef();
  const { current } = elRef;

  const currentScrollMap = {
    left: 0,
    behavior: 'smooth',
  };

  const checkVerticalScroll = (e) => {
    currentScrollMap.left = current.scrollLeft + e.deltaY;
    current.scrollTo(currentScrollMap);
  };

  const onVerticalScroll = useCallback(checkVerticalScroll, [
    checkVerticalScroll,
  ]);

  const checkWhellEvent = (e) => {
    if (e.deltaY === 0) return;
    e.preventDefault();
    onVerticalScroll(e);
  };

  const onWheel = useCallback(checkWhellEvent, [onVerticalScroll]);

  const onHorizontalScroll = () => {
    if (current && nav) {
      current.addEventListener('wheel', onWheel);
      return () => current.removeEventListener('wheel', onWheel);
    }
  };

  // DOM 을 제어할땐 useLayoutEffect를 사용하는게 성능상 좋습니다.
  useLayoutEffect(onHorizontalScroll, [nav, current, onWheel]);

  return elRef;
}
