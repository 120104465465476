import { getYoutubeAPI } from 'api/customAxios';
import BLOG_ICON from 'assets/share/blog_icon.png';
import BLOG_MAIN from 'assets/share/blog_main.png';
import YOUTUBE_ICON from 'assets/share/youtube_icon.png';
import YouTubeCompo from 'components/youtube/youTubeCompo';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import SnsBox from './snsBox';

export default function SnsInfo() {
  const videoId = {
    id1: 'auVQAyywHNE',
    id2: 'dSSfoJ-wg-U&t=3s',
    id3: '7xqcMbwsUBg',
    id4: 'WE2qrARu-a8',
  };
  const videoOptions = {
    width: '457.5px',
    height: '255.58px',
    playerVars: {
      autoplay: 0, //자동재생 O
      rel: 0, //관련 동영상 표시하지 않음
      modestbranding: 1, // 컨트롤 바에 youtube 로고를 표시하지 않음
      controls: 0, // 동영상 재생 컨트롤 바 등.. 나오게
      showinfo: 0,
      mute: 1,
      loop: 1,
    },
  };
  // First, second, third, fourth
  const [youtubeArr, setYoutubeArr] = useState([]);
  useEffect(() => {
    (async () => {
      const YOUTUBE_API_KEY = 'AIzaSyBeIIkHWSW-yoSlZzAgd-FeXrHnrQ6Qbfo';
      const youtubeDataFi = getYoutubeAPI()(videoId.id1, YOUTUBE_API_KEY);
      // const youtubeDataS = getYoutubeAPI()(videoId.id2, YOUTUBE_API_KEY);
      // const youtubeDataT = getYoutubeAPI()(videoId.id3, YOUTUBE_API_KEY);
      // const youtubeDataFo = getYoutubeAPI()(videoId.id4, YOUTUBE_API_KEY);

      const data = await Promise.all([
        youtubeDataFi,
        // youtubeDataS,
        // youtubeDataT,
        // youtubeDataFo,
      ]);
      setYoutubeArr(data);
      // console.log(youtubeData.items[0].snippet);
    })();
  }, [videoId.id1]);

  return (
    <SnsInfoArea>
      <div style={{ color: '#394859', fontWeight: 500 }}>
        <p style={{ lineHeight: '23px' }}>
          <b style={{ fontWeight: 700 }}>시큐어앱</b>의{' '}
          <span style={{ color: '#1588dc' }}>공식 채널</span>
          에는 디지털 성범죄의 대표 '<b style={{ fontWeight: 700 }}>몸캠피싱</b>
          '등 다양한 유형의 범죄에 대한 정보를 제공하고 있습니다. 내용 공유
          하시고 사전에 대처 하세요!
        </p>
      </div>
      <div style={{ display: 'flex', gap: 30 }}>
        {/* 유튜브 박스, 추후 리팩토링 예정 */}
        <SnsBox
          desc="@secureapp8920"
          link="https://www.youtube.com/channel/UCnHKB50MecAwTrXkJsCoxRA"
        >
          {youtubeArr.map((item, index) => (
            <div key={index}>
              <YouTubeCompo
                videoId={item.items[0].id}
                videoOptions={videoOptions}
              />

              <YoutubeTitleArea>
                <DescBox>
                  <div>
                    <img src={YOUTUBE_ICON} alt="NO_IMAGE" />
                  </div>
                  <YouTubeTitleWrapper>
                    <YoutubeTitle>{item.items[0].snippet.title}</YoutubeTitle>
                  </YouTubeTitleWrapper>
                </DescBox>
                <button>
                  <a
                    href={`https://www.youtube.com/watch?v=${item.items[0].id}`}
                  >
                    + 바로가기
                  </a>
                </button>
              </YoutubeTitleArea>
            </div>
          ))}
        </SnsBox>

        {/* 블로그 박스, 추후 리팩토링 예정 */}
        <SnsBox
          desc="@secureapp8920"
          link="https://blog.naver.com/secureapp112"
        >
          <div style={{ width: '457.5px' }}>
            <Blog>
              <img src={BLOG_MAIN} alt="NO_IMAGE" />
            </Blog>
            <YoutubeTitleArea>
              <DescBox>
                <div>
                  <img src={BLOG_ICON} alt="NO_IMAGE" />
                </div>
                <YouTubeTitleWrapper>
                  <YoutubeTitle>
                    몸캠피싱 피해 시 전화번호 변경해도 될까?
                  </YoutubeTitle>
                </YouTubeTitleWrapper>
              </DescBox>
              <button>
                <a
                  href="https://blog.naver.com/secureapp112/222944251449"
                  target="_blank"
                  rel="noreferrer"
                >
                  + 바로가기
                </a>
              </button>
            </YoutubeTitleArea>
          </div>
        </SnsBox>
      </div>
    </SnsInfoArea>
  );
}

const SnsInfoArea = styled.div`
  display: flex;
  flex-direction: column;

  gap: 30px;
`;
const YoutubeTitleArea = styled.div`
  width: 457.5px;
  max-width: 457.5px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    font-size: 0.6rem;
    color: #1588dc;
    border: 1px solid #1588dc;
    background-color: #ffffff;
    border-radius: 3px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 450;
    padding: 0.3em 0.8em;
  }
`;

const YouTubeTitleWrapper = styled.div`
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: bold;
  color: #434343;
`;
const YoutubeTitle = styled.b`
  font-size: 0.875rem;
  color: #434343;
`;
const DescBox = styled.div`
  display: flex;
  gap: 5px;
`;

const Blog = styled.div`
  max-width: 100%;
  img {
    max-width: 100%;
  }
`;
