/* global kakao */

import MARKER_LOGO from 'assets/browserImg/contact/markerLogo.png';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import MARKER_LOGO2 from '../../assets/browserImg/contact/markerLogo.png';

const { kakao } = window;

export default function Map() {
  useEffect(() => {
    const container = document.getElementById('map');
    const options = {
      center: new window.kakao.maps.LatLng(
        37.38188218066195,
        126.66177252641464,
      ),
      level: 2,
    };

    const map = new window.kakao.maps.Map(container, options);

    //드래그 막기
    // map.setDraggable(false);

    //줌 막기
    // map.setZoomable(false);

    // 마커가 표시될 위치입니다
    var markerPosition = new kakao.maps.LatLng(
      37.38188218066195,
      126.66177252641464,
    );

    // 마커를 생성합니다
    const marker = new window.kakao.maps.Marker({
      position: markerPosition,
      clickable: true, // 마커를 클릭했을 때 지도의 클릭 이벤트가 발생하지 않도록 설정합니다
    });

    // 마커가 지도 위에 표시되도록 설정합니다
    marker.setMap(map);

    const infowindow = new kakao.maps.InfoWindow({
      content: `<div style="background-color:#2B3C4D; width:80px; color: #ffffff">
         <img src="/assets/browserImg/contact/markerLogo.png" alt="NO_IMAGE">
          <p style="font-size: 0.8rem">
            <b style="font-weight: bold; font-size: 1rem">M</b>동 <b style="font-weight: bold; font-size: 1rem">2202</b>호
          </p>
        </div>`,
    });
    infowindow.open(map, marker);

    // // 마커에 마우스오버 이벤트를 등록합니다
    // kakao.maps.event.addListener(marker, 'mouseover', function () {
    //   // 마커에 마우스오버 이벤트가 발생하면 인포윈도우를 마커위에 표시합니다
    //   infowindow.open(map, marker);
    // });

    // 마커에 클릭이벤트를 등록합니다
    kakao.maps.event.addListener(marker, 'click', function () {
      // 마커를 클릭하면 장소명이 인포윈도우에 표출됩니다
      // infowindow.setContent(
      //   '<div style="padding:5px;font-size:12px;">' + '변수명' + '</div>',
      // );
      infowindow.open(map, marker);
    });

    // 마커에 마우스아웃 이벤트를 등록합니다
    kakao.maps.event.addListener(marker, 'mouseout', function () {
      // 마커에 마우스아웃 이벤트가 발생하면 인포윈도우를 제거합니다
      infowindow.close();
    });
  }, []);

  return (
    <MapArea className="Map">
      <MapWrapper className="MapContainer" id="map" />
    </MapArea>
  );
}

const MapArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

function MarkerStyled() {
  return <div>hi</div>;
}
