import { pathnameState } from 'atoms/pathnameState';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import useNavi from './useNavi';

export default function useNaviCustom() {
  const [pathname, setPathname] = useRecoilState(pathnameState);
  let windowPath = window.location.pathname;
  const navigate = useNavi();

  useEffect(() => {
    setPathname(window.location.pathname);
  }, [windowPath, setPathname]);

  return [pathname, navigate];
}
