import useNaviCustom from 'hooks/useNaviCustom';
import React from 'react';
import styled from 'styled-components';

export default function List({ apk }) {
  const [pathname, navigate] = useNaviCustom();
  return (
    <ListArea>
      <LeftBox>
        <div>
          <img
            src={`https://secureappimgbucket.s3.ap-northeast-2.amazonaws.com/${apk?.filepath}`}
            alt="NO_IMAGE"
          />
        </div>
        <p onClick={() => navigate(`/analysis/${apk?.id}`)}>{apk?.title}</p>
      </LeftBox>
      <p>{apk?.desc}</p>
    </ListArea>
  );
}

const ListArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 557.09px;
  border-bottom: 1px solid #ffffff !important;

  padding: 0.5em 0;

  p {
    color: #434343;
    font-weight: 300;

    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const LeftBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  div {
    width: 42.18px;
    height: 42.21px;
    /* background-color: red; */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
    }
  }

  p {
    font-weight: 600;
    width: 200px;
    cursor: pointer;
  }
`;
