export default function useSetToken(setRecoilToken) {
  function setToken(token) {
    const secureappToken = `Bearer ${token}`;
    const currentTime = new Date().getTime();
    const expireTime = 86400; // 토큰의 만료 시간(초)
    // const expireTime = 5; // 토큰의 만료 시간(초)
    const tokenExpiration = currentTime + expireTime * 1000; // 만료 시간 (밀리초)
    localStorage.setItem(
      'secureappToken',
      JSON.stringify({ secureappToken, expiresAt: tokenExpiration }),
    );
    setRecoilToken(secureappToken);
  }

  return setToken;
}
