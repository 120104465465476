import React from 'react';
import YouTube from 'react-youtube';
import styled from 'styled-components';

const YouTubeCompo = ({ videoId, videoOptions }) => {
  return (
    <YouTubeArea>
      <YouTube key={videoId} videoId={videoId} opts={videoOptions} />
    </YouTubeArea>
  );
};

const YouTubeArea = styled.div``;

export default YouTubeCompo;
