import { recoilTokenState } from 'atoms/recoilTokenState';
import useLogout from 'hooks/useLogout';
import { useState } from 'react';
import { useResetRecoilState } from 'recoil';
import styled from 'styled-components';

import AdminLayout from './components/adminLayout';
import ApkList from './components/apkList';
import ApkWriter from './components/apkWriter';
import IssueList from './components/issueList';
import IssueWriter from './components/issueWriter';
import VictimInfo from './victimInfo';

export default function Admin() {
  const [adminNavNum, setAdminNavNum] = useState(1);

  const [putStatus, setPutStatus] = useState(false);
  const [detailData, setDetailData] = useState([]);

  const resetRecoilToken = useResetRecoilState(recoilTokenState);
  const onLogout = useLogout(resetRecoilToken);

  return (
    <AdminLayout setAdminNavNum={setAdminNavNum}>
      {/* {adminNavNum === 1 && '1'}
      {adminNavNum === 2 && '2'} */}
      {adminNavNum === 3 && (
        <ApkList
          setPutStatus={setPutStatus}
          setDetailData={setDetailData}
          setAdminNavNum={setAdminNavNum}
        />
      )}
      {adminNavNum === 4 && (
        <ApkWriter
          putStatus={putStatus}
          setPutStatus={setPutStatus}
          setDetailData={setDetailData}
          detailData={detailData}
          setAdminNavNum={setAdminNavNum}
        />
      )}
      {adminNavNum === 5 && (
        <IssueList
          setPutStatus={setPutStatus}
          setDetailData={setDetailData}
          setAdminNavNum={setAdminNavNum}
        />
      )}
      {adminNavNum === 6 && (
        <IssueWriter
          putStatus={putStatus}
          setPutStatus={setPutStatus}
          setDetailData={setDetailData}
          detailData={detailData}
          setAdminNavNum={setAdminNavNum}
        />
      )}
      {/* {adminNavNum === 7 && <VictimInfo />} */}
      <Btn onClick={onLogout}>로그아웃</Btn>
    </AdminLayout>
  );
}

const Btn = styled.button`
  background-color: #1588dc;
  color: #ffffff;
  /* width: ; */
  height: 2rem;
  cursor: pointer;
  position: absolute;
  padding: 1em 2em 1em 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  top: 2%;
  right: 1%;

  &:hover {
    opacity: 0.8;
  }
`;
