import COM from 'assets/browserImg/company/com.png';
import COMBK from 'assets/browserImg/company/comBk.png';
import COMTOP from 'assets/browserImg/company/comTop.png';
import SlideImg1 from 'assets/browserImg/company/slideImg1.png';
import SlideImg2 from 'assets/browserImg/company/slideImg2.png';
import SlideImg3 from 'assets/browserImg/company/slideImg3.png';
import SlideImg4 from 'assets/browserImg/company/slideImg4.png';
import Layout from 'browser/components/layout';
import Carousel from 'components/carousel/carousel';
import PrevArrow from 'components/carousel/prevArrow';
import React from 'react';
import styled from 'styled-components';

import NextArrow from './carousel/nextArrow';

const settings = {
  dots: false, // 개수 표시 점
  infinite: true, // 무한 캐러셀
  speed: 1000, // 다음 컨텐츠 까지의 속도
  slidesToShow: 3.95, // 화면에 보이는 컨텐츠 수
  slidesToScroll: 1, // 스크롤 시 넘어가는 컨텐츠 수
  centerMode: true, // 현재 컨텐츠 가운데 정렬
  centerPadding: '10px', // 중앙 컨텐츠 padding 값
  autoplay: true, // 자동 캐러셀
  autoplaySpeed: 2000, // 자동 캐러셀 속도
  draggable: true, // 드래그
  fade: false, // 사라졌다 나타나는 효과
  arrows: true, // 좌,우 버튼
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  vertical: false, // 세로 캐러셀
  initialSlide: 1, // 첫 컨텐츠 번호
  pauseOnFocus: true, // focus시 정지
  pauseOnHover: true, // hover시 정지
  responsive: [
    // 반응형 옵션
    {
      breakpoint: 480, // (숫자)px 이하일 경우
      settings: {
        slidesToShow: 1,
        arrows: true,
      },
    },
  ],
};

const testMap = [
  {
    id: 1,
    cItem: (
      <div>
        <img src={SlideImg1} alt="NO_IMAGE" style={{ width: '211.8px' }} />
      </div>
    ),
  },
  {
    id: 2,
    cItem: (
      <div>
        <img src={SlideImg2} alt="NO_IMAGE" style={{ width: '211.8px' }} />
      </div>
    ),
  },
  {
    id: 3,
    cItem: (
      <div>
        <img src={SlideImg3} alt="NO_IMAGE" style={{ width: '211.8px' }} />
      </div>
    ),
  },
  {
    id: 4,
    cItem: (
      <div>
        <img src={SlideImg4} alt="NO_IMAGE" style={{ width: '211.8px' }} />
      </div>
    ),
  },
  {
    id: 5,
    cItem: (
      <div>
        <img src={SlideImg4} alt="NO_IMAGE" style={{ width: '211.8px' }} />
      </div>
    ),
  },
];

export default function Company() {
  return (
    <Layout>
      <CompanyArea>
        <div>
          <img src={COMBK} alt="NO_IMAGE" style={{ maxWidth: '100%' }} />
        </div>
        <ImgBox>
          <img src={COMTOP} alt="NO_IMAGE" />
        </ImgBox>
        <CarouselWrapper>
          <p
            style={{
              color: '#275070',
              fontWeight: '500',
              fontSize: '1.125rem',
              marginBottom: '1em',
            }}
          >
            <strong style={{ fontWeight: '700' }}>시큐어앱</strong>을 선택해야
            하는 이유
          </p>
          <Carousel
            carouselMap={testMap}
            settings={settings}
            mWidth={'970px'}
          />
        </CarouselWrapper>
        <div>
          <img src={COM} alt="NO_IMAGE" style={{ maxWidth: '100%' }} />
        </div>
      </CompanyArea>
    </Layout>
  );
}

const ImgBox = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5em;
  margin-top: 0.6em;

  img {
    max-width: 100%;
    width: 70em;
  }
`;

const CompanyArea = styled.div`
  width: 100%;
`;
const CarouselWrapper = styled.div`
  /* min-width: 814.54px; */
  background-color: #d5ebfa;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3.5em;
  padding-bottom: 1.5em;
`;
