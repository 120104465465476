export const NAV_PATH_MAP = [
  {
    id: 1,
    title: 'CONTACT',
    path: '/contact',
    nav: [
      {
        id: 1,
        title: '상담문의',
      },
      {
        id: 2,
        title: '찾아오시는 길',
      },
    ],
  },
  {
    id: 2,
    title: 'SOLUTION',
    path: '/solution',
    nav: [
      {
        id: 1,
        title: '악성코드 분석',
      },
      {
        id: 2,
        title: '패킷 변조 솔루션',
      },
      {
        id: 3,
        title: '긴급 대처 솔루션',
      },
      {
        id: 4,
        title: '영상 추적 솔루션',
      },
      {
        id: 5,
        title: '모바일 포렌식',
      },
    ],
  },
  {
    id: 3,
    title: 'ANALYSIS',
    path: '/analysis',
    nav: [
      {
        id: 1,
        title: '분석보고서',
      },
    ],
  },
  {
    id: 4,
    title: 'PRESS',
    path: '/press',
    nav: [
      {
        id: 1,
        title: '보도자료',
      },
      {
        id: 2,
        title: 'SNS',
      },
    ],
  },
  {
    id: 5,
    title: 'COMPANY',
    path: '/company',
    nav: [
      {
        id: 1,
        title: 'Overview',
      },
      {
        id: 2,
        title: 'CEO 인사말',
      },
      {
        id: 3,
        title: '시큐어앱 연혁',
      },
    ],
  },
];

// export const NAV_PATH_MAP = [
//   {
//     id: 1,
//     title: 'CONTACT',
//     path: '/contact',
//     nav: [
//       {
//         id: 1,
//         title: '상담문의',
//       },
//       {
//         id: 2,
//         title: '찾아오시는 길',
//       },
//     ],
//   },
//   {
//     id: 2,
//     title: 'SOLUTION',
//     path: '/solution',
//     nav: [
//       {
//         id: 1,
//         title: '신발 수선',
//       },
//       {
//         id: 2,
//         title: '옷 수선',
//       },
//       {
//         id: 3,
//         title: '시계 수리',
//       },
//       {
//         id: 4,
//         title: '세탁',
//       },
//       {
//         id: 5,
//         title: '무언가',
//       },
//     ],
//   },
//   {
//     id: 3,
//     title: 'ANALYSIS',
//     path: '/analysis',
//     nav: [
//       {
//         id: 1,
//         title: '시장조사',
//       },
//     ],
//   },
//   {
//     id: 4,
//     title: 'PRESS',
//     path: '/press',
//     nav: [
//       {
//         id: 1,
//         title: '나이키가 뭔가했다',
//       },
//       {
//         id: 2,
//         title: '이것도했다',
//       },
//     ],
//   },
//   {
//     id: 5,
//     title: 'COMPANY',
//     path: '/company',
//     nav: [
//       {
//         id: 1,
//         title: '나이키',
//       },
//       {
//         id: 2,
//         title: '아디다스',
//       },
//       {
//         id: 3,
//         title: '뭔가',
//       },
//     ],
//   },
// ];
