import emergency from 'assets/mobileImg/mainpageImg/solImg/emergency.png';
import forensics from 'assets/mobileImg/mainpageImg/solImg/forensics.png';
import magnifier from 'assets/mobileImg/mainpageImg/solImg/magnifier.png';
import packet from 'assets/mobileImg/mainpageImg/solImg/packet.png';
import tracking from 'assets/mobileImg/mainpageImg/solImg/tracking.png';
import emergencyV from 'assets/video/emergency.mp4';
import forensicsV from 'assets/video/forensics.mp4';
import magnifierV from 'assets/video/magnifier.mp4';
import packetV from 'assets/video/packet.mp4';
import trackingV from 'assets/video/tracking.mp4';
import SolCarousel from 'mobile/components/carousel/solCarousel';
import React from 'react';
import styled from 'styled-components';

import CarouselItem from './carouselItem';

const settings = {
  dots: true, // 개수 표시 점
  infinite: true, // 무한 캐러셀
  speed: 500, // 다음 컨텐츠 까지의 속도
  slidesToShow: 1, // 화면에 보이는 컨텐츠 수
  slidesToScroll: 1, // 스크롤 시 넘어가는 컨텐츠 수
  centerMode: true, // 현재 컨텐츠 가운데 정렬
  centerPadding: '10px', // 중앙 컨텐츠 padding 값
  autoplay: true, // 자동 캐러셀
  autoplaySpeed: 2000, // 자동 캐러셀 속도
  draggable: true, // 드래그
  fade: false, // 사라졌다 나타나는 효과
  arrows: false, // 좌,우 버튼
  vertical: false, // 세로 캐러셀
  initialSlide: 1, // 첫 컨텐츠 번호
  pauseOnFocus: true, // focus시 정지
  pauseOnHover: true, // hover시 정지
  //   dotsClass: 'dots_custom',
  //   responsive: [
  //     // 반응형 옵션
  //     {
  //       breakpoint: 480, // (숫자)px 이하일 경우
  //       settings: {
  //         slidesToShow: 1,
  //         arrows: true,
  //       },
  //     },
  //   ],
};

const carouselMap = [
  {
    id: 1,
    cItem: (
      <CarouselItem
        imgPath={magnifier}
        vi={magnifierV}
        title="악성코드 분석"
        desc="공격경로 및 피해규모 파악 후 홈페이지 내 분석 보고서 
    게시 및 의뢰자에 맞춤형 상황 최적 솔루션 제공"
        btnTitle="악성코드 분석"
        onClick={() => {}}
      />
    ),
  },
  {
    id: 2,
    cItem: (
      <CarouselItem
        imgPath={packet}
        vi={packetV}
        title="패킷 변조 솔루션"
        desc="획득한 API 경로 및 C&C 서버에 침투해 피해 데이터 확보 후 패킷 변조를 통한 탈취 정보 보호"
        btnTitle="패킷 대체 솔루션"
        onClick={() => {}}
      />
    ),
  },
  {
    id: 3,
    cItem: (
      <CarouselItem
        imgPath={emergency}
        vi={emergencyV}
        title="긴급 대처 솔루션"
        desc="개인 정보 완전 탈취 및 지인 유포 감행 협박 시 피해 확산 방지 및 예방을 위한 비상 대응 솔루션"
        btnTitle="긴급 대처 솔루션"
        onClick={() => {}}
      />
    ),
  },
  {
    id: 4,
    cItem: (
      <CarouselItem
        imgPath={tracking}
        vi={trackingV}
        title="영상 추적 솔루션"
        desc="메이저 플랫폼에 영상 업로드 실시간 탐지 및 24시간 모니터링을 통한 재유포 방지"
        btnTitle="영상 추적 솔루션"
        onClick={() => {}}
      />
    ),
  },
  {
    id: 5,
    cItem: (
      <CarouselItem
        imgPath={forensics}
        vi={forensicsV}
        title="모바일 포렌식"
        desc="자사 보유 전문 소프트웨어를 통해 범행에 사용된 어플리케이션 및 기타 필요 데이터 복구"
        btnTitle="모바일 포렌식"
        onClick={() => {}}
      />
    ),
  },
];

export default function SolutionInfo() {
  return (
    <SolutionInfoArea>
      <CarouselWrapper>
        <SolCarousel
          carouselMap={carouselMap}
          settings={settings}
          dotsClass={'dots_custom'}
        />
      </CarouselWrapper>
    </SolutionInfoArea>
  );
}
const SolutionInfoArea = styled.div`
  background: linear-gradient(
    360deg,
    #d9d9d9 0%,
    rgba(217, 217, 217, 0.9) 100%
  );
`;

const CarouselWrapper = styled.div`
  padding-bottom: 3em;
`;
