import Arrow from 'assets/browserImg/sol/tap/arrow.png';
import Tr from 'assets/browserImg/sol/tap/t.png';
import T from 'assets/browserImg/sol/tap/t4.png';
import IImg1 from 'assets/browserImg/sol/tap/tap4/bi1.png';
import IImg2 from 'assets/browserImg/sol/tap/tap4/bi2.png';
import IImg3 from 'assets/browserImg/sol/tap/tap4/bi3.png';
import OImg1 from 'assets/browserImg/sol/tap/tap4/bo1.png';
import OImg2 from 'assets/browserImg/sol/tap/tap4/bo2.png';
import OImg3 from 'assets/browserImg/sol/tap/tap4/bo3.png';
import TB from 'assets/browserImg/sol/tap/tb4.png';
import V from 'assets/video/tracking.mp4';
import React from 'react';
import styled from 'styled-components';

import TapImgBox from './tapImgBox';
import TapImgBoxLayout from './tapImgBoxLayout';
import VideoLayout from './videoLaout';
export default function Tracking() {
  return (
    <div>
      <VideoLayout T={T} TB={TB} Arrow={Arrow} V={V} />
      <TapImgBoxLayout>
        <TapImgBox iImg={IImg1} oImg={OImg1} />
        <TapImgBox iImg={IImg2} oImg={OImg2} />
        <TapImgBox iImg={IImg3} oImg={OImg3} bkImg={true} trac={true} />
      </TapImgBoxLayout>
      <ImgBox>
        <img src={Tr} alt="NO_IMAGE" />
      </ImgBox>
    </div>
  );
}
const ImgBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5em;
  margin-bottom: 3em;

  img {
    width: 33em;
  }
`;
