import BLOG from 'assets/nav/blog.png';
import KAKAO from 'assets/nav/kakao.png';
import KAKAOC from 'assets/nav/kakao-c.png';
import LINE from 'assets/nav/line.png';
import app from 'assets/nav/secureapp.png';
import TELL from 'assets/nav/tell.png';
import TXT from 'assets/nav/txt.png';
import YOUTUB from 'assets/nav/youtub.png';
import { navState } from 'atoms/navState';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

export default function SNav({ scrolled }) {
  const nav = useRecoilValue(navState);

  return (
    <Area nav={nav} isMobile={isMobile} scrolled={scrolled}>
      <div>
        <img src={TXT} alt="NO_IMAGE" />
      </div>
      <a href="tel:1661-7382">
        <img src={TELL} alt="NO_IMAGE" />
      </a>
      <a
        href={'https://open.kakao.com/o/sQNaRZGc'}
        target="_blank"
        rel="noreferrer"
      >
        <img src={KAKAO} alt="NO_IMAGE" />
      </a>
      <div style={{ marginTop: '-12px' }}>
        <img src={LINE} alt="NO_IMAGE" />
      </div>
      <a href={'http://pf.kakao.com/_HHGsxj'} target="_blank" rel="noreferrer">
        <img src={KAKAOC} alt="NO_IMAGE" />
      </a>
      <a
        href="https://blog.naver.com/secureapp112"
        target="_blank"
        rel="noreferrer"
      >
        <img src={BLOG} alt="NO_IMAGE" />
      </a>
      <a
        href="https://www.youtube.com/channel/UCnHKB50MecAwTrXkJsCoxRA"
        target="_blank"
        rel="noreferrer"
      >
        <img src={YOUTUB} alt="NO_IMAGE" />
      </a>
      <a
      // href="https://www.youtube.com/channel/UCnHKB50MecAwTrXkJsCoxRA"
      // target="_blank"
      // rel="noreferrer"
      >
        <img src={app} alt="NO_IMAGE" />
      </a>
    </Area>
  );
}
const Area = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: fixed;
  right: 16%;
  top: 15%;
  z-index: 1;

  gap: 2px;
  opacity: ${({ nav, scrolled }) => (scrolled === 1 ? 0 : 1)};
  transition: opacity 0.15s ease;

  a {
    img {
    }
  }

  @media screen and (max-width: ${({ isMobile }) => isMobile && '10000px'}) {
    z-index: ${({ nav, scrolled }) => (scrolled === 1 ? -100 : 1)};

    right: 5%;
    top: 28%;
  }
`;
