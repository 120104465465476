import { columnDisplay } from 'assets/styles/styled/baseStyles';
import { navState } from 'atoms/navState';
import useTopFocus from 'hooks/useTopFocus';
import React, { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import Footer from './footer';
import Header from './header';

export default function Layout({ children }) {
  const nav = useRecoilValue(navState);
  // const { focusRef, onFocus } = useTopFocus();
  const focusRef = useRef(null);
  const onFocus = () => {
    focusRef.current.focus();
  };

  useEffect(() => {
    onFocus();
  }, []);

  return (
    <LayoutArea>
      <FocusedDiv ref={focusRef} tabIndex={0} />
      <LayoutWrapper>
        <Header onFocus={onFocus} />
        <BodyArea nav={nav}>{children}</BodyArea>
        <Footer nav={nav} />
      </LayoutWrapper>
    </LayoutArea>
  );
}

const LayoutArea = styled.div`
  width: 100%;
  height: 100vh;
  margin-top: 4.6em;
`;

const LayoutWrapper = styled.div`
  ${columnDisplay}

  width: var(--width-br-base);
  margin: 0 auto;
  height: 100%;
`;

const BodyArea = styled.div`
  flex: 1;
  opacity: ${({ nav }) => nav && 0};
  transition: opacity 0.8s ease;
`;
const FocusedDiv = styled.div`
  &:focus {
    border: none;
    outline: none;
  }
`;
