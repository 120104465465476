/* eslint-disable react-hooks/exhaustive-deps */
import H from 'assets/browserImg/headerImg/h.png';
import LOGO from 'assets/mobileImg/headerImg/logo.png';
import MAGNIFIER from 'assets/mobileImg/headerImg/magnifier.png';
import {
  alignCenterDisplay,
  betweenDisplay,
} from 'assets/styles/styled/baseStyles';
import { mobileInputFoucsState } from 'atoms/mobileInputFocusState';
import { navState } from 'atoms/navState';
import { searchInputFocusState } from 'atoms/searchInputFocusState';
import useDetectScrollDirection from 'hooks/useDetectScrollDirection';
import useNaviCustom from 'hooks/useNaviCustom';
import throttle from 'lodash.throttle';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

export default function Header() {
  const [pathname, onNavigate] = useNaviCustom('/');
  const setSearchInputFocus = useSetRecoilState(searchInputFocusState);
  const inputFocus = useRecoilValue(searchInputFocusState);
  const [mobileInputFoucs, setMobileInputFoucs] = useRecoilState(
    mobileInputFoucsState,
  );

  const onFocusAnalSearchInput = () => {
    if (pathname !== '/') onNavigate('/analysis');
    setMobileInputFoucs(true);
  };

  const [navStatus, setNavStatus] = useRecoilState(navState);
  const [scrolled, useMemoedThrottle] = useDetectScrollDirection();

  useEffect(() => {
    window.addEventListener('scroll', useMemoedThrottle);
    return () => {
      window.removeEventListener('scroll', useMemoedThrottle);
    };
  }, []);

  useEffect(() => {
    // if (mobileInputFoucs) {
    setSearchInputFocus((prev) => !prev);
    // }
  }, [mobileInputFoucs]);

  return (
    <HeaderArea scrolled={scrolled} navStatus={navStatus}>
      <HomeLogoBox onClick={() => onNavigate('/')}>
        <img src={LOGO} alt="NO_IMAGE" />
      </HomeLogoBox>
      <NavWrapper>
        <NavBox pathname={pathname} path="" onClick={onFocusAnalSearchInput}>
          <SearchBox>
            <div>
              <img src={MAGNIFIER} alt="NO_IMAGE" />
            </div>
            <span>ANALYSIS</span>
          </SearchBox>
        </NavBox>
        <span>|</span>
        <NavBox h="h" onClick={() => setNavStatus((navStatus) => !navStatus)}>
          <div>
            <img src={H} alt="NO_IMAGE" />
          </div>
        </NavBox>
      </NavWrapper>
    </HeaderArea>
  );
}

const HeaderArea = styled.header`
  ${betweenDisplay}
  background-color: var(--background-color-base);
  padding: 1em 1em 0.8em 1em;

  position: fixed;
  width: 100%;

  top: ${({ scrolled, navStatus }) =>
    !navStatus && scrolled === 1 ? '-4em' : '0'};
  transition: top 0.5s ease;
  z-index: 3;

  max-width: 100vw;
  box-sizing: border-box;
`;

const HomeLogoBox = styled.div`
  cursor: pointer;

  @media screen and (max-width: 300px) {
    img {
      width: 5.5rem;
    }
  }
`;

const NavWrapper = styled.ul`
  ${alignCenterDisplay}
  gap: 0.5em;
`;

const SearchBox = styled.div`
  ${alignCenterDisplay}
`;

const NavBox = styled.li`
  margin-left: ${({ h }) => (h === 'h' ? '0.5em' : '')};
  @media screen and (max-width: 300px) {
    img {
      width: 1rem;
    }
  }
  cursor: pointer;

  span {
    display: inline-block;
    text-align: center;
    width: ${({ path }) => (path === '/press' ? '3.2em' : '5.0625em')};
    font-size: var(--font-size-base);
    color: ${({ pathname, path }) =>
      path === ''
        ? 'var(--font-color-0059AB)'
        : pathname === path
        ? 'var(--font-color-1588DC)'
        : 'var(--font-color-354660)'};
    font-weight: ${({ pathname, path }) =>
      path === ''
        ? 'var(--font-weight-l)'
        : pathname === path
        ? 'var(--font-weight-l)'
        : 'var(--font-weight-md)'};

    @media screen and (max-width: 300px) {
      font-size: 0.8rem;
    }
  }
`;
