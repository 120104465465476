import { getYoutubeAPI } from 'api/customAxios';
import B4 from 'assets/mobileImg/mainpageImg/snsImg/b1.png';
import B1 from 'assets/mobileImg/mainpageImg/snsImg/b2.png';
import B3 from 'assets/mobileImg/mainpageImg/snsImg/b3.png';
import B2 from 'assets/mobileImg/mainpageImg/snsImg/b4.png';
import CJ from 'assets/mobileImg/mainpageImg/snsImg/cj.png';
import BLOG_ICON from 'assets/share/blog_icon.png';
import BLOG_MAIN from 'assets/share/blog_main.png';
import YOUTUBE_ICON from 'assets/share/youtube_icon.png';
import YouTubeCompo from 'components/youtube/youTubeCompo';
import React, { Fragment, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import SnsBox from './snsBox';
import SnsCarousel from './snsCarousel';

/*
  window.screenTop : 상단으로부터 브라우저 위치 
  window.screenLeft : 왼쪽으로부터 브라우저 위치 
  window.innerWidth : 브라우저 화면의 너비(viewport) 
  window.innerHeight : 브라우저 화면의 높이(viewport) 
  window.outerWidth : 브라우저 전체의 너비(메뉴, 툴바, 스크롤바 포함) 
  window.outerHeight : 브라우저 전체의 높이(메뉴, 툴바, 스크롤바 포함) 
*/
export default function SnsInfo() {
  // let w = `${window.innerHeight}`;
  const videoId = {
    id1: 'auVQAyywHNE',
    id2: 'dSSfoJ-wg-U&t=3s',
    id3: '7xqcMbwsUBg',
    id4: 'WE2qrARu-a8',
  };
  const videoOptions = {
    width: '100%',
    height: '100%',
    playerVars: {
      autoplay: 0, //자동재생 O
      rel: 0, //관련 동영상 표시하지 않음
      modestbranding: 1, // 컨트롤 바에 youtube 로고를 표시하지 않음
      controls: 0, // 동영상 재생 컨트롤 바 등.. 나오게
      showinfo: 0,
      mute: 1,
      loop: 1,
    },
  };
  // First, second, third, fourth
  const [youtubeArr, setYoutubeArr] = useState([]);
  useEffect(() => {
    (async () => {
      const YOUTUBE_API_KEY = 'AIzaSyBeIIkHWSW-yoSlZzAgd-FeXrHnrQ6Qbfo';
      const youtubeDataFi = getYoutubeAPI()(videoId.id1, YOUTUBE_API_KEY);
      // const youtubeDataS = getYoutubeAPI()(videoId.id2, YOUTUBE_API_KEY);
      // const youtubeDataT = getYoutubeAPI()(videoId.id3, YOUTUBE_API_KEY);
      // const youtubeDataFo = getYoutubeAPI()(videoId.id4, YOUTUBE_API_KEY);

      const data = await Promise.all([
        youtubeDataFi,
        // youtubeDataS,
        // youtubeDataT,
        // youtubeDataFo,
      ]);
      setYoutubeArr(data);
      // console.log(youtubeData.items[0].snippet);
    })();
  }, [videoId.id1]);

  const settings = {
    dots: true, // 개수 표시 점
    infinite: true, // 무한 캐러셀
    speed: 500, // 다음 컨텐츠 까지의 속도
    slidesToShow: 1, // 화면에 보이는 컨텐츠 수
    slidesToScroll: 1, // 스크롤 시 넘어가는 컨텐츠 수
    centerMode: true, // 현재 컨텐츠 가운데 정렬
    centerPadding: '10px', // 중앙 컨텐츠 padding 값
    autoplay: true, // 자동 캐러셀
    autoplaySpeed: 2000, // 자동 캐러셀 속도
    draggable: true, // 드래그
    fade: false, // 사라졌다 나타나는 효과
    arrows: true, // 좌,우 버튼
    vertical: false, // 세로 캐러셀
    initialSlide: 1, // 첫 컨텐츠 번호
    pauseOnFocus: true, // focus시 정지
    pauseOnHover: true, // hover시 정지
    //   dotsClass: 'dots_custom',
    //   responsive: [
    //     // 반응형 옵션
    //     {
    //       breakpoint: 480, // (숫자)px 이하일 경우
    //       settings: {
    //         slidesToShow: 1,
    //         arrows: true,
    //       },
    //     },
    //   ],
  };

  const carouselMap = [
    {
      id: 1,
      cItem: (
        <CreatedCarouselItem>
          <img src={B1} alt="NO_IMAGE" />
        </CreatedCarouselItem>
      ),
    },
    {
      id: 2,
      cItem: (
        <CreatedCarouselItem>
          <img src={B2} alt="NO_IMAGE" />
        </CreatedCarouselItem>
      ),
    },
    {
      id: 3,
      cItem: (
        <CreatedCarouselItem>
          <img src={B3} alt="NO_IMAGE" />
        </CreatedCarouselItem>
      ),
    },
    {
      id: 4,
      cItem: (
        <CreatedCarouselItem>
          <img src={B4} alt="NO_IMAGE" />
        </CreatedCarouselItem>
      ),
    },
  ];

  const [carouselPosition, setCarouselPosition] = useState(300);
  const onChangeCarousel = (position, shift) => {
    if (shift === 'left')
      return setCarouselPosition((prev) => (prev < 300 ? prev + 200 : 300));
    if (shift === 'right')
      return setCarouselPosition((prev) => (prev > -300 ? prev - 200 : -300));
    setCarouselPosition(position);
  };
  return (
    <div>
      <div>
        <div>
          <YoutubeArea>
            <SnsBox
              desc="@secureapp8920"
              link="https://www.youtube.com/channel/UCnHKB50MecAwTrXkJsCoxRA"
              isMobile={isMobile}
            >
              {youtubeArr.map((item, index) => (
                <YoutubeWrapper key={index}>
                  <YouTubeCompo
                    videoId={item.items[0].id}
                    videoOptions={videoOptions}
                  />

                  <YoutubeTitleArea>
                    <DescBox>
                      <div>
                        <img src={YOUTUBE_ICON} alt="NO_IMAGE" />
                      </div>
                      <YouTubeTitleWrapper>
                        <YoutubeTitle>
                          {item.items[0].snippet.title}
                        </YoutubeTitle>
                      </YouTubeTitleWrapper>
                    </DescBox>
                    <button>
                      <a
                        href={`https://www.youtube.com/watch?v=${item.items[0].id}`}
                      >
                        + 바로가기
                      </a>
                    </button>
                  </YoutubeTitleArea>
                </YoutubeWrapper>
              ))}
            </SnsBox>
            <SnsBox
              desc="@secureapp8920"
              link="https://blog.naver.com/secureapp112"
              isMobile={isMobile}
            >
              <YoutubeWrapper>
                <Blog>
                  <img src={BLOG_MAIN} alt="NO_IMAGE" />
                </Blog>
                <YoutubeTitleArea>
                  <DescBox>
                    <div>
                      <img src={BLOG_ICON} alt="NO_IMAGE" />
                    </div>
                    <YouTubeTitleWrapper>
                      <YoutubeTitle>
                        몸캠피싱 피해 시 전화번호 변경해도 될까?
                      </YoutubeTitle>
                    </YouTubeTitleWrapper>
                  </DescBox>
                  <button>
                    <a
                      href="https://blog.naver.com/secureapp112/222944251449"
                      target="_blank"
                      rel="noreferrer"
                    >
                      + 바로가기
                    </a>
                  </button>
                </YoutubeTitleArea>
              </YoutubeWrapper>
            </SnsBox>
          </YoutubeArea>
          <BkBox>
            <img src={CJ} alt="NO_IMAGE" />
          </BkBox>
          {/* <SnsCarousel carouselMap={carouselMap} settings={settings} /> */}
          <CreatedCarouselArea>
            <CreatedCarouselWrapper carouselPosition={carouselPosition}>
              {carouselMap.map((item) => (
                <Fragment key={item.id}>{item.cItem}</Fragment>
              ))}
            </CreatedCarouselWrapper>
            <CreatedCarouselBtnBox>
              <div onClick={() => onChangeCarousel(null, 'left')}>{`<`}</div>
              {carouselPosition === 300 && (
                <div onClick={() => onChangeCarousel(300)}>01</div>
              )}
              {carouselPosition === 100 && (
                <div onClick={() => onChangeCarousel(100)}>02</div>
              )}
              {carouselPosition === -100 && (
                <div onClick={() => onChangeCarousel(-100)}>03</div>
              )}
              {carouselPosition === -300 && (
                <div onClick={() => onChangeCarousel(-300)}>04</div>
              )}
              <div>|</div>
              <div>04</div>
              <div onClick={() => onChangeCarousel(null, 'right')}>{`>`}</div>
            </CreatedCarouselBtnBox>
          </CreatedCarouselArea>
        </div>
      </div>
    </div>
  );
}
const CreatedCarouselArea = styled.div`
  width: 100vw;
  min-width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: hidden;
`;
const CreatedCarouselWrapper = styled.div`
  width: 400%;

  display: flex;
  justify-content: flex-start;
  margin-left: ${({ carouselPosition }) => `${carouselPosition}%`};
  transition: margin-left 0.7s ease;
`;
const CreatedCarouselItem = styled.div`
  width: 100%;

  img {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`;
const CreatedCarouselBtnBox = styled.div`
  display: flex;
  gap: 2em;
  font-size: 1.3rem;
  font-weight: bold;
  align-items: center;
  margin-top: 1em;

  div:nth-of-type(1) {
    color: #b3b3b3;
    cursor: pointer;
  }
  div:nth-of-type(2) {
    cursor: pointer;
  }
  div:nth-of-type(3) {
    font-size: 1rem;
  }
  div:nth-of-type(4) {
    color: #b3b3b3;
  }
  div:nth-of-type(5) {
    color: #b3b3b3;
    cursor: pointer;
  }
  /* div:nth-of-type(6) {
    color: #000000;
  }
  div:nth-of-type(7) {
    color: #b3b3b3;
  }
  div:nth-of-type(8) {
    color: #b3b3b3;
  } */
`;
const YoutubeTitleArea = styled.div`
  /* width: 457.5px; */
  /* max-width: 457.5px; */
  max-width: 100%;
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    font-size: 0.6rem;
    color: #1588dc;
    border: 1px solid #1588dc;
    background-color: #ffffff;
    border-radius: 3px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 450;
    padding: 0.3em 0.8em;
  }
`;

const YouTubeTitleWrapper = styled.div`
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: bold;
  color: #434343;
`;
const YoutubeTitle = styled.b`
  font-size: 0.875rem;
  color: #434343;
`;
const DescBox = styled.div`
  display: flex;
  gap: 5px;

  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  /* display: -webkit-box; */
  /* -webkit-line-clamp: 1; // 원하는 라인수 */
  /* -webkit-box-orient: vertical; */

  div:nth-of-type(1) {
    /* width: 25px; */
    img {
      /* max-width: 100%; */
    }
  }
`;

const Blog = styled.div`
  max-width: 100%;
  img {
    max-width: 100%;
  }
`;

const YoutubeArea = styled.div`
  background-color: #ececec;
  padding: 0 0 4em 0;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
const YoutubeWrapper = styled.div`
  padding: 0 1em;

  width: 100%;
  img {
    min-width: 100%;
    max-width: 100%;
  }
`;
const BkBox = styled.div`
  width: 100%;
  img {
    min-width: 100%;
    max-width: 100%;
  }
`;
const CarouselImgBox = styled.div`
  /* width: 100%;
  min-width: 100%; */
  img {
    /* max-width: 100%;
    min-width: 100%; */
  }
`;
