import { centerDisplay } from 'assets/styles/styled/baseStyles';
import styled from 'styled-components';

export default function BoxBlock({
  children,
  imgPath = '',
  bkColor = '#F2F2F2',
  paddingNum = '2.254em 0 2.58em 0',
}) {
  return (
    <BoxBlockArea imgPath={imgPath} bkColor={bkColor} paddingNum={paddingNum}>
      <BoxBlockWrapper>{children}</BoxBlockWrapper>
    </BoxBlockArea>
  );
}

const BoxBlockArea = styled.div`
  background: ${({ imgPath }) => `url(${imgPath}) no-repeat right 30%`};
  /* background: linear-gradient(green, green); */
  /* background-image: url(${({ imgPath }) => imgPath}); */
  /* background-size: 30%; */
  width: 100%;

  background-color: ${({ bkColor }) => bkColor};
  padding: ${({ paddingNum }) => paddingNum};
  ${centerDisplay}
`;

const BoxBlockWrapper = styled.div`
  width: var(--width-br-block);
`;
