import { solutionInfoOnState } from 'atoms/solutionInfoOnState';
import { solutionInfoState } from 'atoms/solutionInfoState';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

export default function CarouselItem(props) {
  const [solutionInfo, setSolutionInfo] = useRecoilState(solutionInfoState);
  const [solutionInfoOn, setSolutionInfoOn] =
    useRecoilState(solutionInfoOnState);

  const changeSol = () => {
    setSolutionInfo(props.title);
    setSolutionInfoOn((sol) => !sol);
  };

  return (
    <CarouselItemArea>
      <div>
        <img src={props.imgPath} alt="NO_IMAGE" />
      </div>
      <button onClick={changeSol}>
        + <b>{props.title}</b> 상세 더보기
      </button>
    </CarouselItemArea>
  );
}

const CarouselItemArea = styled.div`
  position: relative;
  div {
    display: flex;
    justify-content: center;
    img {
      max-width: 110%;
    }
  }

  button {
    background-color: #1588dc;
    color: #ffffff;
    font-weight: 300;
    border-radius: 8px;
    padding: 0.6em 1.2em;
    font-size: 0.8rem;
    cursor: pointer;

    position: absolute;
    top: 55%;
    right: 20%;

    b {
      font-weight: 500;
    }
  }
`;
