import LOGO from 'assets/share/logo.png';
import React from 'react';
import styled from 'styled-components';
export default function SnsBoxTitle({ desc, link, isMobile = false }) {
  return (
    <SnsBoxTitleArea isMobile={isMobile}>
      <ImgBox>
        <img src={LOGO} alt="NO_IMAGE" />
      </ImgBox>
      <InfoBox>
        <h2>시큐어앱 (SECURE APP)</h2>
        <span>{desc}</span>
        <a href={link} target="_blank" rel="noreferrer">
          + 채널바로가기
        </a>
      </InfoBox>
    </SnsBoxTitleArea>
  );
}

const SnsBoxTitleArea = styled.div`
  height: 127.27px;
  width: 457.5px;
  background-color: #d9d9d9;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;

  @media screen and (max-width: ${({ isMobile }) => isMobile && '10000px'}) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1em;
  }
`;

const ImgBox = styled.div`
  background-color: #2c3d4f;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  padding: 0.5em 0.5em;

  img {
    width: 100%;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #2c3d4f;

  h2 {
    font-weight: bold;
  }

  span {
    font-weight: 300;
  }

  a {
    margin-top: 3px;
    margin-bottom: -3px;
    font-size: 0.6rem;
    color: #1588dc;
    border: 1px solid #1588dc;
    background-color: #ffffff;

    width: 74px;
    height: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 450;
  }
`;
