import capp from 'assets/mobileImg/mainpageImg/capp.png';
import inimg from 'assets/mobileImg/mainpageImg/in.png';
import styled from 'styled-components';

import AnalysisBlock from './analysisBlock/analysisBlock';
import PressBlock from './pressBlock/pressBlock';
import SnsBlock from './snsBlock/snsBlock';
import SolutionBlock from './solutionBlock/solutionBlock';
export default function BlockContainer() {
  return (
    <BlockContainerArea>
      <SolutionBlock />
      <div style={{ maxWidth: '100%', width: '100%', marginTop: '-1px' }}>
        <img
          src={capp}
          alt="NO_IMAGE"
          style={{ maxWidth: '100%', width: '100%' }}
        />
      </div>
      <AnalysisBlock />
      <PressBlock />
      <ImgBox>
        <img src={inimg} alt="NO_IMAGE" />
      </ImgBox>
      <SnsBlock />
    </BlockContainerArea>
  );
}

const BlockContainerArea = styled.div`
  margin-top: -0.2em;
`;

const ImgBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3.5em 0;
  background-color: #dcdcdc;
  margin-top: -1px;
  img {
    max-width: 100%;
  }
`;
