import CONT from 'assets/browserImg/footerImg/cont.png';
import KAKAO from 'assets/browserImg/footerImg/kakao.png';
import KAKAO_C from 'assets/browserImg/footerImg/kakao-c.png';
import LOGO from 'assets/browserImg/footerImg/logo.png';
import TELL from 'assets/browserImg/footerImg/tell.png';
import Select from 'components/selectBox/select';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function Footer({ nav }) {
  return (
    <FooterArea nav={nav}>
      <FooterHeaderArea>
        <BorderL>|</BorderL>
        <FooterHeaderTitle>
          신속한 대처와 체계적인 시스템, 오직 <b>시큐어앱</b>에서만 !
        </FooterHeaderTitle>
        <Select />
      </FooterHeaderArea>
      <FooterBodyArea>
        <TitleWrapper>
          <Title>
            <span>
              <b>24</b>시간 전문 상담 운영
            </span>
            <span>(출장 및 방문, 상담)</span>
          </Title>
          <TitleDesc>
            시큐어앱의 <span>24시간 출장 서비스</span>는 피해를 최소화 할 수
            있는 <span>가장 전문적인 빠른 대처방법</span>입니다.
          </TitleDesc>
        </TitleWrapper>
        <NavArea>
          <a
            href={'https://open.kakao.com/o/sQNaRZGc'}
            target="_blank"
            rel="noreferrer"
          >
            <img src={KAKAO} alt="NO_IMAGE" />
          </a>
          <Border />
          <a
            href={'https://pf.kakao.com/_RirJxj'}
            target="_blank"
            rel="noreferrer"
          >
            <img src={KAKAO_C} alt="NO_IMAGE" />
          </a>
          <Border />
          <a href="tel:1661-7382">
            <img src={TELL} alt="NO_IMAGE" />
          </a>
          <Border />
          <Link to="/contact">
            <img src={CONT} alt="NO_IMAGE" />
          </Link>
        </NavArea>
        <FBodyWrapper>
          <div>
            <Link to="/">
              <img src={LOGO} alt="NO_IMAGE" style={{ width: '90%' }} />
            </Link>
            {/* <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
                fontWeight: '400',
                marginTop: 10,
                marginLeft: 5,
              }}
            >
              <div>시큐어앱 </div>
              <div> 대표 : 임한빈</div>
            </div> */}
          </div>
          <Border style={{ margin: '0 20px' }} />

          <div />
          <div>
            <p>
              인천광역시 연수구 송도과학로 32 테크노IT센터 M동 2202호
              &nbsp;&nbsp;/&nbsp;&nbsp; 사업자 번호:149-87-02852
              &nbsp;&nbsp;/&nbsp;&nbsp; 사업자명:(주)렛츠핵
              &nbsp;&nbsp;/&nbsp;&nbsp; 이메일:admin@let-shack.com
            </p>
            <p style={{ marginBottom: -5, marginTop: 5 }}>
              Copyrightⓒ SECUREAPP - All rights reserved.
            </p>
          </div>
        </FBodyWrapper>
      </FooterBodyArea>
    </FooterArea>
  );
}

const FooterArea = styled.footer`
  opacity: ${({ nav }) => nav && 0};
  margin-top: -1px;
  transition: opacity 0.8s ease;
`;
const FooterHeaderArea = styled.nav`
  position: relative;
  background-color: #111b2a;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 12em;
  border-bottom: 0;
`;
const FooterHeaderTitle = styled.h3`
  color: #888d94;
  font-weight: 300;
  padding-left: 2em;

  b {
    font-weight: var(--font-weight-l);
  }
`;

const BorderL = styled.div`
  position: absolute;
  height: 100%;
  left: 14%;
  border-left: 1px solid #888d94;
`;

const FooterBodyArea = styled.div`
  background-color: #d3d3d3;

  padding: 3em 14em 5em 14em;
`;

const TitleWrapper = styled.div``;

const Title = styled.p`
  span:nth-of-type(1) {
    font-size: 1.35rem;
    font-weight: 400;

    b {
      font-weight: 450;
    }
  }

  span:nth-of-type(2) {
    font-size: 0.8rem;
    margin-left: 0.3rem;
  }
`;
const TitleDesc = styled.p`
  font-size: 0.9rem;
  margin-top: 0.5em;
  margin-bottom: 3em;

  span:nth-of-type(1) {
    font-weight: 450;
  }

  span:nth-of-type(2) {
    color: #0059ab;
    font-weight: 450;
  }
`;

const NavArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  gap: 20px;

  a {
    img {
      width: 90%;
    }
  }
`;

const FBodyWrapper = styled.div`
  display: flex;
  align-items: center;

  color: #797979;
  font-size: 0.8rem;
  font-weight: 300;
`;

const Border = styled.span`
  border: 1px solid #b0b0b0;
  opacity: 0.3;
  height: 30px;
`;
