import { getAnalysisList } from 'api/customAxios';
import BK from 'assets/mobileImg/mainpageImg/analImg/bk.png';
import { apkDataState } from 'atoms/apkDataState';
import { apkSearchKeywordState } from 'atoms/apkSearchKeywordState';
import { detailBoolState } from 'atoms/detailBoolState';
import { mobileInputFoucsState } from 'atoms/mobileInputFocusState';
import { totalElementsState } from 'atoms/totalElementsState';
import { totalPagesState } from 'atoms/totalPagesState';
import useFocus from 'hooks/useFocus';
import useNaviCustom from 'hooks/useNaviCustom';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

export default function AnalysisInfo() {
  const [pathname, navigate] = useNaviCustom();
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(4);
  const [apkData, setApkData] = useRecoilState(apkDataState); // 검색용
  const [apkListData, setApkListData] = useState([]); // 4개짜리
  const [totalElements, setTotalElements] = useRecoilState(totalElementsState);
  const [totalPages, setTotalPages] = useRecoilState(totalPagesState);
  const [inputFocus, setSearchInputFocus] = useFocus();
  const setDetailBool = useSetRecoilState(detailBoolState);

  const [apkSearchKeyword, setApkSearchKeyword] = useRecoilState(
    apkSearchKeywordState,
  );
  const debouncedsetApkSearchKeyword = debounce(setApkSearchKeyword, 200);
  const [searchBool, setSearchBool] = useState(false);
  const setMobileInputFoucs = useSetRecoilState(mobileInputFoucsState);
  const getApkList = () => {
    try {
      (async () => {
        const { data } = await getAnalysisList(pageNo, 4)('');
        setApkListData(data);
      })();
    } catch (e) {
      alert(`
      ${e}
      내부 오류로인해 게시글을 받아오지 못했습니다.
      `);
    }
  };
  useEffect(getApkList, [pageNo]);

  const changeToSearchP = useCallback(() => {
    navigate('/analysis');
  }, [navigate]);

  useEffect(() => {
    if (searchBool) {
      setSearchBool(false);
      changeToSearchP();
    }
  }, [searchBool, changeToSearchP]);
  const getSearchApk = (e) => {
    e.preventDefault();
    try {
      (async () => {
        const { data } = await getAnalysisList(1, 9)(apkSearchKeyword);
        setApkData(data);
        setTotalElements(data.totalElements);
        setTotalPages(data.totalPages);
        setSearchBool(true);
      })();
    } catch (e) {
      alert(`
      ${e}
      내부 오류로인해 게시글을 받아오지 못했습니다.
      `);
    }
  };

  const arr = new Array(4).fill(0);
  return (
    <AnalysisInfoArea>
      <SearchWrapper onSubmit={getSearchApk}>
        <input
          placeholder="피해파일명을 입력해주세요"
          ref={inputFocus}
          onBlur={() => {
            setSearchInputFocus((prev) => !prev);
            setMobileInputFoucs(false);
          }}
          onChange={(e) => {
            debouncedsetApkSearchKeyword(e.target.value);
          }}
        />
        <button type="submit">검색</button>
      </SearchWrapper>
      <div>
        {apkListData?.content?.map((apk) => (
          <ApkListWrapper key={apk?.id}>
            <div>
              <img
                src={`https://secureappimgbucket.s3.ap-northeast-2.amazonaws.com/${apk?.filepath}`}
                alt="NO_IMAGE"
              />
            </div>
            <div>
              <h2
                onClick={() => {
                  navigate(`/analysis/${apk?.id}`);
                  setDetailBool(true);
                }}
              >
                {apk?.title}
              </h2>
              <p>{apk?.desc}</p>
            </div>
          </ApkListWrapper>
        ))}
      </div>
      <BottomImgBox>
        <img src={BK} alt="NO_IMAGE" />
      </BottomImgBox>
    </AnalysisInfoArea>
  );
}

const AnalysisInfoArea = styled.div`
  background-color: #f2f2f2;
`;

const ApkListWrapper = styled.div`
  background-color: #eaeaea;
  border-bottom: 1px solid #ffffff !important;
  padding: 0.5em 0.8em 0.5em 0.8em;

  display: flex;
  align-items: center;
  gap: 1em;

  div:nth-of-type(1) {
    /* background-color: red; */
    width: 2.6363rem;
    height: 2.6363rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
    }
  }
  div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    gap: 3px;
    color: #434343;
    h2 {
      font-weight: bold;
      font-size: 1.125rem;
    }
    p {
      font-size: 0.825rem;
    }
  }
`;
const SearchWrapper = styled.form`
  border-bottom: 1px solid #ffffff !important;
  background-color: #b8b8b8;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  padding: 1em 0.8em 1em 0.8em;

  input {
    flex: 1;
    padding: 0.6em 0.2em 0.3em 0.8em;
    max-width: 80%;

    &::placeholder {
      font-size: 0.8rem;
      color: #9e9e9e;
    }
  }

  button {
    background: #1588dc;
    color: #ffffff;
    font-size: 0.9rem;
    padding: 0.8em 1.5em 0.6em 1.5em;
    min-width: 69px;
  }
`;

const BottomImgBox = styled.div`
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
  }
`;
