import { getIssueList } from 'api/customAxios';
import Card from 'browser/pages/press/card/card';
import useNaviCustom from 'hooks/useNaviCustom';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import List from './list';

export default function PressInfo() {
  const navigate = useNaviCustom();
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(7);
  const [issueListData, setIssueListData] = useState([]); // 4개짜리
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const getApkList = () => {
    try {
      (async () => {
        const { data } = await getIssueList(pageNo, pageSize)('');
        setIssueListData(data);
        setTotalElements(data?.totalElements);
        setTotalPages(data?.totalPages);
      })();
    } catch (e) {
      alert(`
      ${e}
      내부 오류로인해 게시글을 받아오지 못했습니다.
      `);
    }
  };
  useEffect(getApkList, [pageNo, pageSize]);
  const arr = new Array(7).fill(0);
  return (
    <PressInfoArea>
      <CardWrapper>
        {issueListData?.content?.map(
          (issue, i) =>
            i <= 1 && (
              <Card
                key={issue?.id}
                bkColor="rgba(255, 255, 255, 0)"
                issue={issue}
              />
            ),
        )}
      </CardWrapper>
      <ListWrapper>
        {issueListData?.content?.map((issue, i) => (
          <List key={issue?.id} issue={issue} i={i} />
        ))}
      </ListWrapper>
    </PressInfoArea>
  );
}

const PressInfoArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 21px;
  margin-left: -30px;
`;
const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 21.1em;
  margin-top: 1em;
`;
