import MainNavBox from 'mobile/components/mainNavBox';
import React from 'react';

import PressInfo from './pressInfo';

export default function PressBlock() {
  return (
    <div>
      <MainNavBox title="보도자료" link="/press" />
      <PressInfo />
    </div>
  );
}
