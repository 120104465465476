import MainNavBox from 'mobile/components/mainNavBox';
import React from 'react';

import SolutionInfo from './solutionInfo';

export default function SolutionBlock() {
  return (
    <div>
      <MainNavBox title="SOLUTION" link="/solution" />
      <SolutionInfo />
    </div>
  );
}
