import { API, authedInstance, FormAPI, PlainAPI, YoutubeAPI } from './config';

// 회원가입
export const onSignup = () => async (params) => {
  const data = await API.post('/api/signup', params);
  return data;
};

// 로그인
export const onSignin = () => async (params) => {
  const data = await API.post('/api/authenticate', params);
  return data;
};

// 파일 업로드 (토스트 에디터용)
export const postImages = () => async (parmas) => {
  const data = await FormAPI.post('/images', parmas);
  return data;
};

// 텔레그램 신고
export const postReport = () => async (params) => {
  const data = await API.post('/api/victim', params);
  return data;
};

export const getReport = (page, size) => async (searchKeyword) => {
  const { data } = await authedInstance.get(
    `/api/victim?page=${page}&size=${size}&searchKeyword=${searchKeyword}`,
  );
  return data;
};

// 이슈 리스트 가져오기
export const getIssueList =
  (page = '0', size = '9') =>
  async (searchKeyword = '') => {
    const data = await API.get(
      `/issue?page=${page}&size=${size}&searchKeyword=${searchKeyword}`,
    );
    return data;
  };

// 이슈 디테일 가져오기
export const getIssue = (id) => async () => {
  const data = await API.get(`/issue/${id}`);
  return data;
};

// // issue 작성
export const postIssue = () => async (params) => {
  const data = await authedInstance.post('/issue', params);
  return data;
};

// issue 수정
export const putIssue = (id) => async (params) => {
  const data = await authedInstance.put(`/issue/${id}`, params);
  return data;
};

// issue 삭제
export const deleteIssue = async (id) => {
  const { status } = await authedInstance.delete(`/issue/${id}`);
  return status;
};

// analysis 리스트 가져오기
export const getAnalysisList =
  (page = '0', size = '9') =>
  async (searchKeyword = '') => {
    const data = await API.get(
      `/analysis?page=${page}&size=${size}&searchKeyword=${searchKeyword}`,
    );
    return data;
  };

// analysis 디테일 가져오기
export const getAnalysis = (id) => async () => {
  const data = await API.get(`/analysis/${id}`);
  return data;
};

// analysis 작성
export const postAnalysis = () => async (parmas) => {
  const data = await authedInstance.post('/analysis', parmas);
  return data;
};

export const postEditorImages = () => async (parmas) => {
  const data = await authedInstance.post('/images', parmas);
  return data;
};

// analysis 수정
export const putAnalysis = (id) => async (params) => {
  const data = await authedInstance.put(`/analysis/${id}`, params);
  return data;
};

// analysis 삭제
export const deleteAnalysis = async (id) => {
  const { status } = await authedInstance.delete(`/analysis/${id}`);
  return status;
};

// (혹시 모르니 남겨두자..)
export const reportDamageAPI = () => async (params) => {
  const { status } = await API.post('/report', params, {
    headers: { Authorization: window.localStorage.getItem('accessToken') },
  });
  return status;
};

// 글 삭제나 하자
export const 신고삭제 = async (id) => {
  const { data } = await API.delete(`/report/${id}`);
  return data;
};

// 여따가 디테일이나 받자
export const 신고상담사배정 = async (id, params) => {
  const { data } = await API.post(`/report/${id}/assign`, params);
  return data;
};

export const getReportConsultantListAPI =
  () => async (pageNo, numOfListsPerPage, word) => {
    const data = await API.get(
      `/report/role/CONSULTANT?step=ALL&word=${word}&pageNo=${pageNo}&pageSize=${numOfListsPerPage}&sort=createdAt%2Cdesc`,
    );
    return data;
  };

// 겟 리스트로 사용하자
export const getNoticeListAPI = async (
  pageNo = 0,
  numOfListsPerPage = 10,
  type = 'ALL',
) => {
  const { data } = await API.get(
    `/notice?pageNo=${pageNo}&pageSize=${numOfListsPerPage}&sort=createdAt%2Cdesc&type=${type}`,
  );

  return data;
};

// 유튜브
export const getYoutubeAPI = () => async (videoId, YOUTUBE_API_KEY) => {
  const { data } = await YoutubeAPI.get(
    `/v3/videos?id=${videoId}&key=${YOUTUBE_API_KEY}&part=snippet,contentDetails,statistics,status`,
  );
  return data;
};
