import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// 시큐어 어드민 임폴트 시작
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

//종료

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

export default function AdminLayout({ children, setAdminNavNum }) {
  const [collapsed, setCollapsed] = useState(false);

  const changeAdminNav = (nav) => {
    setAdminNavNum(nav);
  };

  const items = [
    // getItem(
    //   <div onClick={() => changeAdminNav(1)}>admin ID 관리</div>,
    //   1,
    //   <PieChartOutlined />,
    // ),
    // getItem(
    //   <div onClick={() => changeAdminNav(2)}>issue 게시글 확인</div>,
    //   2,
    //   <DesktopOutlined />,
    // ),
    getItem('apk 게시글', 'sub1', <UserOutlined />, [
      getItem(
        <div onClick={() => changeAdminNav(3)}>리스트 (수정, 삭제)</div>,
        3,
      ),
      getItem(<div onClick={() => changeAdminNav(4)}>작성</div>, 4),
      //   getItem('아이템3', '5'),
    ]),
    getItem('issue 게시글', 'sub2', <TeamOutlined />, [
      getItem(
        <div onClick={() => changeAdminNav(5)}>리스트 (수정, 삭제)</div>,
        5,
      ),
      getItem(<div onClick={() => changeAdminNav(6)}>작성</div>, 6),
    ]),
    getItem('victim', 'sub3', <FileOutlined />, [
      getItem(<div onClick={() => changeAdminNav(7)}>신고 내역</div>, 7),
    ]),
    // getItem('victim', '7', <FileOutlined />),
  ];
  return (
    <Layout
      style={{
        minHeight: '100vh',
        borderBottom: 'none',
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div
          className="logo"
          style={{
            height: 32,
            margin: 16,
            // background: 'rgba(255, 255, 255, 0.3)',
          }}
        />
        <Menu
          theme="dark"
          defaultSelectedKeys={['1']}
          mode="inline"
          items={items}
          style={{ marginTop: '2em' }}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: '2.25em',
          }}
        />
        <Content
          style={{
            margin: '0 16px',
          }}
        >
          <Breadcrumb
            style={{
              margin: '16px 0',
            }}
          >
            <Breadcrumb.Item>시큐어앱</Breadcrumb.Item>
            <Breadcrumb.Item>관리자</Breadcrumb.Item>
          </Breadcrumb>
          <div
            className="site-layout-background"
            style={{
              padding: 24,
              minHeight: 360,
            }}
          >
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}
