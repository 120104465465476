import H from 'assets/browserImg/headerImg/h.png';
import LOGO from 'assets/browserImg/headerImg/logo.png';
import MAGNIFIER from 'assets/browserImg/headerImg/magnifier.png';
import {
  alignCenterDisplay,
  betweenDisplay,
  centerDisplay,
} from 'assets/styles/styled/baseStyles';
import { headerHideState } from 'atoms/headerHideState';
import { navState } from 'atoms/navState';
import { searchInputFocusState } from 'atoms/searchInputFocusState';
import { PATH_MAP } from 'constants/PATH_MAP';
import useHorizontalScroll from 'hooks/useHorizontalScroll';
import useNavi from 'hooks/useNavi';
import useNaviCustom from 'hooks/useNaviCustom';
import useVisible from 'hooks/\buseVisible';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import Nav from './nav';

export default function Header({ hide, onFocus }) {
  const [pathname, onNavigate] = useNaviCustom('/');
  const setSearchInputFocus = useSetRecoilState(searchInputFocusState);
  const [nav, setNav] = useRecoilState(navState);
  const [navChecked, setNavChecked] = useState(false);
  const headerHide = useRecoilValue(headerHideState);

  const onFocusAnalSearchInput = () => {
    if (pathname !== '/') onNavigate('/analysis');
    setSearchInputFocus((focus) => !focus);
  };
  useEffect(() => {
    if (nav) setTimeout(() => setNavChecked(true), 600);
    else setTimeout(() => setNavChecked(false), 600);
  }, [nav]);

  const navigate = useNavi();
  const changeNavState = (path) => {
    setNav((prev) => !prev);
    navigate(path);
  };
  return (
    <HeaderArea headerHide={headerHide}>
      <HeaderWrapper>
        {/* style 지우고 로고 살리셈, 그리고 NAV_PATH_MAP 가서 주석푸셈 */}
        <HomeLogoBox
          onClick={() => {
            onFocus();
            onNavigate('/');
          }}
          style={{ height: '48px' }}
        >
          <img src={LOGO} alt="NO_IMAGE" />
        </HomeLogoBox>
        <NavArea>
          {!navChecked ? (
            <NavWrapper navChecked={navChecked} nav={nav}>
              <NavBox
                pathname={pathname}
                path=""
                onClick={onFocusAnalSearchInput}
              >
                <SearchBox>
                  <div>
                    <img src={MAGNIFIER} alt="NO_IMAGE" />
                  </div>
                  <span>ANALYSIS</span>
                </SearchBox>
              </NavBox>
              {PATH_MAP.map((path) => (
                <NavBox
                  key={path.id}
                  pathname={pathname}
                  path={path.path}
                  onClick={() => {
                    onNavigate(path.path);
                    onFocus();
                  }}
                >
                  <button>{path.title}</button>
                </NavBox>
              ))}
              <NavImgBox
                h="h"
                onClick={() => {
                  setNav((nav) => !nav);
                }}
              >
                <ImgBtn>
                  <img src={H} alt="NO_IMAGE" />
                </ImgBtn>
              </NavImgBox>
            </NavWrapper>
          ) : (
            <NavTrueWrapper nav={nav}>
              <NavSearchWrapper
                onClick={() => {
                  onFocusAnalSearchInput();
                  setNav((nav) => !nav);
                }}
              >
                <div>
                  <img src={MAGNIFIER} alt="NO_IMAGE" />
                </div>
                <span>analysis</span>
              </NavSearchWrapper>
              <NavSearchWrapper>상담문의</NavSearchWrapper>
              <ChangeNav onClick={() => setNav((nav) => !nav)} />
            </NavTrueWrapper>
          )}
        </NavArea>
        <Nav nav={nav} changeNavState={changeNavState} onFocus={onFocus} />
      </HeaderWrapper>
    </HeaderArea>
  );
}

const HeaderArea = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color-base);
  padding: 1em 12em 0.5em 12em;

  z-index: 12;
  position: fixed;
  top: ${({ headerHide }) => (headerHide ? '-30rem' : '0')};
  z-index: 10;
  transition: top 1s ease;
  max-width: 100%;
`;

const HeaderWrapper = styled.div`
  ${betweenDisplay}
  background-color: var(--background-color-base);
  position: relative;
  button {
    background-color: var(--background-color-base);
  }
  width: 1056px;
  align-items: center;
`;

const HomeLogoBox = styled.button`
  cursor: pointer;
`;

const NavArea = styled.ul`
  ${alignCenterDisplay}
  gap: 0.8em;
`;

const NavWrapper = styled(NavArea)`
  transition: 0.6s;
  opacity: ${({ navChecked, nav }) => (!navChecked && !nav ? 1 : 0)};
`;

const SearchBox = styled.button`
  display: flex !important;
  ${alignCenterDisplay};
  padding-right: 6em;
`;

const NavBox = styled.li`
  margin-left: ${({ h }) => (h === 'h' ? '-0.5em' : '')};

  span,
  button {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    width: ${({ path }) =>
      path === '/press' ? '3.2em' : path === '' ? '6.3em' : '5.0625em'};
    font-size: var(--font-size-base);
    color: ${({ pathname, path }) =>
      path === ''
        ? 'var(--font-color-0059AB)'
        : pathname === path
        ? 'var(--font-color-1588DC)'
        : 'var(--font-color-354660)'};
    font-weight: ${({ pathname, path }) =>
      path === ''
        ? 'var(--font-weight-l)'
        : pathname === path
        ? 'var(--font-weight-l)'
        : 'var(--font-weight-md)'};
    transition: 0.2s;
  }
`;

const NavImgBox = styled(NavBox)`
  margin-left: ${({ h }) => (h === 'h' ? '-0.5em' : '')};

  button {
    -webkit-transition-duration: 4s;
    -moz-transition-duration: 4s;
    -ms-transition-duration: 4s;
    -o-transition-duration: 4s;
    transition-duration: 4s;
  }
`;

const ImgBtn = styled.button`
  &:focus {
    -webkit-transform: translate(100px, -100px) scale(0.6) rotate(180deg);
    -moz-transform: translate(100px, -100px) scale(0.6) rotate(180deg);
    -ms-transform: translate(100px, -100px) scale(0.6) rotate(180deg);
    -o-transform: translate(100px, -100px) scale(0.6) rotate(180deg);
    transform: translate(100px, -100px) scale(0.6) rotate(180deg);
  }
`;

const NavTrueWrapper = styled.div`
  ${alignCenterDisplay}
  gap: 10px;
  height: 40px;

  transition: 0.6s;
  opacity: ${({ nav }) => (!nav ? 0 : 1)};
`;

const NavSearchWrapper = styled.button`
  ${centerDisplay}
  background-color: var(--background-color-base);
  font-weight: var(--font-weight-l);
  color: var(--font-color-1588DC);
  border: 1px solid var(--font-color-1588DC) !important;
  width: 143px;
  height: 33px;
  cursor: pointer;
  &:focus {
    border: 2px solid var(--font-color-1588DC);
  }
`;

const ChangeNav = styled.button`
  font-size: 2.5rem;
  font-weight: 100;
  margin-left: 0.8em;
  margin-right: 0.8em;
  margin-bottom: 0;
  margin-top: -16px;
  -webkit-margin-before: -7px;

  cursor: pointer;

  display: inline-block;
  *display: inline;

  &:after {
    display: inline-block;
    content: '\\00d7';
    font-size: 35pt;
  }

  -webkit-transition-duration: 4s;
  -moz-transition-duration: 4s;
  -ms-transition-duration: 4s;
  -o-transition-duration: 4s;
  transition-duration: 4s;

  &:focus {
    -webkit-transform: translate(100px, -100px) scale(0.6) rotate(180deg);
    -moz-transform: translate(100px, -100px) scale(0.6) rotate(180deg);
    -ms-transform: translate(100px, -100px) scale(0.6) rotate(180deg);
    -o-transform: translate(100px, -100px) scale(0.6) rotate(180deg);
    transform: translate(100px, -100px) scale(0.6) rotate(180deg);
  }
`;
