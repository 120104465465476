import axios from 'axios';

import { interceptors } from './service';

// axios.defaults.baseURL = process.env.REACT_APP_BOHOME_BASE_URL;
// axios.defaults.baseURL = 'https://api.bohome.co.kr/';
// axios.defaults.baseURL = 'http://localhost:8080';
// axios.defaults.headers.common['Authorization'] =
//   window.localStorage.getItem('accessToken');
// axios.defaults.headers.get['Content-type'] = 'application/json';
// axios.defaults.headers.post['Content-type'] = 'multipart/form-data';

// https://secure-app.bohome.co.kr/  << baseURL 사용하셈

const youtubeConfig = {
  baseURL: 'https://www.googleapis.com/youtube/',
  headers: {
    Authorization: 'application/json',
  },
};
const jsonHeaders = {
  baseURL: process.env.REACT_APP_SECUREAPP_BASEURL,
  // baseURL: 'https://secure-app.bohome.co.kr',
  // baseURL: 'http://localhost:8080',
  headers: {
    // Authorization:
    //   'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImF1dGgiOiJST0xFX0FETUlOLFJPTEVfVVNFUiIsImV4cCI6MTY3NjgyOTU1OX0.UaR3Oz8BUXuwZl3d8NArvbeAVASCwXzanV7LFjTiP62mdQNUINy8DjVMP7HfNu1GHlJG6gKhahuLa-bwF4a16w',
    'Content-Type': 'application/json',
  },
};

const formHeaders = {
  baseURL: process.env.REACT_APP_SECUREAPP_BASEURL,
  // baseURL: 'https://secure-app.bohome.co.kr',
  // baseURL: 'http://localhost:8080',
  headers: {
    // Authorization:
    //   'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImF1dGgiOiJST0xFX0FETUlOLFJPTEVfVVNFUiIsImV4cCI6MTY3NjgyOTU1OX0.UaR3Oz8BUXuwZl3d8NArvbeAVASCwXzanV7LFjTiP62mdQNUINy8DjVMP7HfNu1GHlJG6gKhahuLa-bwF4a16w',
    'Content-Type': 'multipart/form-data',
  },
};
const formHeaders2 = {
  baseURL: process.env.REACT_APP_SECUREAPP_BASEURL,
  // baseURL: 'https://secure-app.bohome.co.kr',
  // baseURL: 'http://localhost:8080',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// 여기부터
// const onFulfilled = (response) => {
//   // HTTP status가 2XX일 때 처리하고 싶은 로직이 있다면 여기에서 처리함
//   // 데이터 받기에 성공했으므로 받은 response를 그대로 return 해준다.
//   // 물론 따로 가공해도 됩니다.
//   return response;
// };
// const onRejected = (error) => {
//   // HTTP status가 2XX이 아닐 때 여기를 통과하게 됨
//   // return은 항상 Promise.reject(error)로 해야함
//   return Promise.reject(error);
// };

// const customAxiosInstance = () => {
//   const axiosInstance = axios.create();
//   const onFulfilled = (response) => response;
//   const retry = (errorConfig) => {
//     return new Promise((resolve) => {
//       setTimeout(() => {
//         console.log("retry");
//         resolve(axiosInstance.request(errorConfig));
//       }, 5000);
//     });
//   };
//   const onRejected = (error) => {
//     if (error.config) {
//       return retry(error.config);
//     }

//     return Promise.reject(error);
//   };
//   axiosInstance.interceptors.response.use(onFulfilled, onRejected);
//   return axiosInstance;
// };
// try {
//   const apiRequest = customAxiosInstance();
//   const response = await apiRequest.get(API_URL);
// } catch {}
// // 여기 까지
// axios.interceptors.response.use(onFulfilled, onRejected);

const API = axios.create(jsonHeaders);

const authAPI = (url, options) => {
  const instance = axios.create({ baseURL: url, ...options });

  interceptors(instance);
  return instance;
};
const authedInstance = authAPI(process.env.REACT_APP_SECUREAPP_BASEURL);
// const authedInstance = authAPI('https://secure-app.bohome.co.kr');
// const authedInstance = authAPI('http://localhost:8080');

const FormAPI = axios.create(formHeaders);
const YoutubeAPI = axios.create(youtubeConfig);
// const PlainAPI = axios.create(headers);

//   API.interceptors.request.use(headerTokenConfig);
//   API.interceptors.response.use(null, refreshInterceptor(API));

// const fetchUsers = async () => { 예제코드 적용
//   try {
//     dispatch({ type: 'LOADING' });
//     console.log('첫 API 호출이 되었습니다');
//     const apiRequest = customAxiosInstance();
//     const response = await apiRequest.get('https://jsonplaceholder.typicode.com/users');
//     dispatch({ type: 'SUCCESS', data: response.data });
//   } catch (e) {
//     dispatch({ type: 'ERROR', error: e });
//   }
// };

export { API, authedInstance, FormAPI, YoutubeAPI };
