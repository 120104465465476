import Arrow from 'assets/browserImg/sol/tap/arrow.png';
import S from 'assets/browserImg/sol/tap/s.png';
import T from 'assets/browserImg/sol/tap/t3.png';
import IImg1 from 'assets/browserImg/sol/tap/tap3/bi1.png';
import IImg2 from 'assets/browserImg/sol/tap/tap3/bi2.png';
import OImg1 from 'assets/browserImg/sol/tap/tap3/bo1.png';
import OImg2 from 'assets/browserImg/sol/tap/tap3/bo2.png';
import TB from 'assets/browserImg/sol/tap/tb3.png';
import V from 'assets/video/emergency.mp4';
import React from 'react';
import styled from 'styled-components';

import TapImgBox from './tapImgBox';
import TapImgBoxLayout from './tapImgBoxLayout';
import VideoLayout from './videoLaout';

export default function Emergency() {
  return (
    <div>
      <VideoLayout T={T} TB={TB} Arrow={Arrow} V={V} />
      <TapImgBoxLayout>
        <TapImgBox iImg={IImg1} oImg={OImg1} bkImg={true} />
        <TapImgBox iImg={IImg2} oImg={OImg2} />
      </TapImgBoxLayout>
      <ImgBox>
        <img src={S} alt="NO_IMAGE" />
      </ImgBox>
    </div>
  );
}

const ImgBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5em;
  margin-bottom: 3em;

  img {
    width: 25em;
  }
`;
