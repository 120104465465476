import './slick_css.css';

import SlickCarousel from 'components/carousel/slickCarousel';
import React from 'react';
import styled from 'styled-components';

export default function SolCarousel({ carouselMap, settings }) {
  return (
    <CarouselArea>
      <SlickCarousel
        carouselMap={carouselMap}
        settings={settings}
        dotsClass="dots_custom"
      />
    </CarouselArea>
  );
}

const CarouselArea = styled.div`
  margin-left: auto;
  margin-right: auto;
`;
