import CONT from 'assets/mobileImg/footerImg/cont.png';
import KAKAO from 'assets/mobileImg/footerImg/kakao.png';
import KAKAOC from 'assets/mobileImg/footerImg/kakao-c.png';
import LOGO from 'assets/mobileImg/footerImg/logo.png';
import TELL from 'assets/mobileImg/footerImg/tell.png';
import Select from 'components/selectBox/select';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function Footer() {
  return (
    <FooterArea>
      <SelectBox>
        <Select />
      </SelectBox>
      <FooterInfoWrapper>
        <InfoDescBox>
          <p>
            24시간 전문 상담 운영 <span>(출장 및 방문,상담)</span>
          </p>
          <p>
            <span>시큐어앱</span>의 <span>24시간 출장 서비스</span>는 피해를
            최소화 할 수 있는 <span>가장 전문적인 빠른 대처방법</span>입니다.
          </p>
        </InfoDescBox>
        <ImgWrapper>
          <a
            href={'https://open.kakao.com/o/sQNaRZGc'}
            target="_blank"
            rel="noreferrer"
          >
            <img src={KAKAO} alt="NO_IMAGE" />
          </a>
          <a
            href={'https://pf.kakao.com/_RirJxj'}
            target="_blank"
            rel="noreferrer"
          >
            <img src={KAKAOC} alt="NO_IMAGE" />
          </a>
          <a href="tel:1661-7382">
            <img src={TELL} alt="NO_IMAGE" />
          </a>
          <Link to="/contact">
            <img src={CONT} alt="NO_IMAGE" />
          </Link>
        </ImgWrapper>
        <AddressArea>
          <div>
            <img src={LOGO} alt="NO_IMAGE" />
          </div>
          <div>
            {/* <span>시큐어앱</span>
            <span>대표 : 임한빈</span> */}
          </div>

          <address>인천 연수구 송도과학로 32 M동, 2202</address>
          <div>
            <p>
              <span>사업자등록번호 : </span>149-87-02852
            </p>
            <p>
              <span>사업자명 : </span>
              (주)렛츠핵
            </p>
          </div>
          <p>
            <span>이메일 : </span> admin@let-shack.com
          </p>
          <p>Copyrightⓒ SECUREAPP - All rights reserved.</p>
        </AddressArea>
      </FooterInfoWrapper>
    </FooterArea>
  );
}

const SelectBox = styled.div`
  background-color: #111b2a;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;

  div:nth-of-type(1) {
    div:nth-of-type(1) {
      width: 100%;
    }
  }
`;

const FooterArea = styled.footer`
  margin-top: 1.8em;
  z-index: 1;
  background-color: #d3d3d3;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 100%;
  padding-bottom: 3em;
`;

const FooterInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

const InfoDescBox = styled.div`
  padding: 2em 3em 2em 1.5em;
  p:nth-of-type(1) {
    color: #0059ab;
    font-weight: bold;
    line-height: 2em;
    span:nth-of-type(1) {
      font-size: 0.9rem;
    }
  }
  p:nth-of-type(2) {
    font-weight: 300;
    color: rgba(67, 67, 67, 0.6);
    font-size: 0.9rem;
    line-height: 1.5em;

    span:nth-of-type(1) {
      color: rgba(67, 67, 67, 1);
    }
    span:nth-of-type(2) {
      font-weight: 400;
      color: rgba(67, 67, 67, 1);
    }
    span:nth-of-type(3) {
      color: rgba(67, 67, 67, 1);
    }
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;

  a {
    padding: 1em 1em 1em 2em;
    border-top: 1px solid #b0b0b0;
    width: 100%;

    img {
      max-width: 100%;
    }
  }

  a:nth-of-type(1) {
    img {
    }
  }
  a:nth-of-type(2) {
    img {
    }
  }
  a:nth-of-type(3) {
    img {
      /* margin-left: 5%; */
    }
  }
  a:nth-of-type(4) {
    border-bottom: 1px solid #b0b0b0 !important;

    img {
      margin-top: 0.3em;
      /* margin-left: 11.5%; */
    }
  }
`;

const AddressArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 2.5em 0 3em 0;

  div:nth-of-type(1) {
  }
  div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    color: #434343;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 1.7em;
    font-weight: 300;
    span {
      line-height: 1.2rem;
    }
  }

  address {
    color: #434343;
    font-weight: 300;
    line-height: 1.3rem;
  }
  p:nth-of-type(1) {
    color: #434343;
    font-weight: 300;
    line-height: 1.3rem;

    span {
      font-weight: 500;
    }
  }
  div:nth-of-type(3) {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center !important;
    justify-content: center;
    @media screen and (max-width: 380px) {
      flex-direction: column;
      align-items: center;
      gap: 0;
    }
    p:nth-of-type(1) {
      color: #434343;
      font-weight: 300;
      line-height: 1.3rem;
      font-size: 1rem;

      span {
        font-weight: 500;
      }
    }
    p:nth-of-type(2) {
      margin: 0;
      padding: 0;
      color: #434343;
      font-weight: 300;
      line-height: 1.3rem;
      font-size: 1rem;

      span {
        font-weight: 500;
      }
    }
  }
  p:nth-last-of-type(1) {
    color: #797979;
    font-weight: 300;
    font-size: 0.85rem;

    margin-top: 1.8rem;
  }
`;
