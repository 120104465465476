import '@toast-ui/editor-plugin-table-merged-cell/dist/toastui-editor-plugin-table-merged-cell.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-table-merged-cell/dist/toastui-editor-plugin-table-merged-cell.css';
import '@toast-ui/chart/dist/toastui-chart.css';
import '@toast-ui/editor/dist/theme/toastui-editor-dark.css';
import '@toast-ui/editor/dist/toastui-editor.css';

import chart from '@toast-ui/editor-plugin-chart';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import tableMergedCell from '@toast-ui/editor-plugin-table-merged-cell';
import uml from '@toast-ui/editor-plugin-uml';
import { Editor } from '@toast-ui/react-editor';
import React from 'react';

export default function ToastEditor({ editorRef, content, setData }) {
  return (
    <div>
      <Editor
        usageStatistics={false}
        initialValue={content}
        previewStyle="vertical"
        // theme={""}
        height="600px"
        initialEditType="wysiwyg"
        useCommandShortcut={true}
        plugins={[
          chart,
          codeSyntaxHighlight,
          colorSyntax,
          tableMergedCell,
          uml,
        ]}
        onChange={() => {
          const editorTxt = editorRef.current.getInstance().getHTML();
          setData((prev) => ({ ...prev, content: editorTxt }));
        }}
        ref={editorRef}
      />
    </div>
  );
}
