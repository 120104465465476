import Sol01 from 'assets/browserImg/sol/carousel/solution_01.png';
import Sol02 from 'assets/browserImg/sol/carousel/solution_02.png';
import Sol03 from 'assets/browserImg/sol/carousel/solution_03.png';
import Sol04 from 'assets/browserImg/sol/carousel/solution_04.png';
import Sol05 from 'assets/browserImg/sol/carousel/solution_05.png';

import CarouselItem from './carouselItem';

export const carouselMap = [
  {
    id: 1,
    cItem: <CarouselItem imgPath={Sol01} link={''} title="악성코드 분석" />,
  },
  {
    id: 2,
    cItem: <CarouselItem imgPath={Sol02} link={''} title="패킷 변조 솔루션" />,
  },
  {
    id: 3,
    cItem: <CarouselItem imgPath={Sol03} link={''} title="긴급 대처 솔루션" />,
  },
  {
    id: 4,
    cItem: <CarouselItem imgPath={Sol04} link={''} title="영상 추적 솔루션" />,
  },
  {
    id: 5,
    cItem: <CarouselItem imgPath={Sol05} link={''} title="모바일 포렌식" />,
  },
];
