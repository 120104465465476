import { recoilTokenState } from 'atoms/recoilTokenState';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import getLocalStorage from 'utils/getLocalStrorage';

export default function useGetToken() {
  const setRecoilToken = useSetRecoilState(recoilTokenState);
  const { token, tokenBool } = getLocalStorage();

  useEffect(() => {
    if (tokenBool) setRecoilToken(token);
  }, [tokenBool, setRecoilToken, token]);

  if (!token) return null;

  if (tokenBool) {
    // 토큰이 만료되지 않았을 때의 처리
    return token;
  }

  // 토큰이 만료되었을 때의 처리
  setRecoilToken('');
  return '만료';
}
