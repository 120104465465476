import { getAnalysis } from 'api/customAxios';
import { detailBoolState } from 'atoms/detailBoolState';
import useNaviCustom from 'hooks/useNaviCustom';
import React, { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import Analysis from './analysis';

export default function ApkShow() {
  const { id } = useParams();
  const [detailData, setDetailData] = useState({});
  const [detailBool, setDetailBool] = useRecoilState(detailBoolState);
  const [pathname, navigate] = useNaviCustom();

  useLayoutEffect(() => {
    (async () => {
      const { data } = await getAnalysis(id)();
      setDetailData(data);
    })();
  }, [id]);
  const { title, desc, createdDate, content, type, filename, filepath } =
    detailData;
  return (
    <Analysis>
      <ApkShowArea>
        <ApkTitleWrapper>
          {/* <ApkMainImgWrapper>
            <img
              src={`https://secureappimgbucket.s3.ap-northeast-2.amazonaws.com/${filepath}`}
              alt="NO_IMAGE"
            />
          </ApkMainImgWrapper> */}
          <ApkInfoWrapper>
            <span>{createdDate?.slice(0, 10)}</span>
            <h2>{title}</h2>
            <p>{desc}</p>
          </ApkInfoWrapper>
        </ApkTitleWrapper>
        <ContentArea dangerouslySetInnerHTML={{ __html: content }} />
        <span
          style={{
            color: '#354660',
            fontWeight: '600',
            marginTop: '1.5em',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          onClick={() => {
            setDetailBool(false);
            navigate('/analysis');
          }}
        >
          + list 보기
        </span>
      </ApkShowArea>
    </Analysis>
  );
}

const ApkShowArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: #ececec;
  padding-bottom: 2.5em;
`;

const ApkTitleWrapper = styled.div``;

const ContentArea = styled.div`
  padding: 1em 1em;

  img {
    max-width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  strong {
    font-weight: bold;
  }

  font-size: 1rem;
  h1 {
    font-size: 1.4rem;
  }
  h2 {
    font-size: 1.3rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  h4 {
    font-size: 1.125rem;
  }
  h5 {
    font-size: 1.1rem;
  }
  font-size: 0.9rem;
  line-height: 1.3rem;

  hr {
    margin: 10px 0px;
    border-top-width: 1px;
    box-sizing: border-box;
    border-top: 1px solid #f2f3f5;
  }

  table {
    box-sizing: border-box;
    border-collapse: collapse;
    border-spacing: 0px;
    color: rgb(0, 0, 0);
    max-width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 32px;
    border: 1px solid black;

    thead {
      box-sizing: border-box;
      background-color: rgb(75, 73, 73);
      color: white;
      max-width: 100%;
      tr {
        box-sizing: border-box;
        max-height: 100%;
        th {
          box-sizing: border-box;
          margin: 0px;
          padding: 0px;
          border: 1px solid black;
          line-height: 2;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    tbody {
      box-sizing: border-box;
      max-width: 100%;

      tr {
        box-sizing: border-box;
        max-height: 100%;

        td {
          box-sizing: border-box;
          margin: 0px;
          padding: 0px;
          border: 1px solid black;
          line-height: 2;
          max-width: 100%;
          max-height: 100%;

          p {
            text-align: center;
            margin: {
              left: 8px;
              right: 8px;
            }
          }
        }
      }
    }
  }
`;

const ApkMainImgWrapper = styled.div`
  /* background-color: red; */
  padding: 1em 1em;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    /* background-color: yellow; */
    max-width: 100%;
  }
`;

const ApkInfoWrapper = styled.div`
  padding: 1em 1em;

  display: flex;
  flex-direction: column;
  gap: 1em;
  span {
    color: #7a7a7a;
    font-weight: 600;
    font-size: 0.9rem;
  }
  h2 {
    color: #434343;
    font-weight: 600;
    font-size: 1.125rem;
  }
  p {
    color: #434343;
    font-size: 0.9rem;
  }
`;
