import Arrow from 'assets/browserImg/sol/tap/arrow.png';
import P1 from 'assets/browserImg/sol/tap/p1.png';
import P2 from 'assets/browserImg/sol/tap/p2.png';
import T from 'assets/browserImg/sol/tap/t2.png';
import IImg1 from 'assets/browserImg/sol/tap/tap2/bi1.png';
import IImg2 from 'assets/browserImg/sol/tap/tap2/bi2.png';
import IImg3 from 'assets/browserImg/sol/tap/tap2/bi3.png';
import OImg1 from 'assets/browserImg/sol/tap/tap2/bo1.png';
import OImg2 from 'assets/browserImg/sol/tap/tap2/bo2.png';
import OImg3 from 'assets/browserImg/sol/tap/tap2/bo3.png';
import TB from 'assets/browserImg/sol/tap/tb2.png';
import V from 'assets/video/packet.mp4';
import React from 'react';
import styled from 'styled-components';

import TapImgBox from './tapImgBox';
import TapImgBoxLayout from './tapImgBoxLayout';
import VideoLayout from './videoLaout';

export default function Packet() {
  return (
    <div>
      <VideoLayout T={T} TB={TB} Arrow={Arrow} V={V} />
      <TapImgBoxLayout>
        <TapImgBox iImg={IImg1} oImg={OImg1} bkImg={true} />
        <TapImgBox iImg={IImg2} oImg={OImg2} />
        <TapImgBox iImg={IImg3} oImg={OImg3} />
      </TapImgBoxLayout>
      <ImgBoxWrapper>
        <div>
          <img src={P1} alt="NO_IMAGE" />
        </div>
        <div>
          <img src={P2} alt="NO_IMAGE" />
        </div>
      </ImgBoxWrapper>
    </div>
  );
}

const ImgBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 55px;
  margin: 3em 0;
  div {
    display: flex;
    justify-content: center;
  }

  div:nth-of-type(1) {
    img {
      width: 54em;
    }
  }
  div:nth-of-type(2) {
    img {
      width: 58em;
    }
  }
`;
