import MBK from 'assets/mobileImg/mainpageImg/mbk.png';
import useNaviCustom from 'hooks/useNaviCustom';
import React from 'react';
import styled from 'styled-components';

import BlockContainer from './blockContainer';

export default function Home() {
  const [pathname, navigate] = useNaviCustom();
  return (
    <HomeArea>
      <TopImgBox>
        <img src={MBK} alt="NO_IMAGE" />
        <BtnArea>
          <MainBtn onClick={() => navigate('/contact')}>+ 상담문의</MainBtn>
          <MainBtnC href="tel:1661-7382">+ 전화문의</MainBtnC>
        </BtnArea>
      </TopImgBox>
      <BlockContainer />
    </HomeArea>
  );
}
const BtnArea = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;
  bottom: 5%;
  left: 38%;
  gap: 10px;
`;

const MainBtn = styled.button`
  /* position: absolute;
  bottom: 17%;
  left: 48%; */
  background-color: rgba(253, 68, 27, 0.7);
  border: 0.5px solid rgba(255, 255, 255, 0.5) !important;
  /* color: var(--font-color-ffffff); */
  color: rgba(255, 255, 255, 0.7);
  /* opacity: 0.8; */
  font-weight: 300;
  padding: 0.6em 1em 0.6em 1em;
  /* border: 0.5px solid rgba(255, 255, 255, 0.5); */
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.5) !important;
  cursor: pointer;

  &:hover {
    color: rgba(255, 255, 255, 0.6);
  }
`;
const MainBtnC = styled.a`
  /* position: absolute;
  bottom: 17%;
  left: 48%; */
  background-color: rgba(253, 68, 27, 0.7);
  border: 0.5px solid rgba(255, 255, 255, 0.5) !important;
  /* color: var(--font-color-ffffff); */
  color: rgba(255, 255, 255, 0.7);
  /* opacity: 0.8; */
  font-weight: 300;
  padding: 0.6em 1em 0.6em 1em;
  /* border: 0.5px solid rgba(255, 255, 255, 0.5); */
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.5) !important;
  cursor: pointer;

  &:hover {
    color: rgba(255, 255, 255, 0.6);
  }
`;

const HomeArea = styled.div`
  width: 100%;
  margin-top: -23%;
`;
const TopImgBox = styled.div`
  width: 100%;
  position: relative;

  img {
    width: 100%;
    max-width: 100%;
  }

  /* div {
    position: absolute;
    background-color: rgba(255, 255, 255, 0);
    width: 26%;
    height: 8.2%;
    bottom: 18%;
    left: 38%;
    z-index: 1;
    cursor: pointer;
  } */
`;
