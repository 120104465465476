import { atom } from 'recoil';

export const reportDataState = atom({
  key: 'reportDataState',
  default: {
    name: '', // 성명
    birthday: '', // 생년월일
    victimNumber: '', // 피해당한 번호
    phoneModel: '', // 폰 기종 (아이폰, 안드로이드)
    firstContactTime: '', // 처음 접촉시간
    damageOccurrenceTime: '', // 피해발생 시간
    damageOccurrence: '', // 피해 금액 발생 유무
    secureAppContactPath: '', // 접총방식
    content: '', // 피해내용
  },
});
