import { recoilTokenState } from 'atoms/recoilTokenState';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import useGetToken from './useGetToken';

export default function useGetRecoilToken() {
  const recoilToken = useRecoilValue(recoilTokenState);
  const setRecoilToken = useSetRecoilState(recoilTokenState);
  const getToken = useGetToken();

  if (getToken !== '만료') {
    return recoilToken;
  }

  const removeLocalStorage = () => {
    alert('다시 로그인해주세요.');
    localStorage.removeItem('secureappToken');
    setRecoilToken('');
  };

  removeLocalStorage();
}
