import app from 'assets/browserImg/mainpageImg/app.png';
import inimg from 'assets/browserImg/mainpageImg/in.png';
import styled from 'styled-components';

import AnalysisBlock from './analysisBlock/analysisBlock';
import PressBlock from './pressBlock/pressBlock';
import SnsBlock from './snsBlock/snsBlock';
import SolutionBlock from './solutionBlock/solutionBlock';

export default function BlockArea() {
  return (
    <BlockAreaStyled>
      <SolutionBlock />
      <div
        style={{
          width: '100%',
          backgroundColor: '#C9E1F3',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '-1px',
        }}
      >
        <img
          src={app}
          alt="NO_IMAGE"
          style={{ maxWidth: '67%', marginBottom: '-1.2px' }}
        />
      </div>
      <AnalysisBlock />
      <PressBlock />
      <ImgBox>
        <img src={inimg} alt="NO_IMAGE" />
      </ImgBox>
      <SnsBlock />
    </BlockAreaStyled>
  );
}

const BlockAreaStyled = styled.div``;
const ImgBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3.5em 0;
  background-color: #dcdcdc;
  margin-top: -1px;
  img {
    max-width: 100%;
    width: 65%;
  }
`;
