import { searchInputFocusState } from 'atoms/searchInputFocusState';
import { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';

export default function useFocus() {
  const [searchInputFocus, setSearchInputFocus] = useRecoilState(
    searchInputFocusState,
  );
  const inputFocus = useRef();
  const onFocusRef = () => searchInputFocus && inputFocus.current.focus();
  useEffect(onFocusRef, [searchInputFocus, inputFocus]);

  return [inputFocus, setSearchInputFocus];
}
