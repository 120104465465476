import MainNavBox from 'mobile/components/mainNavBox';
import React from 'react';

import SnsInfo from './snsInfo';

export default function SnsBlock() {
  return (
    <div>
      <MainNavBox title="SNS" link="" />
      <SnsInfo />
    </div>
  );
}
