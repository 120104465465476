import EMERGENCY from 'assets/browserImg/mainpageImg/solImg/emergency.png';
import FORENSICS from 'assets/browserImg/mainpageImg/solImg/forensics.png';
import MAGNIFIER from 'assets/browserImg/mainpageImg/solImg/magnifier.png';
import PACKET from 'assets/browserImg/mainpageImg/solImg/packet.png';
import TRACKING from 'assets/browserImg/mainpageImg/solImg/tracking.png';
import EMERGENCY_V from 'assets/video/emergency.mp4';
import FORENSICS_V from 'assets/video/forensics.mp4';
import MAGNIFIER_V from 'assets/video/magnifier.mp4';
import PACKET_V from 'assets/video/packet.mp4';
import TRACKING_V from 'assets/video/tracking.mp4';
import { solutionInfoOnState } from 'atoms/solutionInfoOnState';
import { solutionInfoState } from 'atoms/solutionInfoState';
import useNavi from 'hooks/useNavi';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
const solutionInfoMap = [
  {
    id: 1,
    title: '악성코드 분석',
    imgPath: MAGNIFIER,
  },
  {
    id: 2,
    title: '패킷 변조 솔루션',
    imgPath: PACKET,
  },
  {
    id: 3,
    title: '긴급 대처 솔루션',
    imgPath: EMERGENCY,
  },
  {
    id: 4,
    title: '영상 추적 솔루션',
    imgPath: TRACKING,
  },
  {
    id: 5,
    title: '모바일 포렌식',
    imgPath: FORENSICS,
  },
];
const solutionDescMap = {
  '악성코드 분석': (
    <p>
      공격경로 및 피해규모 파악 후 홈페이지 내 분석 보고서 게시 및 의뢰자에
      맞춤형 상황 최적 솔루션 제공
    </p>
  ),
  '패킷 변조 솔루션': (
    <p>
      획득한 <b>API</b> 경로 및 <b>C&C</b> 서버에 침투해 피해 데이터 확보 후
      패킷 변조를 통한 탈취 정보 보호
    </p>
  ),
  '긴급 대처 솔루션': (
    <p>
      개인 정보 완전 탈취 및 지인 유포 감행 협박 시 피해 확산 방지 및 예방을
      위한 비상 대응 솔루션
    </p>
  ),
  '영상 추적 솔루션': (
    <p>
      메이져 플랫폼에 영상 업로드 실시간 탐지 및 <b>24</b>시간 모니터링을 통한
      재유포 방지
    </p>
  ),
  '모바일 포렌식': (
    <p>
      자사 보유 전문 소프트웨어를 통해 범행에 사용된 어플리케이션 및 기타 필요
      데이터 복구
    </p>
  ),
};

export default function SolutionInfo() {
  const [videoT, setVideoT] = useState(MAGNIFIER_V);
  const [solutionInfo, setSolutionInfo] = useRecoilState(solutionInfoState);
  const [solutionInfoOn, setSolutionInfoOn] =
    useRecoilState(solutionInfoOnState);
  const navigate = useNavi();
  useEffect(() => {
    if (solutionInfo === '악성코드 분석') return setVideoT(MAGNIFIER_V);
    if (solutionInfo === '패킷 변조 솔루션') return setVideoT(PACKET_V);
    if (solutionInfo === '긴급 대처 솔루션') return setVideoT(EMERGENCY_V);
    if (solutionInfo === '영상 추적 솔루션') return setVideoT(TRACKING_V);
    if (solutionInfo === '모바일 포렌식') return setVideoT(FORENSICS_V);
  }, [solutionInfo]);
  const randerSolDesc = (desc) => {
    return solutionDescMap[desc];
  };

  return (
    <SolutionInfoArea>
      <SolutionNavWrapper>
        {solutionInfoMap.map((solItem) => (
          <ImgWrapper
            key={solItem.id}
            onClick={() => setSolutionInfo(solItem.title)}
          >
            <ImgBox>
              <img src={solItem.imgPath} alt="NO_iMAGE" />
            </ImgBox>
            <SolItemTitle solutionInfo={solutionInfo} solItem={solItem.title}>
              {solItem.title}
            </SolItemTitle>
            <Pointer solutionInfo={solutionInfo} solItem={solItem.title} />
          </ImgWrapper>
        ))}
      </SolutionNavWrapper>
      <VideoBox>
        <div>{randerSolDesc(solutionInfo)}</div>
        <video
          width="75%"
          // height="320px"
          preload="auto"
          autoPlay
          playsInline
          muted="muted"
          poster="img.jpg"
          src={videoT}
        />
        <button
          type="button"
          onClick={() => {
            navigate('/solution');
            setSolutionInfoOn(solutionInfo);
          }}
        >
          + <b>{solutionInfo}</b> 상세 더보기
        </button>
      </VideoBox>
    </SolutionInfoArea>
  );
}

const SolutionInfoArea = styled.div`
  /* min-height: 700px; */
`;
const ImgBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 0.7em;
  img {
    width: 50%;
  }
`;
const ImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
`;
const SolutionNavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 3em;
`;
const SolItemTitle = styled.div`
  font-weight: 500;
  height: 30px;
  padding: 0 1em;
  border-radius: 10px;
  display: flex;
  align-items: center;

  background-color: ${({ solutionInfo, solItem }) =>
    solutionInfo === solItem && '#1588dc'};
  color: ${({ solutionInfo, solItem }) =>
    solutionInfo === solItem && '#ffffff'};
  transition: 0.2s;
`;
const Pointer = styled.div`
  position: absolute;

  width: 0px;
  height: 0px;
  border-bottom: calc(10px * 1.732) solid #1588dc !important;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  top: 105%;
  opacity: ${({ solutionInfo, solItem }) =>
    solutionInfo === solItem ? '1' : '0'};
`;

const VideoBox = styled.div`
  margin-top: 1.5em;
  background: linear-gradient(180deg, #a1c3db 0%, #d4dee9 100%);
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 3em 0;
  gap: 1em;

  p {
    background: linear-gradient(180deg, #e6e6e6 0%, #ecf7ff 100%);
    border: 1px solid #d2d2d2;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    padding: 0.8em 1em;
    font-size: 0.96rem;

    b {
      font-weight: 600;
    }
  }

  button {
    background-color: #1588dc;
    color: #ffffff;
    font-weight: 300;

    padding: 0.8em 3.5em;
    border-radius: 10px;
    font-size: 0.8rem;

    cursor: pointer;

    b {
      font-weight: 500;
    }
  }
`;
