import { columnDisplayCenter } from 'assets/styles/styled/baseStyles';
import { NAV_PATH_MAP } from 'constants/NAV_PATH_MAP';
import useNaviCustom from 'hooks/useNaviCustom';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';

export default function Nav({ nav, changeNavState, onFocus }) {
  const [navCall, setNavCall] = useState('none');
  const [pathname, navigate] = useNaviCustom();

  useEffect(() => {
    if (nav) setTimeout(() => setNavCall('flex'), 600);
    else setTimeout(() => setNavCall('none'), 200);
  }, [nav]);

  return (
    <NavArea nav={nav}>
      <NavWrapper navCall={navCall} nav={nav}>
        {NAV_PATH_MAP.map((title) => (
          <NavBox key={title.id}>
            <li>
              <Title
                onClick={() => {
                  changeNavState(title.path);
                  onFocus();
                }}
                pathname={pathname}
                path={title.path}
              >
                {title.title}
              </Title>
            </li>
            <li>
              <NavItemBox>
                {title.nav.map((navItem) => (
                  <li key={navItem.id}>
                    <button
                      onClick={() => {
                        onFocus();
                        changeNavState(title.path);
                      }}
                    >
                      {navItem.title}
                    </button>
                  </li>
                ))}
              </NavItemBox>
            </li>
          </NavBox>
        ))}
      </NavWrapper>
    </NavArea>
  );
}

const NavArea = styled.nav`
  background-color: var(--background-color-base);
  width: 100%;
  height: ${({ nav }) => (nav ? '100%' : '0')};
  top: 3.5em;
  transition: 0.8s;
  position: absolute;
  z-index: 11;
  ${columnDisplayCenter}

  button {
    background-color: var(--background-color-base);
  }
`;

const NavWrapper = styled.div`
  background-color: var(--background-color-base);

  display: ${({ navCall }) => navCall};
  justify-content: center;
  width: 1440px;
  height: 100%;

  padding-top: 130px;
  gap: 40px;

  transition: 0.4s;
  opacity: ${({ nav }) => (nav ? 1 : 0)};
`;

const Title = styled.button`
  color: ${({ pathname, path }) =>
    pathname === path
      ? 'var(--font-color-1588DC)'
      : 'var(--font-color-354660)'};
  font-weight: var(--font-weight-l);
  font-size: 2rem;
  cursor: pointer;
`;

const NavBox = styled.ul`
  ${columnDisplayCenter}
`;

const NavItemBox = styled(NavBox)`
  margin-top: 2.5em;
  gap: 1em;

  li {
    button {
      cursor: pointer;
      color: var(--font-color-354660);
      font-weight: var(--font-weight-base);
    }
  }
`;
