import styled from 'styled-components';

export default function CarouselItem({ cItem }) {
  return (
    <CarouselItemArea>
      <CarouselItemWrapper>{cItem}</CarouselItemWrapper>
    </CarouselItemArea>
  );
}

const CarouselItemArea = styled.div``;
const CarouselItemWrapper = styled.div``;
