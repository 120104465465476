import React from 'react';
import Pagination from 'react-js-pagination';
import styled from 'styled-components';

export default function PaginationLIB({
  pageNo = 1,
  pageSize = 9,
  totalElements = 1,
  totalPages = 1,
  handlePageChange = () => {},
}) {
  return (
    <PaginationWrapper>
      <Pagination
        // 현재 보고있는 페이지
        activePage={pageNo + 1}
        // 한페이지에 출력할 아이템 수
        itemsCountPerPage={pageSize}
        // 총 아이템수
        totalItemsCount={totalElements}
        // 표시할 페이지수
        pageRangeDisplayed={totalPages}
        // 함수
        onChange={handlePageChange}
      />
    </PaginationWrapper>
  );
}

const PaginationWrapper = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
    gap: 6px;
    margin-top: 15px;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid #2c3d4e;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }
  ul.pagination li:first-child {
    /* border-radius: 5px 0 0 5px; */
    border: none;
    margin-top: -4px;

    a {
      font-size: 2em;
    }
  }
  ul.pagination li:nth-of-type(2) {
    /* border-radius: 5px 0 0 5px; */
    border: none;
    margin-top: -3px;

    a {
      font-size: 1.5em;
    }
  }
  ul.pagination li:nth-last-of-type(2) {
    /* border-radius: 5px 0 0 5px; */
    border: none;
    margin-top: -3px;
    a {
      font-size: 1.5em;
    }
  }
  ul.pagination li:last-child {
    /* border-radius: 0 5px 5px 0; */
    border: none;
    margin-top: -4px;

    a {
      font-size: 2em;
    }
  }
  ul.pagination li a {
    text-decoration: none;
    color: #2c3d4e;
    font-size: 1rem;
  }
  ul.pagination li.active a {
    color: #ffffff;
  }
  ul.pagination li.active {
    background-color: #2c3d4e;
  }
  ul.pagination li a:hover,
  ul.pagination li a.active {
    /* color: #ffffff; */
    opacity: 0.8;
  }
`;
