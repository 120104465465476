import BK_BOTTOM from 'assets/browserImg/mainpageImg/snsImg/bk-bottom.png';
import TOP_BK from 'assets/browserImg/mainpageImg/top-bk.png';
import { columnDisplayCenter } from 'assets/styles/styled/baseStyles';
import Layout from 'browser/components/layout';
import ImgBox from 'components/imgBox/imgBox';
import useNavi from 'hooks/useNavi';
import useTopFocus from 'hooks/useTopFocus';
import React from 'react';
import styled from 'styled-components';

import BlockArea from './blockArea';

const Home = () => {
  const navigate = useNavi();

  return (
    <Layout>
      <HomeArea>
        <ImgBox imgPath={TOP_BK}>
          <TitleBox>
            <p>공익 가치 실현하는</p>
            <p>디지털 성범죄 대응 전문 기업</p>
          </TitleBox>
          <TitleBoxEn>
            <p>We will be your</p>
            <p>Security manager</p>
          </TitleBoxEn>
          <BtnArea>
            <MainBtn onClick={() => navigate('/contact')}>+ 상담문의</MainBtn>
            <MainBtnC href="tel:1661-7382">+ 전화문의</MainBtnC>
          </BtnArea>
        </ImgBox>
        <BlockArea />
        <ImgBox
          imgPath={BK_BOTTOM}
          marginBottom={'-5.8px'}
          marginTop={'-2px'}
          bkColor={'#F2F2F2'}
        />
      </HomeArea>
    </Layout>
  );
};

const HomeArea = styled.div``;
const BtnArea = styled.div`
  display: flex;

  position: absolute;
  bottom: 17%;
  left: 42%;
  gap: 14px;
`;

const MainBtn = styled.button`
  /* position: absolute;
  bottom: 17%;
  left: 48%; */
  background-color: rgba(253, 68, 27, 0.7);
  border: 0.5px solid rgba(255, 255, 255, 0.5) !important;
  /* color: var(--font-color-ffffff); */
  color: rgba(255, 255, 255, 0.7);
  /* opacity: 0.8; */
  font-weight: 300;
  padding: 0.6em 1em 0.6em 1em;
  /* border: 0.5px solid rgba(255, 255, 255, 0.5); */
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.5) !important;
  cursor: pointer;

  &:hover {
    color: rgba(255, 255, 255, 0.6);
  }
`;
const MainBtnC = styled.a`
  /* position: absolute;
  bottom: 17%;
  left: 48%; */
  background-color: rgba(253, 68, 27, 0.7);
  border: 0.5px solid rgba(255, 255, 255, 0.5) !important;
  /* color: var(--font-color-ffffff); */
  color: rgba(255, 255, 255, 0.7);
  /* opacity: 0.8; */
  font-weight: 300;
  padding: 0.6em 1em 0.6em 1em;
  /* border: 0.5px solid rgba(255, 255, 255, 0.5); */
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.5) !important;
  cursor: pointer;

  &:hover {
    color: rgba(255, 255, 255, 0.6);
  }
`;

const TitleBox = styled.div`
  opacity: 0.8;
  color: var(--font-color-ffffff);
  font-weight: 300;
  font-size: 1.3125rem;
  position: absolute;
  right: 40.9%;
  top: 29%;
  ${columnDisplayCenter}
`;
const TitleBoxEn = styled.div`
  color: var(--font-color-ffffff);
  font-weight: var(--font-weight-md);
  font-size: 3.625rem;
  position: absolute;
  right: 33%;
  top: 47%;
  ${columnDisplayCenter}
`;
export default Home;
