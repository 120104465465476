import BoxBlock from 'browser/components/boxBlock';
import MainNavBox from 'components/mainNavBox';
import React from 'react';
import styled from 'styled-components';

import PressInfo from './pressInfo';

export default function PressBlock() {
  return (
    <PressBlockArea>
      <MainNavBox title={'보도자료'} link="/press" />
      <BoxBlock>
        <PressInfo />
      </BoxBlock>
    </PressBlockArea>
  );
}

const PressBlockArea = styled.div``;
