import { getIssueList, getYoutubeAPI } from 'api/customAxios';
import PressTxt from 'assets/browserImg/press/pressTxt.png';
import TopBk from 'assets/browserImg/press/topBk.png';
import TopTxt from 'assets/browserImg/press/topTxt.png';
import BLOG_ICON from 'assets/share/blog_icon.png';
import BLOG_MAIN from 'assets/share/blog_main.png';
import YOUTUBE_ICON from 'assets/share/youtube_icon.png';
import Layout from 'browser/components/layout';
import PaginationLIB from 'components/pagination/paginationLIB';
import YouTubeCompo from 'components/youtube/youTubeCompo';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import SnsBox from '../home/snsBlock/snsBox';
import Card from './card/card';
export default function Press({ children }) {
  const [issueData, setIssueData] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(9);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const 임시배열 = new Array(9).fill(0);

  const handlePageChange = (cPage) => {
    setPageNo(cPage - 1);
  };

  const fetchIssueList = () => {
    try {
      (async () => {
        const { data } = await getIssueList(pageNo, pageSize)();
        setIssueData(data);
        setTotalElements(data.totalElements);
        setTotalPages(data.totalPages);
      })();
    } catch (e) {
      alert(`
      ${e}
      내부 오류로인해 게시글을 받아오지 못했습니다.
      `);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchIssueList, [pageNo, pageSize]);

  const videoId = {
    id1: 'auVQAyywHNE',
    id2: 'dSSfoJ-wg-U&t=3s',
    id3: '7xqcMbwsUBg',
    id4: 'WE2qrARu-a8',
  };
  const videoOptions = {
    width: '457.5px',
    height: '255.58px',
    playerVars: {
      autoplay: 0, //자동재생 O
      rel: 0, //관련 동영상 표시하지 않음
      modestbranding: 1, // 컨트롤 바에 youtube 로고를 표시하지 않음
      controls: 0, // 동영상 재생 컨트롤 바 등.. 나오게
      showinfo: 0,
      mute: 1,
      loop: 1,
    },
  };
  // First, second, third, fourth
  const [youtubeArr, setYoutubeArr] = useState([]);
  useEffect(() => {
    (async () => {
      const YOUTUBE_API_KEY = 'AIzaSyBeIIkHWSW-yoSlZzAgd-FeXrHnrQ6Qbfo';
      const youtubeDataFi = getYoutubeAPI()(videoId.id1, YOUTUBE_API_KEY);
      // const youtubeDataS = getYoutubeAPI()(videoId.id2, YOUTUBE_API_KEY);
      // const youtubeDataT = getYoutubeAPI()(videoId.id3, YOUTUBE_API_KEY);
      // const youtubeDataFo = getYoutubeAPI()(videoId.id4, YOUTUBE_API_KEY);

      const data = await Promise.all([
        youtubeDataFi,
        // youtubeDataS,
        // youtubeDataT,
        // youtubeDataFo,
      ]);
      setYoutubeArr(data);
      // console.log(youtubeData.items[0].snippet);
    })();
  }, [videoId.id1]);

  const { pathname } = useLocation();
  const focus = useRef(null);
  useEffect(() => {
    focus.current.focus();
  }, [pathname]);
  return (
    <Layout>
      <PressArea>
        <div ref={focus} tabIndex={0}>
          <img src={TopBk} alt="NO_IMAGE" style={{ maxWidth: '100%' }} />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '1.5em 0',
          }}
        >
          <img src={TopTxt} alt="NO_IMAGE" style={{ width: '65%' }} />
        </div>
        {children}

        <CardArea>
          {issueData?.content?.map((issue) => (
            <Card key={issue.id} issue={issue} />
          ))}
        </CardArea>

        <div>
          <PaginationLIB
            handlePageChange={handlePageChange}
            totalElements={totalElements}
            totalPages={totalPages}
            pageNo={pageNo}
            pageSize={pageSize}
          />
        </div>

        <VAre>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '1.5em 0',
            }}
          >
            <img src={PressTxt} alt="NO_IMAGE" style={{ width: '65%' }} />
          </div>
          <div style={{ display: 'flex', gap: 30 }}>
            {/* 유튜브 박스, 추후 리팩토링 예정 */}
            <SnsBox
              desc="@secureapp8920"
              link="https://www.youtube.com/channel/UCnHKB50MecAwTrXkJsCoxRA"
            >
              {youtubeArr.map((item, index) => (
                <div key={index}>
                  <YouTubeCompo
                    videoId={item.items[0].id}
                    videoOptions={videoOptions}
                  />

                  <YoutubeTitleArea>
                    <DescBox>
                      <div>
                        <img src={YOUTUBE_ICON} alt="NO_IMAGE" />
                      </div>
                      <YouTubeTitleWrapper>
                        <YoutubeTitle>
                          {item.items[0].snippet.title}
                        </YoutubeTitle>
                      </YouTubeTitleWrapper>
                    </DescBox>
                    <button>
                      <a
                        href={`https://www.youtube.com/watch?v=${item.items[0].id}`}
                      >
                        + 바로가기
                      </a>
                    </button>
                  </YoutubeTitleArea>
                </div>
              ))}
            </SnsBox>

            {/* 블로그 박스, 추후 리팩토링 예정 */}
            <SnsBox
              desc="@secureapp8920"
              link="https://blog.naver.com/secureapp112"
            >
              <div style={{ width: '457.5px' }}>
                <Blog>
                  <img src={BLOG_MAIN} alt="NO_IMAGE" />
                </Blog>
                <YoutubeTitleArea>
                  <DescBox>
                    <div>
                      <img src={BLOG_ICON} alt="NO_IMAGE" />
                    </div>
                    <YouTubeTitleWrapper>
                      <YoutubeTitle>
                        몸캠피싱 피해 시 전화번호 변경해도 될까?
                      </YoutubeTitle>
                    </YouTubeTitleWrapper>
                  </DescBox>
                  <button>
                    <a
                      href="https://blog.naver.com/secureapp112/222944251449"
                      target="_blank"
                      rel="noreferrer"
                    >
                      + 바로가기
                    </a>
                  </button>
                </YoutubeTitleArea>
              </div>
            </SnsBox>
          </div>
        </VAre>
      </PressArea>
    </Layout>
  );
}

const PressArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const VAre = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #eaeaea;
  align-items: center;
  padding-bottom: 4em;
  margin-top: 3em;

  gap: 30px;
`;
const YoutubeTitleArea = styled.div`
  width: 457.5px;
  max-width: 457.5px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    font-size: 0.6rem;
    color: #1588dc;
    border: 1px solid #1588dc;
    background-color: #ffffff;
    border-radius: 3px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 450;
    padding: 0.3em 0.8em;
  }
`;

const YouTubeTitleWrapper = styled.div`
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: bold;
  color: #434343;
`;
const YoutubeTitle = styled.b`
  font-size: 0.875rem;
  color: #434343;
`;
const DescBox = styled.div`
  display: flex;
  gap: 5px;
`;

const Blog = styled.div`
  max-width: 100%;
  img {
    max-width: 100%;
  }
`;
const CardArea = styled.div`
  width: 945.45px;

  margin: 1em 0;

  display: flex;
  justify-content: flex-start;
  gap: 1.93em;

  flex-wrap: wrap;
`;
