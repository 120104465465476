import Admin from 'admin/admin';
import AdminSignin from 'admin/adminSignin';
import { navState } from 'atoms/navState';
import SNav from 'components/sNav';
import useGetRecoilToken from 'hooks/useGetRecoilToken';
import useHorizontalScroll from 'hooks/useHorizontalScroll';
import useNavi from 'hooks/useNavi';
import useNaviCustom from 'hooks/useNaviCustom';
import useVisible from 'hooks/\buseVisible';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import Analysis from './pages/analysis/analysis';
import ApkShow from './pages/analysis/detail/apkShow';
import Company from './pages/company/company';
import Contact from './pages/contact/contact';
import Home from './pages/home/home';
import IssueShow from './pages/press/detail/issueShow';
import Press from './pages/press/press';
import Solution from './pages/solution/solution';

const Browser = () => {
  const secureToken = useGetRecoilToken();

  const [nav, setNav] = useRecoilState(navState);
  // const navigate = useNavi();
  const [pathname, navigate] = useNaviCustom();

  // const changeNavState = (path) => {
  //   setNav((prev) => !prev);
  //   navigate(path);
  // };
  // const pathname = window.location.pathname;
  // const [state, setState] = useState([]);
  // const test = async () => {
  //   const a = await getIssueList()();
  //   setState(a);
  //   console.log(a);
  //   // https://bohome.com/path
  // };
  // const test2 = async () => {
  //   const a = await onSignup()({
  //     username: 'test',
  //     password: 'asdf1234@',
  //     nickname: 'test',
  //   });
  //   console.log(a);
  // };

  // const test3 = async () => {
  //   const a = await onSignin()({
  //     username: 'admin',
  //     password: 'admin',
  //   });
  //   console.log(a);
  // };
  // const inputRef = useRef(null);

  // const formData = new FormData();
  // formData.append('title', '되나?');
  // formData.append('desc', '되려나');
  // formData.append('content', '되나?');

  // const onUploadImage = useCallback(
  //   (e) => {
  //     if (!e.target.files) {
  //       return;
  //     }

  //     formData.append('file', e.target.files[0]);

  //     // axios({
  //     //   baseURL: API_HOST,
  //     //   url: '/images/:username/thumbnail',
  //     //   method: 'POST',
  //     //   data: formData,
  //     //   headers: {
  //     //     'Content-Type': 'multipart/form-data',
  //     //   },
  //     // })
  //     //   .then(response => {
  //     //     console.log(response.data);
  //     //   })
  //     //   .catch(error => {
  //     //     console.error(error);
  //     //   });
  //   },
  //   [formData],
  // );
  // console.log(formData.get('file'));

  // const test4 = async () => {
  //   // const a = await postIssue()(formData);
  //   const a = await postAnalysis()(formData);
  //   console.log(a);
  // };

  // const test5 = async () => {
  //   const a = await postReport()({
  //     name: 'xptmxmddsfjsdlkfjsdlkfjsdalk;fj',
  //     victimNumber: 'ㄴㅇㄴㅇ',
  //     firstContactTime: '010101',
  //     damageOccurrence: true,
  //     damageAmount: 2,
  //     birthday: 'sd',
  //     phoneModel: 'sdf',
  //     damageOccurrenceTime: 'sdf',
  //     secureAppContactPath: 'sdf',
  //     searchKeyword: 'sdsdfds',
  //     content: 'sdfsd',
  //   });
  //   console.log(a);
  // };

  const handleOnWheel = useVisible();
  const elRef = useHorizontalScroll();

  return (
    <BrowserArea ref={elRef} onWheel={handleOnWheel}>
      {pathname !== '/admin' && <Wrapper nav={nav} />}
      {/* <button onClick={test4}>테스트</button>
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        onClick={(e) => (e.target.value = null)}
        onChange={onUploadImage}
      />
      {state?.data?.content.map((item) => {
        return (
          <div>
            <img src={`http://localhost:8080${item.filepath}`} />
            {item.filepath}
          </div>
        );
      })} */}
      <MainBtn onClick={() => navigate('/analysis')}>분석 바로가기</MainBtn>

      {pathname !== '/admin' && <SNav />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/solution" element={<Solution />} />
        <Route path="/analysis" element={<Analysis />} />
        <Route path="/analysis/:id" element={<ApkShow />} />
        <Route path="/press" element={<Press />} />
        <Route path="/press/:id" element={<IssueShow />} />
        <Route path="/company" element={<Company />} />

        {/* 어드민 */}
        <Route
          path="/admin"
          element={secureToken ? <Admin /> : <Navigate to="/admin-signin" />}
        />
        <Route
          path="/admin-signin"
          element={!secureToken ? <AdminSignin /> : <Navigate to="/admin" />}
        />

        {/* 404 */}
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserArea>
  );
};

const BrowserArea = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  min-width: 1440px;
  overflow: auto;

  /* overflow: hidden; */
  /* font-family: 'Pretendard Variable', Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI',
    'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif; */

  * {
    border-bottom: 1px solid transparent;
  }
  *:focus {
    border-bottom-color: var(--font-color-1588DC);
  }
`;

const MainBtn = styled.button`
  position: absolute;
  top: -20%;
  background-color: var(--background-color-1588DC);
  color: var(--font-color-ffffff);
  width: 100%;
  padding: 0.4em 0;
  z-index: 11;

  &:focus {
    transition: 0.2s;
    top: 0;
    z-index: 11;
  }
`;

const Wrapper = styled.div`
  background-color: var(--background-color-base);
  z-index: 10;
  height: 100vh;
  width: 100vw;
  position: absolute;
  transition: 0.8s;
  bottom: ${({ nav }) => (nav ? '0' : '94.3vh')};
`;
export default Browser;
