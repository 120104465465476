import React from 'react';
import styled from 'styled-components';

export default function AlertCompo({ setSubmitBool }) {
  return (
    <Area>
      <Wrapper>
        <p>성공적으로 제출되었습니다.</p>
        <button onClick={() => setSubmitBool(false)}>확인</button>
      </Wrapper>
    </Area>
  );
}
const Area = styled.div`
  z-index: 30;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  width: 100vw;
  height: 100vh;
  padding-top: 9em;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Wrapper = styled.div`
  background-color: #ffffff;
  width: 15rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    padding: 2em 0;
  }
  button {
    background-color: #111b2a;
    width: 100%;
    color: #ffffff;
    cursor: pointer;
    padding: 0.5em 0;
  }
`;
