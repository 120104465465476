import styled from 'styled-components';

export default function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <Arrow
      className={className}
      style={{ ...style, display: 'block', background: '' }}
      onClick={onClick}
    />
  );
}

const Arrow = styled.div``;
