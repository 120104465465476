import BoxBlock from 'browser/components/boxBlock';
import MainNavBox from 'components/mainNavBox';
import React from 'react';

import AnalysisInfo from './analysisInfo';

export default function AnalysisBlock() {
  return (
    <div>
      <MainNavBox title={'ANALYSIS'} link="/analysis" />
      <BoxBlock>
        <AnalysisInfo />
      </BoxBlock>
    </div>
  );
}
