import Arrow from 'assets/browserImg/sol/tap/arrow.png';
import M from 'assets/browserImg/sol/tap/m.png';
import T from 'assets/browserImg/sol/tap/t1.png';
import IImg1 from 'assets/browserImg/sol/tap/tap1/bi1.png';
import IImg2 from 'assets/browserImg/sol/tap/tap1/bi2.png';
import IImg3 from 'assets/browserImg/sol/tap/tap1/bi3.png';
import OImg1 from 'assets/browserImg/sol/tap/tap1/bo1.png';
import OImg2 from 'assets/browserImg/sol/tap/tap1/bo2.png';
import OImg3 from 'assets/browserImg/sol/tap/tap1/bo3.png';
import TB from 'assets/browserImg/sol/tap/tb1.png';
import V from 'assets/video/magnifier.mp4';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import TapImgBox from './tapImgBox';
import TapImgBoxLayout from './tapImgBoxLayout';
import VideoLayout from './videoLaout';

export default function Magnifier() {
  return (
    <div>
      <VideoLayout T={T} TB={TB} Arrow={Arrow} V={V} />
      <TapImgBoxLayout>
        <TapImgBox iImg={IImg1} oImg={OImg1} bkImg={true} />
        <TapImgBox iImg={IImg2} oImg={OImg2} />
        <TapImgBox iImg={IImg3} oImg={OImg3} />
      </TapImgBoxLayout>
      <ImgBox>
        <img src={M} alt="NO_IMAGE" />
        <Link to="/analysis" />
      </ImgBox>
    </div>
  );
}

const ImgBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3.3em;
  margin-bottom: 4em;
  position: relative;

  img {
    width: 58.3em;
  }

  a {
    position: absolute;
    width: 312px;
    height: 41px;
    bottom: 0;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
  }
`;
