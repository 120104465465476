import Admin from 'admin/admin';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router';

import Layout from './components/Layout';
import Analysis from './pages/analysis/analysis';
import ApkShow from './pages/analysis/apkShow';
import Show from './pages/analysis/apkShow';
import Company from './pages/company/company';
import Contact from './pages/contact/contact';
import Home from './pages/home/home';
import IssueShow from './pages/press/issueShow';
import Press from './pages/press/press';
import Solution from './pages/solution/solution';

const Mobile = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/solution" element={<Solution />} />
        <Route path="/analysis" element={<Analysis />} />
        <Route path="/analysis/:id" element={<ApkShow />} />
        <Route path="/press" element={<Press />} />
        <Route path="/press/:id" element={<IssueShow />} />
        <Route path="/company" element={<Company />} />

        {/* 어드민 */}
        <Route path="/admin" element={<Admin />} />

        {/* 404 */}
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </Layout>
  );
};

export default Mobile;
