import s1 from 'assets/mobileImg/solution/tap/forensics/s1.png';
import s2 from 'assets/mobileImg/solution/tap/forensics/s2.png';
import s3 from 'assets/mobileImg/solution/tap/forensics/s3.png';
import s4 from 'assets/mobileImg/solution/tap/forensics/s4.png';
import s5 from 'assets/mobileImg/solution/tap/forensics/s5.png';
import V from 'assets/video/forensics.mp4';
import React from 'react';
import styled from 'styled-components';
export default function Forensics() {
  return (
    <div>
      <SImgBox style={{ paddingTop: '2em' }}>
        <img src={s1} alt="NO_IMAGE" />
      </SImgBox>
      <VWrapper>
        <video
          width="100%"
          //   height="350px"
          preload="auto"
          autoPlay
          playsInline
          muted="muted"
          poster="img.jpg"
          src={V}
        />
      </VWrapper>
      <SImgBox>
        <img src={s2} alt="NO_IMAGE" />
      </SImgBox>
      <SImgBox>
        <img src={s3} alt="NO_IMAGE" />
      </SImgBox>
      <SImgBox>
        <img src={s4} alt="NO_IMAGE" />
      </SImgBox>
      <SImgBox style={{ padding: 0 }}>
        <img src={s5} alt="NO_IMAGE" />
      </SImgBox>
    </div>
  );
}
const SImgBox = styled.div`
  width: 100%;

  padding: 0.5em 1.4em;
  img {
    min-width: 100%;
    max-width: 100%;
  }
`;
const VWrapper = styled.div`
  padding: 0 1.4em;
  margin-bottom: 1em;
`;
