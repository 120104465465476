import c1 from 'assets/mobileImg/solution/c1.png';
import c2 from 'assets/mobileImg/solution/c2.png';
import c3 from 'assets/mobileImg/solution/c3.png';
import c4 from 'assets/mobileImg/solution/c4.png';
import c5 from 'assets/mobileImg/solution/c5.png';
import c6 from 'assets/mobileImg/solution/c6.png';
import emergency1 from 'assets/mobileImg/solution/emergency1.png';
import emergency2 from 'assets/mobileImg/solution/emergency2.png';
import forensics1 from 'assets/mobileImg/solution/forensics1.png';
import forensics2 from 'assets/mobileImg/solution/forensics2.png';
import GG from 'assets/mobileImg/solution/gg.png';
import magnifier1 from 'assets/mobileImg/solution/magnifier1.png';
import magnifier2 from 'assets/mobileImg/solution/magnifier2.png';
import packet1 from 'assets/mobileImg/solution/packet1.png';
import packet2 from 'assets/mobileImg/solution/packet2.png';
import Sol1 from 'assets/mobileImg/solution/sol1.png';
import Sol2 from 'assets/mobileImg/solution/sol2.png';
import Sol3 from 'assets/mobileImg/solution/sol3.png';
import Sol4 from 'assets/mobileImg/solution/sol4.png';
import Sol5 from 'assets/mobileImg/solution/sol5.png';
import top from 'assets/mobileImg/solution/top.png';
import topv from 'assets/mobileImg/solution/topv.png';
import tracking1 from 'assets/mobileImg/solution/tracking1.png';
import tracking2 from 'assets/mobileImg/solution/tracking2.png';
import TS from 'assets/mobileImg/solution/ts.png';
import { solutionInfoOnState } from 'atoms/solutionInfoOnState';
import { solutionInfoState } from 'atoms/solutionInfoState';
import NextArrow from 'components/carousel/nextArrow';
import PrevArrow from 'components/carousel/prevArrow';
import useNavi from 'hooks/useNavi';
import React, { Fragment, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import Carousel from './carousel';
import CarouselItem from './carouselItem';
import Emergency from './tap/emergency';
import Forensics from './tap/forensics';
import Magnifier from './tap/magnifier';
import Packet from './tap/packet';
import Tracking from './tap/tracking';
const settings = {
  dots: true, // 개수 표시 점
  infinite: true, // 무한 캐러셀
  speed: 500, // 다음 컨텐츠 까지의 속도
  slidesToShow: 1, // 화면에 보이는 컨텐츠 수
  slidesToScroll: 1, // 스크롤 시 넘어가는 컨텐츠 수
  centerMode: true, // 현재 컨텐츠 가운데 정렬
  centerPadding: '10px', // 중앙 컨텐츠 padding 값
  autoplay: true, // 자동 캐러셀
  autoplaySpeed: 2000, // 자동 캐러셀 속도
  draggable: true, // 드래그
  fade: false, // 사라졌다 나타나는 효과
  arrows: true, // 좌,우 버튼
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  vertical: false, // 세로 캐러셀
  initialSlide: 1, // 첫 컨텐츠 번호
  pauseOnFocus: true, // focus시 정지
  pauseOnHover: true, // hover시 정지
  responsive: [
    // 반응형 옵션
    {
      breakpoint: 480, // (숫자)px 이하일 경우
      settings: {
        slidesToShow: 1,
        arrows: true,
      },
    },
  ],
};

export const carouselMap = [
  {
    id: 1,
    cItem: <CarouselItem imgPath={Sol1} link={''} title="악성코드 분석" />,
  },
  {
    id: 2,
    cItem: <CarouselItem imgPath={Sol2} link={''} title="패킷 변조 솔루션" />,
  },
  {
    id: 3,
    cItem: <CarouselItem imgPath={Sol3} link={''} title="긴급 대처 솔루션" />,
  },
  {
    id: 4,
    cItem: <CarouselItem imgPath={Sol4} link={''} title="영상 추적 솔루션" />,
  },
  {
    id: 5,
    cItem: <CarouselItem imgPath={Sol5} link={''} title="모바일 포렌식" />,
  },
];

export default function Solution() {
  const [solutionInfoOn, setSolutionInfoOn] =
    useRecoilState(solutionInfoOnState);
  const [solutionInfo, setSolutionInfo] = useRecoilState(solutionInfoState);
  const changeSolInfo = (sol) => {
    setSolutionInfoOn(true);
    setSolutionInfo(sol);
  };
  const navigate = useNavi();
  const carouselMap2 = [
    {
      id: 1,
      cItem: (
        <CreatedCarouselItem>
          <img src={c1} alt="NO_IMAGE" />
        </CreatedCarouselItem>
      ),
    },
    {
      id: 2,
      cItem: (
        <CreatedCarouselItem>
          <img src={c2} alt="NO_IMAGE" />
        </CreatedCarouselItem>
      ),
    },
    {
      id: 3,
      cItem: (
        <CreatedCarouselItem>
          <img src={c3} alt="NO_IMAGE" />
        </CreatedCarouselItem>
      ),
    },
    {
      id: 4,
      cItem: (
        <CreatedCarouselItem>
          <img src={c4} alt="NO_IMAGE" />
        </CreatedCarouselItem>
      ),
    },
    {
      id: 5,
      cItem: (
        <CreatedCarouselItem>
          <img src={c5} alt="NO_IMAGE" />
        </CreatedCarouselItem>
      ),
    },
    {
      id: 6,
      cItem: (
        <CreatedCarouselItem>
          <img src={c6} alt="NO_IMAGE" />
        </CreatedCarouselItem>
      ),
    },
  ];

  const [carouselPosition, setCarouselPosition] = useState(300);
  const onChangeCarousel = (position, shift) => {
    if (shift === 'left')
      return setCarouselPosition((prev) => (prev < 500 ? prev + 200 : 500));
    if (shift === 'right')
      return setCarouselPosition((prev) => (prev > -500 ? prev - 200 : -500));
    setCarouselPosition(position);
  };
  return (
    <SolutionArea>
      <NavArea>
        <NavImgWrapper
          type="악성코드 분석"
          onClick={() => changeSolInfo('악성코드 분석')}
          solutionInfo={solutionInfo}
          solutionInfoOn={solutionInfoOn}
        >
          <img src={magnifier1} alt="NO_IMAGE" />
          <img src={magnifier2} alt="NO_IMAGE" />
        </NavImgWrapper>
        <NavImgWrapper
          type="패킷 변조 솔루션"
          onClick={() => changeSolInfo('패킷 변조 솔루션')}
          solutionInfo={solutionInfo}
          solutionInfoOn={solutionInfoOn}
        >
          <img src={packet1} alt="NO_IMAGE" />
          <img src={packet2} alt="NO_IMAGE" />
        </NavImgWrapper>
        <NavImgWrapper
          type="긴급 대처 솔루션"
          onClick={() => changeSolInfo('긴급 대처 솔루션')}
          solutionInfo={solutionInfo}
          solutionInfoOn={solutionInfoOn}
        >
          <img src={emergency1} alt="NO_IMAGE" />
          <img src={emergency2} alt="NO_IMAGE" />
        </NavImgWrapper>
        <NavImgWrapper
          type="영상 추적 솔루션"
          onClick={() => changeSolInfo('영상 추적 솔루션')}
          solutionInfo={solutionInfo}
          solutionInfoOn={solutionInfoOn}
        >
          <img src={tracking1} alt="NO_IMAGE" />
          <img src={tracking2} alt="NO_IMAGE" />
        </NavImgWrapper>
        <NavImgWrapper
          type="모바일 포렌식"
          onClick={() => changeSolInfo('모바일 포렌식')}
          solutionInfo={solutionInfo}
          solutionInfoOn={solutionInfoOn}
        >
          <img src={forensics1} alt="NO_IMAGE" />
          <img src={forensics2} alt="NO_IMAGE" />
        </NavImgWrapper>
      </NavArea>
      {!solutionInfoOn ? (
        <>
          <SolImgBox style={{ marginTop: '2em' }}>
            <img src={topv} alt="NO_IMAGE" />
          </SolImgBox>
          <SolImgBox style={{ padding: '0 1.2em' }}>
            <img src={top} alt="NO_IMAGE" style={{ width: '62%' }} />
          </SolImgBox>

          <CarouselWrapper>
            <Carousel carouselMap={carouselMap} settings={settings} />
          </CarouselWrapper>
        </>
      ) : (
        <div>
          {/* magnifier 악성코드 분석 */}
          {solutionInfo === '악성코드 분석' && <Magnifier />}

          {/* packet 패킷 변조 솔루션 */}
          {solutionInfo === '패킷 변조 솔루션' && <Packet />}

          {/* emergency 긴급 대처 솔루션 */}
          {solutionInfo === '긴급 대처 솔루션' && <Emergency />}

          {/* tracking 영상 추적 솔루션 */}
          {solutionInfo === '영상 추적 솔루션' && <Tracking />}

          {/* forensics 모바일 포렌식 */}
          {solutionInfo === '모바일 포렌식' && <Forensics />}
        </div>
      )}
      <BWrapper solutionInfoOn={solutionInfoOn}>
        <SolImgBox style={{ padding: '0 1.2em', marginBottom: '12em' }}>
          <img src={TS} alt="NO_IMAGE" />
        </SolImgBox>
        <CreatedCarouselArea>
          <h1>몸캠피싱 피해과정</h1>
          <CreatedCarouselWrapper carouselPosition={carouselPosition}>
            {carouselMap2.map((item) => (
              <Fragment key={item.id}>{item.cItem}</Fragment>
            ))}
          </CreatedCarouselWrapper>
          <CreatedCarouselBtnBox>
            <div onClick={() => onChangeCarousel(null, 'left')}>{`<`}</div>
            {carouselPosition === 500 && (
              <div onClick={() => onChangeCarousel(500)}>01</div>
            )}
            {carouselPosition === 300 && (
              <div onClick={() => onChangeCarousel(300)}>02</div>
            )}
            {carouselPosition === 100 && (
              <div onClick={() => onChangeCarousel(100)}>03</div>
            )}
            {carouselPosition === -100 && (
              <div onClick={() => onChangeCarousel(-100)}>04</div>
            )}
            {carouselPosition === -300 && (
              <div onClick={() => onChangeCarousel(-300)}>05</div>
            )}
            {carouselPosition === -500 && (
              <div onClick={() => onChangeCarousel(-500)}>06</div>
            )}
            <div>|</div>
            <div>06</div>
            <div onClick={() => onChangeCarousel(null, 'right')}>{`>`}</div>
          </CreatedCarouselBtnBox>
        </CreatedCarouselArea>
      </BWrapper>
      <SolImgBox style={{ marginBottom: '-70%', backgroundColor: '#F6F6F6' }}>
        <img src={GG} alt="NO_IMAGE" />
        <div onClick={() => navigate('/contact')} />
      </SolImgBox>
    </SolutionArea>
  );
}

const SolutionArea = styled.div``;

const NavImgWrapper = styled.div`
  img:nth-of-type(1) {
    min-width: 100%;
    max-width: 100%;
    display: ${({ solutionInfo, type, solutionInfoOn }) =>
      solutionInfoOn && solutionInfo === type ? 'none' : 'inline-block'};
  }
  img:nth-of-type(2) {
    min-width: 100%;
    max-width: 100%;
    display: ${({ solutionInfo, type, solutionInfoOn }) =>
      solutionInfoOn && solutionInfo === type ? 'inline-block' : 'none'};
  }
`;

const SolImgBox = styled.div`
  width: 100%;
  position: relative;

  img {
    min-width: 100%;
    max-width: 100%;
  }

  div {
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    width: 80%;
    height: 12%;
    top: 30%;
    left: 10.5%;
    z-index: 1;
  }
`;

const NavArea = styled.div`
  display: flex;
  align-items: center;
  background-color: #efefef;
  padding: 1em 0.2em;
`;

const CarouselWrapper = styled.div`
  background: #e6f4ff;
  /* border-radius: 50px; */
  padding: 2em 1em 2em 1em;

  margin: 2em 0 3.5em 0;
`;
const CreatedCarouselArea = styled.div`
  width: 100vw;
  min-width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: hidden;

  margin-bottom: 1.5em;
  margin-top: -38%;

  background: linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);

  h1 {
    color: #35455f;
    font-size: 1.55rem;
    font-weight: 600;

    margin: 1.5em 0;
  }
`;
const CreatedCarouselWrapper = styled.div`
  width: 600%;

  display: flex;
  justify-content: flex-start;
  margin-left: ${({ carouselPosition }) => `${carouselPosition}%`};
  transition: margin-left 0.7s ease;
`;
const CreatedCarouselItem = styled.div`
  width: 100%;
  padding: 0 1em;

  img {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`;
const CreatedCarouselBtnBox = styled.div`
  display: flex;
  gap: 2em;
  font-size: 1.3rem;
  font-weight: bold;
  align-items: center;
  margin-top: 1em;

  div:nth-of-type(1) {
    color: #b3b3b3;
    cursor: pointer;
  }
  div:nth-of-type(2) {
    cursor: pointer;
  }
  div:nth-of-type(3) {
    font-size: 1rem;
  }
  div:nth-of-type(4) {
    color: #b3b3b3;
  }
  div:nth-of-type(5) {
    color: #b3b3b3;
    cursor: pointer;
  }
  /* div:nth-of-type(6) {
    color: #000000;
  }
  div:nth-of-type(7) {
    color: #b3b3b3;
  }
  div:nth-of-type(8) {
    color: #b3b3b3;
  } */
`;

const BWrapper = styled.div``;
