import SlideImg1 from 'assets/browserImg/company/slideImg1.png';
import SlideImg2 from 'assets/browserImg/company/slideImg2.png';
import SlideImg3 from 'assets/browserImg/company/slideImg3.png';
import SlideImg4 from 'assets/browserImg/company/slideImg4.png';
import MAINTOP from 'assets/mobileImg/company/maintop.png';
import VIEW from 'assets/mobileImg/company/overview.png';
import SecureHistory from 'assets/mobileImg/company/secureHistory.png';
import Carousel from 'components/carousel/carousel';
import PrevArrow from 'components/carousel/prevArrow';
import Layout from 'mobile/components/Layout';
import React from 'react';
import styled from 'styled-components';

import NextArrow from './carousel/nextArrow';

export default function Company() {
  const settings = {
    dots: false, // 개수 표시 점
    infinite: true, // 무한 캐러셀
    speed: 3000, // 다음 컨텐츠 까지의 속도
    slidesToShow: 1, // 화면에 보이는 컨텐츠 수
    slidesToScroll: 1, // 스크롤 시 넘어가는 컨텐츠 수
    centerMode: true, // 현재 컨텐츠 가운데 정렬
    centerPadding: '0em', // 중앙 컨텐츠 padding 값
    autoplay: true, // 자동 캐러셀
    autoplaySpeed: 2000, // 자동 캐러셀 속도
    draggable: true, // 드래그
    fade: false, // 사라졌다 나타나는 효과
    arrows: true, // 좌,우 버튼
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    vertical: false, // 세로 캐러셀
    initialSlide: 1, // 첫 컨텐츠 번호
    pauseOnFocus: true, // focus시 정지
    pauseOnHover: true, // hover시 정지
    responsive: [
      // 반응형 옵션
      {
        breakpoint: 480, // (숫자)px 이하일 경우
        settings: {
          slidesToShow: 1,
          arrows: true,
        },
      },
    ],
  };

  const testMap = [
    {
      id: 1,
      cItem: (
        <div>
          <img
            src={SlideImg1}
            alt="NO_IMAGE"
            style={{ minWidth: '100%', height: 'auto', maxWidth: '100%' }}
          />
        </div>
      ),
    },
    {
      id: 2,
      cItem: (
        <div>
          <img
            src={SlideImg2}
            alt="NO_IMAGE"
            style={{ minWidth: '100%', height: 'auto', maxWidth: '100%' }}
          />
        </div>
      ),
    },
    {
      id: 3,
      cItem: (
        <div>
          <img
            src={SlideImg3}
            alt="NO_IMAGE"
            style={{ minWidth: '100%', height: 'auto', maxWidth: '100%' }}
          />
        </div>
      ),
    },
    {
      id: 4,
      cItem: (
        <div>
          <img
            src={SlideImg4}
            alt="NO_IMAGE"
            style={{ minWidth: '100%', height: 'auto', maxWidth: '100%' }}
          />
        </div>
      ),
    },
    // {
    //   id: 5,
    //   cItem: (
    //     <div>
    //       <img src={SlideImg4} alt="NO_IMAGE" style={{ minWidth: '100%', height: 'auto', maxWidth: '100%' }} />

    //     </div>
    //   ),
    // },
  ];
  return (
    <CompanyArea>
      <TopImgBox>
        <img src={MAINTOP} alt="NO_IMAGE" />
      </TopImgBox>
      <TopImgBox>
        <img src={VIEW} alt="NO_IMAGE" />
      </TopImgBox>
      <CarouselWrapper>
        <p
          style={{
            color: '#275070',
            fontWeight: '500',
            fontSize: '1.125rem',
            marginBottom: '1em',
          }}
        >
          <strong style={{ fontWeight: '700' }}>시큐어앱</strong>을 선택해야
          하는 이유
        </p>
        <Carousel carouselMap={testMap} settings={settings} mWidth={'85%'} />
      </CarouselWrapper>
      <HistoryImgBox>
        <img src={SecureHistory} alt="NO_IMAGE" />
      </HistoryImgBox>
    </CompanyArea>
  );
}

const CompanyArea = styled.div``;
const HistoryImgBox = styled.div`
  width: 100%;
  img {
    min-width: 100%;
    max-width: 100%;
  }
  margin-bottom: -10em;
`;
const TopImgBox = styled.div`
  width: 100%;

  img {
    min-width: 100%;
    max-width: 100%;
  }
`;
const CarouselWrapper = styled.div`
  /* min-width: 814.54px; */
  background-color: #d9d9d9;
  padding-top: 3.5em;
  padding-bottom: 2.5em;

  p {
    display: flex;
    justify-content: center;
  }
`;
