import styled from 'styled-components';

export default function VideoLayout({ T, TB, Arrow, V }) {
  return (
    <VideoLayoutArea>
      <VideoLayoutWrapper>
        <div>
          <img src={T} alt="NO_IMAGE" />
        </div>
        <div>
          <img src={TB} alt="NO_IMAGE" />
        </div>
        <div>
          <img src={Arrow} alt="NO_IMAGE" />
        </div>
        <video
          width="100%"
          height="350px"
          preload="auto"
          autoPlay
          playsInline
          muted="muted"
          poster="img.jpg"
          src={V}
        />
      </VideoLayoutWrapper>
    </VideoLayoutArea>
  );
}

const VideoLayoutArea = styled.div`
  width: 100%;
`;
const VideoLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div:nth-of-type(1) {
    display: flex;
    justify-content: center;
    img {
      width: 86%;
    }
  }
  div:nth-of-type(2) {
    display: flex;
    justify-content: center;

    img {
      width: 87%;
    }
  }
  div:nth-of-type(3) {
    margin-bottom: 0.7em;
    img {
      width: 85%;
    }
  }

  video {
  }
`;
