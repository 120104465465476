import './slick-css.css';

import React from 'react';
import styled from 'styled-components';

import SlickCarousel from './slickCarousel';

const Carousel = ({ carouselMap, settings, mWidth = '700px' }) => {
  return (
    <CarouselArea mWidth={mWidth}>
      <SlickCarousel
        carouselMap={carouselMap}
        settings={settings}
        dotsClass="slick-css"
      />
    </CarouselArea>
  );
};
export default Carousel;

const CarouselArea = styled.div`
  /* margin-left: auto;
  margin-right: auto; */
  max-width: 100%;

  /* max-width: ${({ mWidth }) => mWidth}; */
  padding: 1em 0;
  padding-bottom: 2em;
`;
