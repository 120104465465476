import { postEditorImages, postIssue, putIssue } from 'api/customAxios';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import ToastEditor from './toastEditor';

export default function IssueWriter({
  putStatus,
  setPutStatus,
  detailData,
  setDetailData,
  setAdminNavNum,
}) {
  const [issueData, setIssueData] = useState({
    title: '',
    desc: '',
    content: '',
    filename: '',
    filepath: '',
    file: null,
  });
  const { title, desc, content, file, filepath, filename } = issueData;
  const [subStatus, setSubStatus] = useState(false);

  const imageRef = useRef(null);
  // const formData = new FormData(e.currentTarget);
  const issueFormData = new FormData();
  const onChageIssueData = (e) => {
    const { value, name } = e.target;
    setIssueData((issue) => ({ ...issue, [name]: value }));
  };
  issueFormData.append('title', title);
  issueFormData.append('desc', desc);
  issueFormData.append('content', content);

  // 파일업로드 이미지 미리보기
  const [imageSrc, setImageSrc] = useState('');
  const setSrc = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e);
    return new Promise((resolve) => {
      reader.onload = () => {
        setImageSrc(reader.result);
        resolve();
      };
    });
  };

  const onUploadImage = (e) => {
    if (!e.target.files) return;
    setIssueData((issue) => ({ ...issue, file: e.target.files[0] }));
    setSrc(e.target.files[0]);
  };
  issueFormData.append('file', file);

  // 에디터 이미지파일 변경
  const editorRef = useRef(null);
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.getInstance().removeHook('addImageBlobHook');
      editorRef.current
        .getInstance()
        .addHook('addImageBlobHook', (blob, callback) => {
          const formData = new FormData();
          formData.append('file', blob);

          (async () => {
            try {
              const { data } = await postEditorImages()(formData);
              callback(
                'https://secureappimgbucket.s3.ap-northeast-2.amazonaws.com/' +
                  data?.filepath,
                'NO_IMAGE',
              );
            } catch (e) {
              alert('서버에 문제가 발생했습니다.');
              // console.error(e);
            }
          })();

          return false;
        });
    }
    return () => {};
  }, [editorRef]);

  const fetchIssueData = async (e) => {
    e.preventDefault();
    setSubStatus(true);

    if (putStatus) {
      putIssueFetch(detailData?.id);
      setSubStatus(false);
      return;
    }
    try {
      // const res = await postIssue()(issueFormData);
      await postIssue()(issueFormData);
      setSubStatus(false);
      alert('작성되었습니다.');
      setAdminNavNum(5);
    } catch (e) {
      alert(` 작성되지 않았습니다.
    로그인만료는 로그아웃 후 다시 로그인하시면 됩니다.`);
    }
  };

  const putIssueFetch = async (id) => {
    issueFormData.append('filename', filename);
    issueFormData.append('filepath', filepath);
    try {
      await putIssue(id)(issueFormData);
      setPutStatus(false);
      setDetailData([]);
      setAdminNavNum(5);
    } catch (e) {
      alert('수정 실패');
    }
  };

  useState(() => {
    if (putStatus) {
      setImageSrc(
        `https://secureappimgbucket.s3.ap-northeast-2.amazonaws.com/${detailData?.filepath}`,
      );
      setIssueData({
        title: detailData?.title,
        desc: detailData?.desc,
        content: detailData?.content,
        filename: detailData?.filename,
        filepath: detailData?.filepath,
        // file: null,
      });

      // const fileInput = document.getElementById('file');
      // fileInput.onChange = () => {
      //   const selectedFile = fileInput.files[0];
      //   console.log(selectedFile);
      // };
    }
  }, [detailData, putStatus]);
  return (
    <form onSubmit={fetchIssueData}>
      <h1 style={{ marginBottom: '2em', fontSize: '1.5rem', fontWeight: 500 }}>
        issue(press) 게시글 작성
      </h1>
      <div style={{ marginBottom: '0.3em' }}>
        <label htmlFor="title" style={{ fontWeight: 500 }}>
          제목: &nbsp;
        </label>
        <input
          type="text"
          name="title"
          id="title"
          value={title}
          onChange={onChageIssueData}
          placeholder="글에대한 제목을 적어주세요."
          style={{
            border: '1px solid black',
            width: '20rem',
            padding: '0.2em 0.6em',
          }}
        />
      </div>
      <div style={{ marginBottom: '0.5em' }}>
        <label htmlFor="title" style={{ fontWeight: 500 }}>
          설명: &nbsp;
        </label>
        <input
          type="desc"
          name="desc"
          id="desc"
          value={desc}
          onChange={onChageIssueData}
          placeholder="글에대한 설명을 적어주세요."
          style={{
            border: '1px solid black',
            width: '20rem',
            padding: '0.2em 0.6em',
          }}
        />
      </div>

      <input
        // required
        type="file"
        accept="image/*"
        ref={imageRef}
        onClick={(e) => (e.target.value = null)}
        onChange={onUploadImage}
      />
      <PreviewImgBox style={{}}>
        {imageSrc ? (
          <img src={imageSrc} alt="NO_IMAGE" />
        ) : (
          <div>banner_image를 업로드하세요.</div>
        )}
      </PreviewImgBox>
      <ToastEditor
        editorRef={editorRef}
        content={content}
        setData={setIssueData}
      />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          type="submit"
          style={{
            margin: '1em 0',
            border: '1px solid #1588dc',
            borderRadius: '10px',
            padding: '0.7em 1.2em 0.7em 1.2em',
            backgroundColor: '#1588dc',
            color: '#ffffff',
            cursor: 'pointer',
          }}
          disabled={subStatus}
        >
          제출
        </button>
      </div>
    </form>
  );
}

const PreviewImgBox = styled.div`
  img {
    width: 10%;
  }

  div {
    margin: 2em 0;
  }
`;
