import MainNavBox from 'mobile/components/mainNavBox';
import React from 'react';
import styled from 'styled-components';

import AnalysisInfo from './analysisInfo';

export default function AnalysisBlock() {
  return (
    <div>
      <MainNavBox title="ANALYSIS" link="/analysis" />
      <AnalysisInfo />
    </div>
  );
}
