import { navState } from 'atoms/navState';
import SNav from 'components/sNav';
import useDetectScrollDirection from 'hooks/useDetectScrollDirection';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import Footer from './footer';
import Header from './header';
import Nav from './nav';

export default function Layout({ children }) {
  const navStatus = useRecoilValue(navState);

  const [scrolled, useMemoedThrottle] = useDetectScrollDirection();

  useEffect(() => {
    window.addEventListener('scroll', useMemoedThrottle);
    return () => {
      window.removeEventListener('scroll', useMemoedThrottle);
    };
  }, []);

  return (
    <LayoutArea navStatus={navStatus}>
      <Nav />
      <SNav scrolled={scrolled} />
      <Header />
      <BodyArea>{children}</BodyArea>
      <Footer />
    </LayoutArea>
  );
}

const LayoutArea = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  width: 100%;
  overflow: ${({ navStatus }) => navStatus && 'hidden'};
  max-width: 100%;
  box-sizing: border-box;
`;

const BodyArea = styled.div`
  flex: 1;
  margin-top: 4.3em;
`;
