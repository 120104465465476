import styled from 'styled-components';

export default function TapImgBox({ iImg, oImg, bkImg = false, trac = false }) {
  return (
    <div>
      <TapImgBoxWrapper bkImg={bkImg} trac={trac}>
        <div>
          <img src={iImg} alt="NO_IMAGE" />
        </div>
        <div>
          <img src={oImg} alt="NO_IMAGE" />
        </div>
      </TapImgBoxWrapper>
    </div>
  );
}

const TapImgBoxWrapper = styled.div`
  position: relative;
  width: 18.18em;
  height: 19.31em;
  div {
    display: flex;
    justify-content: center;
  }

  div:nth-of-type(1) {
    position: absolute;
    img {
      width: 18.18em;
    }
  }
  div:nth-of-type(2) {
    position: absolute;
    &:hover {
      opacity: 0;
      transition: 0.6s;
    }

    img {
      width: ${({ bkImg }) => (bkImg ? '19.1em' : '18.18em')};
      margin-right: ${({ bkImg }) => bkImg && '-1em'};
      margin-left: ${({ trac }) => trac && '-0.9em'};
    }
  }
`;
