import useNaviCustom from 'hooks/useNaviCustom';
import React from 'react';
import styled from 'styled-components';

export default function Card({ bkColor = '#ececec', issue }) {
  const [pathname, navigate] = useNaviCustom();

  return (
    <CardArea bkColor={bkColor}>
      <ImgBox>
        <img
          src={`https://secureappimgbucket.s3.ap-northeast-2.amazonaws.com/${issue?.filepath}`}
          alt="NO_IMAGE"
        />
      </ImgBox>
      <InfoWrapper>
        <Title>{issue?.title}</Title>
        <Desc>{issue?.desc}</Desc>
        <BtnWrapper>
          <span> {issue?.createdDate?.slice(0, 10)}</span>
          <DetailBtn
            type="button"
            onClick={() => {
              navigate(`/press/${issue.id}`);
            }}
          >
            + 내용보기
          </DetailBtn>
        </BtnWrapper>
      </InfoWrapper>
    </CardArea>
  );
}

const CardArea = styled.div`
  height: 363.63px;
  width: 294.54px;
  background-color: ${({ bkColor }) => bkColor};
  margin: 0.7em 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const ImgBox = styled.div`
  width: 250.18px;
  height: 226.36px;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    max-width: 100%;
  }
`;
const Title = styled.p`
  width: 15.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  line-height: 1.2em;

  display: -webkit-box;
  -webkit-line-clamp: 2; // 원하는 라인수
  -webkit-box-orient: vertical;

  font-size: 1rem;
  font-weight: 600;
  color: #434343;
`;

const Desc = styled(Title)`
  max-width: 100%;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  line-height: 1.3em;

  display: -webkit-box;
  -webkit-line-clamp: 5; // 원하는 라인수
  -webkit-box-orient: vertical;

  color: #7a7a7a;
  font-size: 0.8rem;
  font-weight: 300;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding-top: 0.6em;
`;

const DetailBtn = styled.button`
  background: #ffffff;
  border: 1px solid #72bbf0 !important;
  border-radius: 5px;
  font-size: 0.7rem;
  padding: 0.3em 0.3em;
  font-weight: 500;
  color: #1588dc;
  cursor: pointer;
`;

const BtnWrapper = styled.div`
  width: 15.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 0.8rem;
    color: #7a7a7a;
  }
`;
