import throttle from 'lodash.throttle';
import { useMemo, useState } from 'react';

export default function useDetectScrollDirection() {
  const [scrolled, setScrolled] = useState(0);
  let savedNum = 0;

  const throttledDetectScrollDirection = throttle(() => {
    let nowScrollTop = document.documentElement.scrollTop;
    // console.log(nowScrollTop, savedNum, 'now & save & 밖');
    if (nowScrollTop > 67 && nowScrollTop - savedNum >= 0 && scrolled !== 1) {
      // console.log(nowScrollTop, savedNum, 'now & save & 다운');
      savedNum = nowScrollTop;
      setScrolled(1);
    } else if (nowScrollTop - savedNum < 0 && scrolled !== -1) {
      // console.log(nowScrollTop, savedNum, 'now & save & 밖');
      savedNum = nowScrollTop;
      setScrolled(-1);
    }
  }, 300);
  const useMemoedThrottle = useMemo(
    () => throttledDetectScrollDirection,
    [throttledDetectScrollDirection],
  );

  return [scrolled, useMemoedThrottle];
}
