export default function getLocalStorage() {
  const secureappToken = JSON.parse(localStorage.getItem('secureappToken'));
  const token = secureappToken?.secureappToken;
  const tokenBool =
    secureappToken &&
    secureappToken.expiresAt &&
    secureappToken.expiresAt > new Date().getTime();

  return { secureappToken, token, tokenBool };
}
