import V from 'assets/browserImg/footerImg/v.png';
import React, { useState } from 'react';
import { useCallback } from 'react';
import { useRef } from 'react';
import styled from 'styled-components';

export default function Select() {
  const wrapperRef = useRef(null);
  const itemRef = useRef(null);
  const [isCollapse, setIsCollapse] = useState(false);
  const handleBtn = useCallback((e) => {
    const wrapper = wrapperRef.current;
    const item = itemRef.current;
    e.stopPropagation();
    if (!wrapper || !item) return;

    if (wrapper.clientHeight > 0) wrapper.style.height = 0;
    else wrapper.style.height = `${item.clientHeight}px`;

    setIsCollapse((collapse) => !collapse);
  }, []);
  return (
    <SelectArea>
      <OptionDefault onClick={handleBtn} isCollapse={isCollapse}>
        <div>시큐어앱 Family Site</div>
        <div>
          <img src={V} alt="NO_IMAGE" />
        </div>
      </OptionDefault>

      <OptionArea ref={wrapperRef}>
        <OptionWrapper ref={itemRef}>
          {/* <a href="https://www.bohome.co.kr/" target="_blank" rel="noreferrer">
            <OptionItem>피해예방 커뮤니티 보호미</OptionItem>
          </a> */}
          <a>
            <OptionItem>공식카카오톡채널</OptionItem>
          </a>
          <a
            href="https://blog.naver.com/secureapp112"
            target="_blank"
            rel="noreferrer"
          >
            <OptionItem>공식블로그</OptionItem>
          </a>
          <a>
            <OptionItem>공식포스팅</OptionItem>
          </a>
          <a
            href="https://www.youtube.com/channel/UCnHKB50MecAwTrXkJsCoxRA"
            target="_blank"
            rel="noreferrer"
          >
            <OptionItem>공식유튜브</OptionItem>
          </a>
        </OptionWrapper>
      </OptionArea>
    </SelectArea>
  );
}

const SelectArea = styled.div`
  position: relative;
  border: none;
  z-index: 1;
  cursor: pointer;
`;

const OptionDefault = styled.h4`
  opacity: 0.8;
  color: #ffffff;

  display: flex;
  border: 1px solid #888d94;
  border-top: none;
  /* margin-bottom: -1px; */
  padding: 0.8em 1.2em;
  gap: 70px;

  div:nth-of-type(2) {
    img {
      rotate: ${({ isCollapse }) => isCollapse && '180deg'};
      transition: rotate 0.8s ease;
    }
  }
`;

const OptionArea = styled.div`
  height: 0;
  width: 100%;
  overflow: hidden;
  transition: height 0.35s ease;
  background-color: #111b2a;
  border-bottom: 0;

  position: absolute;
`;

const OptionWrapper = styled.div`
  /* // 임시
  color: #ffffff;
  border: 1px solid #ffffff;
  position: absolute;
  background-color: red;
  /* height: ${({ isOpen }) => (isOpen ? '100%' : '0')}; */
  /* overflow: ${({ isOpen }) => (isOpen ? 'hidden' : 'auto')}; */
  /* transition: height 1s ease; */
`;

const OptionItem = styled.div`
  color: #738298;
  font-weight: 300;
  border: 1px solid #888d94;
  border-top: none;
  padding: 0.8em 1.2em;

  &:hover {
    color: #d3deee;
    background-color: #404854;
  }
`;
