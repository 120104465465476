import { getAnalysisList } from 'api/customAxios';
import ANLB from 'assets/mobileImg/analysis/anlB.png';
import ANLBTOP from 'assets/mobileImg/analysis/anlTop.png';
import ANLV from 'assets/mobileImg/analysis/anlV.png';
import { apkDataState } from 'atoms/apkDataState';
import { apkSearchKeywordState } from 'atoms/apkSearchKeywordState';
import { detailBoolState } from 'atoms/detailBoolState';
import { mobileInputFoucsState } from 'atoms/mobileInputFocusState';
import { totalElementsState } from 'atoms/totalElementsState';
import { totalPagesState } from 'atoms/totalPagesState';
import PaginationLIB from 'components/pagination/paginationLIB';
import useFocus from 'hooks/useFocus';
import debounce from 'lodash.debounce';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import Card from './Card';
export default function Analysis({ children }) {
  const [inputFocus, setSearchInputFocus] = useFocus();
  const [detailBool, setDetailBool] = useRecoilState(detailBoolState);

  const [apkSearchKeyword, setApkSearchKeyword] = useRecoilState(
    apkSearchKeywordState,
  );
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [apkData, setApkData] = useRecoilState(apkDataState);
  const [totalElements, setTotalElements] = useRecoilState(totalElementsState);
  const [totalPages, setTotalPages] = useRecoilState(totalPagesState);

  const debouncedsetApkSearchKeyword = debounce(setApkSearchKeyword, 200);
  const setMobileInputFoucs = useSetRecoilState(mobileInputFoucsState);

  const 임시배열 = new Array(9).fill(0);

  const handlePageChange = (cPage) => {
    setPageNo(cPage - 1);
  };

  const getApkList = () => {
    try {
      (async () => {
        const { data } = await getAnalysisList(
          pageNo,
          pageSize,
        )(apkSearchKeyword);
        setApkData(data);
        setTotalElements(data.totalElements);
        setTotalPages(data.totalPages);
      })();
    } catch (e) {
      alert(`
      ${e}
      내부 오류로인해 게시글을 받아오지 못했습니다.
      `);
    }
  };

  const getSearchApk = (e) => {
    e.preventDefault();
    try {
      (async () => {
        const { data } = await getAnalysisList(
          pageNo,
          pageSize,
        )(apkSearchKeyword);
        setApkData(data);
        setTotalElements(data.totalElements);
        setTotalPages(data.totalPages);
      })();
    } catch (e) {
      alert(`
      ${e}
      내부 오류로인해 게시글을 받아오지 못했습니다.
      `);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getApkList, [pageNo, pageSize]);

  useEffect(() => {}, []);

  const arr = new Array(5).fill(0);

  return (
    <AnalysisArea>
      <AnlImgBox>
        <img src={ANLBTOP} alt="NO_IMAGE" />
      </AnlImgBox>
      <AnlImgBox style={{ paddingTop: '2em' }}>
        <img src={ANLV} alt="NO_IMAGE" />
      </AnlImgBox>
      {detailBool ? (
        <>{children}</>
      ) : (
        <>
          <SearchWrapper onSubmit={getSearchApk}>
            <input
              onChange={(e) => {
                debouncedsetApkSearchKeyword(e.target.value);
              }}
              ref={inputFocus}
              onFocus={() => {
                setSearchInputFocus((prev) => !prev);
              }}
              onBlur={() => {
                setSearchInputFocus((prev) => !prev);
                setMobileInputFoucs(false);
              }}
              placeholder={apkSearchKeyword}
            />
            <button type="submit">검색</button>
          </SearchWrapper>
          {apkData?.content?.map((apk) => (
            <Card key={apk.id} apk={apk} setDetailBool={setDetailBool} />
          ))}
          <div style={{ padding: '1em 0 2em 0' }}>
            <PaginationLIB
              handlePageChange={handlePageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              pageNo={pageNo}
              pageSize={pageSize}
            />
          </div>
        </>
      )}
      <AnlImgBox>
        <img src={ANLB} alt="NO_IMAGE" />
      </AnlImgBox>
    </AnalysisArea>
  );
}

const AnalysisArea = styled.div``;
const AnlImgBox = styled.div`
  width: 100%;
  img {
    min-width: 100%;
    max-width: 100%;
  }
`;
const SearchWrapper = styled.form`
  border-bottom: 1px solid #ffffff !important;
  background-color: #b8b8b8;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  padding: 1em 0.8em 1em 0.8em;

  input {
    flex: 1;
    padding: 0.3em 0.2em 0.5em 0.8em;
    max-width: 80%;

    &::placeholder {
      font-size: 0.8rem;
      color: #9e9e9e;
    }
  }

  button {
    background: #1588dc;
    color: #ffffff;
    font-size: 0.9rem;
    padding: 0.8em 1.5em 0.8em 1.5em;
    min-width: 69px;
  }
`;
