import { deleteIssue, getIssue, getIssueList } from 'api/customAxios';
import PaginationLIB from 'components/pagination/paginationLIB';
import debounce from 'lodash.debounce';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export default function IssueList({
  setPutStatus,
  setDetailData,
  setAdminNavNum,
}) {
  const [issueSearchKeyword, setIssueSearchKeyword] = useState('');
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [issueData, setIssueData] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const debouncedsetIssueSearchKeyword = debounce(setIssueSearchKeyword, 200);

  const 임시배열 = new Array(9).fill(0);

  const handlePageChange = (cPage) => {
    setPageNo(cPage - 1);
  };

  const getIssueLists = () => {
    try {
      (async () => {
        const { data } = await getIssueList(
          pageNo,
          pageSize,
        )(issueSearchKeyword);
        setIssueData(data);
        setTotalElements(data.totalElements);
        setTotalPages(data.totalPages);
      })();
    } catch (e) {
      alert(`
      ${e}
      내부 오류로인해 게시글을 받아오지 못했습니다.
      `);
    }
  };
  const issueSearchApk = (e) => {
    e.preventDefault();
    try {
      (async () => {
        const { data } = await getIssueList(
          pageNo,
          pageSize,
        )(issueSearchKeyword);
        setIssueData(data);
        setTotalElements(data.totalElements);
        setTotalPages(data.totalPages);
      })();
    } catch (e) {
      alert(`
      ${e}
      내부 오류로인해 게시글을 받아오지 못했습니다.
      `);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getIssueLists, [pageNo, pageSize, deleteStatus]);

  const deleteIssueData = async (id) => {
    try {
      await deleteIssue(id);
      setDeleteStatus((del) => !del);
      alert('삭제 했습니다.');
    } catch (e) {
      alert(`삭제에 실패했습니다.
      로그인 만료시간을 확인해주세요.`);
    }
  };

  const putIssue = async (id) => {
    try {
      const { data } = await getIssue(id)();
      setDetailData(data);
      setPutStatus(true);
      setAdminNavNum(6);
    } catch (e) {
      alert(e);
    }
  };
  const arr = new Array(30).fill(0);
  return (
    <IssueListArea>
      {/* <SearchArea >
          <div>
            <input
              onChange={(e) => {
                debouncedsetIssueSearchKeyword(e.target.value);
              }}
            />
          </div>
          <SearchBtn type="submit">검색</SearchBtn>
        </SearchArea> */}
      {issueData?.content?.map((issue) => (
        // {arr.map((issue) => (
        <InfoArea key={issue.id}>
          <div>
            <div>
              <img
                src={`https://secureappimgbucket.s3.ap-northeast-2.amazonaws.com/${issue?.filepath}`}
                alt="NO_IMAGE"
              />
            </div>
            <p>{issue?.desc}</p>
          </div>
          <div>
            <p>{issue?.title}</p>
            <p>{issue?.createdDate?.slice(0, 10)}</p>
          </div>
          <div>
            <button onClick={() => putIssue(issue.id)}>수정</button>
            <button onClick={() => deleteIssueData(issue.id)}>삭제</button>
          </div>
        </InfoArea>
      ))}

      <div>
        <PaginationLIB
          handlePageChange={handlePageChange}
          totalElements={totalElements}
          totalPages={totalPages}
          pageNo={pageNo}
          pageSize={pageSize}
        />
      </div>
    </IssueListArea>
  );
}

const IssueListArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SearchArea = styled.form`
  background-color: #ececec;
  width: 945.45px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4em 0 0.4em 0;
  margin-bottom: 0.6em;
  border-radius: 10px;
  gap: 10px;

  div {
    position: relative;
    input {
      width: 560.72px;
      height: 28.63px;
      padding-left: 1.1em;
    }

    span {
      position: absolute;
      left: 3%;
      bottom: 32%;
      color: #7a7a7a;
      font-size: 0.9rem;

      b {
        color: #1588dc;
      }
    }
  }
`;

const SearchBtn = styled.button`
  background: #1588dc;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;

  padding: 0.6em 2.2em;
  font-size: 0.8rem;
  font-weight: 300;
`;
const InfoArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  border-bottom: 0.5px solid grey;
  padding: 0.4em 0;

  div:nth-of-type(1) {
    display: flex;
    align-items: center;
    gap: 8px;

    div {
      img {
        /* background-color: red; */
        width: 30px;
        height: 30px;
        overflow: hidden;
      }
    }
    p {
      cursor: pointer;
      width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        font-weight: bold;
      }
    }
  }
  div:nth-of-type(2) {
    display: flex;
    align-items: center;
    gap: 15px;

    p:nth-of-type(1) {
      width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: bold;
    }
  }
  div:nth-of-type(3) {
    display: flex;
    align-items: center;
    gap: 8px;

    button {
      background-color: grey;
      border: 1px solid black !important;
      border-radius: 5px;
      font-size: 0.9rem;
      padding: 0.1em;
      color: #ffffff;

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
`;
