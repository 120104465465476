import BoxBlock from 'browser/components/boxBlock';
import MainNavBox from 'components/mainNavBox';
import React from 'react';
import styled from 'styled-components';

import SolutionInfo from './solutionInfo';

export default function SolutionBlock() {
  return (
    <SolutionBlockArea>
      <MainNavBox title={'SOLUTION'} link="/solution" />
      <BoxBlock>
        <SolutionInfo />
      </BoxBlock>
    </SolutionBlockArea>
  );
}

const SolutionBlockArea = styled.div``;
