import BoxBlock from 'browser/components/boxBlock';
import MainNavBox from 'components/mainNavBox';
import React from 'react';
import styled from 'styled-components';

import SnsInfo from './snsInfo';

export default function SnsBlock() {
  return (
    <SnsBlockArea>
      <MainNavBox title={'SNS'} link="" />
      <BoxBlock>
        <SnsInfo />
      </BoxBlock>
    </SnsBlockArea>
  );
}

const SnsBlockArea = styled.div``;
