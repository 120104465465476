import SnsBoxTitle from 'components/snsBoxTitle';
import React from 'react';
import styled from 'styled-components';

export default function SnsBox({ children, desc, link, isMobile }) {
  return (
    <SnsBoxArea>
      <SnsBoxTitle desc={desc} link={link} isMobile={isMobile} />
      <div>{children}</div>
    </SnsBoxArea>
  );
}

const SnsBoxArea = styled.div``;
