import { getAnalysisList } from 'api/customAxios';
import PROFESSIONAL from 'assets/browserImg/analysis/professional.png';
import TopImg from 'assets/browserImg/analysis/topImg.png';
import TopBk from 'assets/browserImg/analysis/topImg1.png';
import { apkDataState } from 'atoms/apkDataState';
import { apkSearchKeywordState } from 'atoms/apkSearchKeywordState';
import { totalElementsState } from 'atoms/totalElementsState';
import { totalPagesState } from 'atoms/totalPagesState';
import Layout from 'browser/components/layout';
import PaginationLIB from 'components/pagination/paginationLIB';
import useFocus from 'hooks/useFocus';
import debounce from 'lodash.debounce';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import Card from './card/card';

export default function Analysis({ children }) {
  const [inputFocus, setSearchInputFocus] = useFocus();
  const [nullText, setNullTest] = useState(true);
  const [apkSearchKeyword, setApkSearchKeyword] = useRecoilState(
    apkSearchKeywordState,
  );
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(9);
  const [apkData, setApkData] = useRecoilState(apkDataState);
  const [totalElements, setTotalElements] = useRecoilState(totalElementsState);
  const [totalPages, setTotalPages] = useRecoilState(totalPagesState);

  const debouncedsetApkSearchKeyword = debounce(setApkSearchKeyword, 100);

  const 임시배열 = new Array(9).fill(0);

  const handlePageChange = (cPage) => {
    setPageNo(cPage - 1);
  };

  const getApkList = () => {
    try {
      (async () => {
        const { data } = await getAnalysisList(
          pageNo,
          pageSize,
        )(apkSearchKeyword);
        setApkData(data);
        setTotalElements(data.totalElements);
        setTotalPages(data.totalPages);
      })();
    } catch (e) {
      alert(`
      ${e}
      내부 오류로인해 게시글을 받아오지 못했습니다.
      `);
    }
  };
  const getSearchApk = (e) => {
    e.preventDefault();

    try {
      (async () => {
        const { data } = await getAnalysisList(
          pageNo,
          pageSize,
        )(apkSearchKeyword);
        setApkData(data);
        setTotalElements(data.totalElements);
        setTotalPages(data.totalPages);
      })();
    } catch (e) {
      alert(`
      ${e}
      내부 오류로인해 게시글을 받아오지 못했습니다.
      `);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getApkList, [pageNo, pageSize]);
  useEffect(() => {
    if (apkSearchKeyword === '') setNullTest(true);
    else setNullTest(false);
  }, [apkSearchKeyword]);

  const { pathname } = useLocation();
  const focus = useRef(null);
  useEffect(() => {
    focus.current.focus();
  }, [pathname]);

  return (
    <Layout>
      <AnalysisArea>
        <div ref={focus} tabIndex={0}>
          <img src={TopBk} alt="NO_IMAGE" style={{ maxWidth: '100%' }} />
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            margin: '1.8em 0',
          }}
        >
          <img src={TopImg} alt="NO_IMAGE" style={{ width: '75%' }} />
        </div>
        {children}
        <SearchArea onSubmit={getSearchApk}>
          <div>
            <input
              onChange={(e) => {
                debouncedsetApkSearchKeyword(e.target.value);
                setNullTest(false);
              }}
              ref={inputFocus}
              onFocus={() => {
                setSearchInputFocus(true);
              }}
              onBlur={() => {
                setSearchInputFocus(false);
              }}
              placeholder={apkSearchKeyword}
            />
            {nullText && (
              <span
                onClick={() => {
                  setSearchInputFocus(true);
                }}
              >
                <b>피해파일명</b>을 입력해주세요
              </span>
            )}
          </div>
          <SearchBtn type="submit">검색</SearchBtn>
        </SearchArea>

        <CardArea>
          {apkData?.content?.map((apk) => (
            <Card key={apk.id} apk={apk} />
          ))}
        </CardArea>

        <div>
          <PaginationLIB
            handlePageChange={handlePageChange}
            totalElements={totalElements}
            totalPages={totalPages}
            pageNo={pageNo}
            pageSize={pageSize}
          />
        </div>
        <ProgressWrapper>
          <img src={PROFESSIONAL} alt="NO_IMAGE" />
          <a
            href={'https://open.kakao.com/o/sQNaRZGc'}
            target="_blank"
            rel="noreferrer"
          >
            .
          </a>
          <a href="#d">.</a>
          <a href="tel:1661-7382">.</a>
          <Link to="/contact">.</Link>
        </ProgressWrapper>
      </AnalysisArea>
    </Layout>
  );
}

const AnalysisArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SearchArea = styled.form`
  background-color: #ececec;
  width: 945.45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5em 0 2em 0;
  margin-bottom: 0.6em;
  border-radius: 10px;
  gap: 10px;

  div {
    position: relative;
    input {
      width: 560.72px;
      height: 43.63px;
      padding-left: 1.1em;
    }

    span {
      position: absolute;
      left: 3%;
      bottom: 32%;
      color: #7a7a7a;
      font-size: 0.9rem;

      b {
        color: #1588dc;
      }
    }
  }
`;
const CardArea = styled.div`
  width: 945.45px;

  margin: 1em 0;

  display: flex;
  justify-content: flex-start;
  gap: 1.7em;

  flex-wrap: wrap;
`;

const SearchBtn = styled.button`
  background: #1588dc;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;

  padding: 1.15em 2.2em;
  font-size: 0.8rem;
  font-weight: 300;
`;

const ProgressWrapper = styled.div`
  background: linear-gradient(180deg, #f2faff 0%, rgba(138, 205, 254, 0) 100%);
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 4.5em 0;
  margin-top: 3.5em;

  img {
    width: 945.45px;
  }

  a:nth-of-type(1) {
    position: absolute;
    height: 62px;
    width: 223px;
    z-index: 1;
    opacity: 0;

    left: 17.4%;
    top: 6.2%;
  }
  a:nth-of-type(2) {
    position: absolute;
    height: 62px;
    width: 223px;
    z-index: 1;
    top: 6.2%;
    left: 34%;
    opacity: 0;
  }
  a:nth-of-type(3) {
    height: 62px;
    width: 223px;
    z-index: 1;
    top: 6.2%;
    left: 50.5%;
    position: absolute;
    opacity: 0;
  }
  a:nth-of-type(4) {
    height: 62px;
    width: 223px;
    z-index: 1;
    top: 6.2%;
    opacity: 0;
    position: absolute;
    left: 67%;
  }
`;
