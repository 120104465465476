import reset from 'styled-reset';

const { createGlobalStyle } = require('styled-components');

export const GlobalStyle = createGlobalStyle`
  ${reset}

    :root {
        --font-size-base: 1rem;
        --font-size-md: 1.125rem;
        --font-size-l: 1.25rem;

        --font-weight-base: 400;
        --font-weight-md: 500;
        --font-weight-l: 700;

        --font-color-0059AB: #0059AB;
        --font-color-1588DC: #1588DC;
        --font-color-354660: #354660;
        --font-color-ffffff: #ffffff;
        --font-color-203F6B: #203F6B;

        --background-color-base: #ffffff;
        --background-color-1588DC: #1588DC;

        --width-br-base: 1440px;
        --width-br-block: 945.45px;
    }
`;
