import { headerHideState } from 'atoms/headerHideState';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

export default function useVisible() {
  const setHeaderHide = useSetRecoilState(headerHideState);
  const handleOnWheel = (e) => {
    if (e.nativeEvent.wheelDelta > 0) {
      // scroll up event
      setHeaderHide(false);
    } else {
      // scroll down event
      setHeaderHide(true);
    }
  };

  return handleOnWheel;
}
