// import useGetRecoilToken from "hooks/useGetRecoilToken";

import getLocalStorage from 'utils/getLocalStrorage';

export const interceptors = (instance) => {
  instance.interceptors.request.use(
    (config) => {
      // 훅 사용이 문제됨
      //   const secureToken = useGetRecoilToken();
      const { token, tokenBool } = getLocalStorage();

      config.headers = {
        Authorization: tokenBool ? token : null,
      };

      return config;
    },
    (error) => Promise.reject(error.response),
  );
  return instance;
};
