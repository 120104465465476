import './map.css';

import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const { kakao } = window;
const position = new kakao.maps.LatLng(37.38188218066195, 126.66177252641464);
const mapOptions = {
  center: position, // 지도의 중심좌표
  level: 4, // 지도의 확대 레벨
};
export default function UseMap({ isMarker }) {
  const mapContainer = useRef(null);
  // 커스텀 오버레이에 표출될 내용
  const content =
    isMarker &&
    `
      <div class="customoverlay">
        <span><b>M</b>동 <b>2202</b>호</span>
        <div class="img_sub">
      </div>`;
  const onOverlay = () => {
    const map = new kakao.maps.Map(mapContainer.current, mapOptions);
    const marker = new kakao.maps.Marker({ position }); // 마커 생성

    // 커스텀 오버레이 생성
    new kakao.maps.CustomOverlay({
      map,
      position,
      content,
    });

    // 마커가 지도 위에 표시되도록 설정
    marker.setMap(map);
  };

  useEffect(onOverlay, [isMarker, content]);

  return (
    <MapArea className="Map">
      <MapWrapper className="MapContainer" id="map" ref={mapContainer} />
    </MapArea>
  );
}
const MapArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
