import useNaviCustom from 'hooks/useNaviCustom';
import React from 'react';
import styled from 'styled-components';

export default function List({ issue, i }) {
  const [pathname, navigate] = useNaviCustom();
  return (
    <ListArea borderT={i}>
      <LeftBox>
        <span>issue</span>
        <p onClick={() => navigate(`/press/${issue?.id}`)}>{issue?.title}</p>
      </LeftBox>
      <p>{issue?.createdDate?.slice(0, 10)}</p>
    </ListArea>
  );
}

const ListArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 360px;
  border-bottom: 0.5px solid #9e9e9e !important;
  border-top: ${({ borderT }) =>
    borderT === 0 ? '0.5px solid #9e9e9e' : 'none'};

  padding: 0.88em 0;

  p {
    color: #7a7a7a;
    font-weight: 300;
    font-size: 0.8rem;
  }
`;

const LeftBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    width: 37px;
    text-align: left;
    color: #d81a26;
    overflow: hidden;
    font-weight: 500;
    font-size: 0.9rem;
  }

  p {
    font-weight: 400;
    opacity: 0.8;
    font-size: 0.9rem;
    color: #434343;

    width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
`;
