import {
  alignCenterDisplay,
  betweenDisplay,
  centerDisplay,
} from 'assets/styles/styled/baseStyles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function MainNavBox({ title, link = '' }) {
  return (
    <MainNavBoxArea>
      <MainNavBoxWrapper>
        <TitleBox>
          <Dot />
          <Title>{title}</Title>
        </TitleBox>
        <Link to={link}>
          <LinkTxt>{link && '+ 더보기'}</LinkTxt>
        </Link>
      </MainNavBoxWrapper>
    </MainNavBoxArea>
  );
}

const MainNavBoxArea = styled.div`
  width: 100%;
  ${centerDisplay}
`;

const MainNavBoxWrapper = styled.div`
  width: var(--width-br-block);
  ${betweenDisplay}
  background-color: #ffffff;
  padding: 0.6em 0;
`;

const Dot = styled.span`
  display: inline-block;
  width: 0.35rem;
  height: 0.35rem;
  background-color: var(--font-color-203F6B);
  margin-right: 0.5em;
  /* margin-bottom: 0.3em; */
  rotate: 45deg;
`;

const Title = styled.span`
  display: inline-block;
  color: var(--font-color-203F6B);
  font-weight: var(--font-weight-l);
`;

const TitleBox = styled.div`
  ${alignCenterDisplay}
`;

const LinkTxt = styled.span`
  display: inline-block;
  color: var(--font-color-203F6B);
  font-weight: var(--font-weight-md);
`;
