import KAKAO from 'assets/browserImg/contact/kakao.png';
import KAKAOC from 'assets/browserImg/contact/kakaoC.png';
import TELL from 'assets/browserImg/contact/tell.png';
import TopBk from 'assets/browserImg/contact/topBk.png';
import Layout from 'browser/components/layout';
import ImgBox from 'components/imgBox/imgBox';
import Map from 'components/map/map';
import UseMap from 'components/map/useMap';
import React, { useState } from 'react';
import styled from 'styled-components';

import SubmitArea from './submitArea';

export default function Contact() {
  const [isMarker, setIsMarker] = useState(false);
  const markerState = () => {
    setIsMarker((marker) => !marker);
  };
  return (
    <Layout>
      <ContactArea>
        <ImgBox imgPath={TopBk} />
        <TitleBox>
          <h1>긴급온라인상담</h1>
        </TitleBox>
        <div>
          <SubmitArea />
        </div>
        <ImgWrapper>
          <a
            href={'https://open.kakao.com/o/sQNaRZGc'}
            target="_blank"
            rel="noreferrer"
          >
            <img src={KAKAO} alt="NO_IMAGE" />
          </a>

          <a
            href={'https://pf.kakao.com/_RirJxj'}
            target="_blank"
            rel="noreferrer"
          >
            <img src={KAKAOC} alt="NO_IMAGE" />
          </a>
          <a href="tel:1661-7382">
            <img src={TELL} alt="NO_IMAGE" />
          </a>
        </ImgWrapper>
        <MapArea>
          <MapTitleWrapper>
            <span>
              <b>시큐어앱</b> 오시는 길
            </span>
            <button
              type="button"
              onClick={markerState}
              style={{ cursor: 'pointer' }}
            >
              <span>
                <b>Map</b>으로 찾기
              </span>
            </button>
          </MapTitleWrapper>
          <MapWrapper>
            {/* <Map /> */}
            <UseMap isMarker={isMarker} />
          </MapWrapper>
          <SecureappInfoWrapper>
            <p>
              인천광역시 연수구 송도 과학로 32 테크노IT센터 <b>M동 2202호</b>
            </p>
            <div>
              <p>주차타워(1시간 무료 및 주차증 제공) </p>
              <p>
                <b>지하, 지상 1, 3, 5층 엘리베이터</b>를 이용하시면 편리합니다.
              </p>
            </div>
          </SecureappInfoWrapper>
        </MapArea>
      </ContactArea>
    </Layout>
  );
}

const ContactArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleBox = styled.div`
  margin-top: 2.5em;
  margin-bottom: 4.5em;
  h1 {
    font-weight: 700;
    font-size: 3rem;

    color: #35455f;
  }
`;

const MapArea = styled.div`
  width: 727.27px;
  margin-bottom: 6em;
  margin-top: 3em;
`;

const MapWrapper = styled.div`
  width: 100%;
  height: 427.6px;
`;

const MapTitleWrapper = styled.div`
  background: linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
  padding: 0.8em 1em 0.6em 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: #434343;
    font-size: 0.7rem;
    b {
      font-size: 0.9rem;
      font-weight: 500;
    }
  }
  button {
    background-color: #67aaed;
    padding: 0.1em 0.6em 0.3em 0.6em;
    span {
      color: #ffffff;
      font-size: 0.5rem;
      b {
        font-size: 0.6rem;
      }
    }
  }
`;

const SecureappInfoWrapper = styled.div`
  padding: 1.8em 1em 1.8em 1em;
  font-size: 0.8rem;
  margin-top: -2px;

  display: flex;
  flex-direction: column;
  gap: 2em;

  background: linear-gradient(
    180deg,
    #d5d5d5 0%,
    rgba(235, 235, 235, 0.869792) 13.02%,
    #ebebeb 99.99%,
    rgba(235, 235, 235, 0) 100%
  );

  p {
    line-height: 16px;
  }

  b {
    font-weight: 500;
  }

  p:nth-of-type(2) {
    b {
      color: #1588dc;
    }
  }
`;

const ImgWrapper = styled.div`
  display: flex;
  width: 729.27px;
  margin-top: 5em;

  a {
    &:hover {
      opacity: 0.8;
    }
    img {
      max-width: 98%;
    }
  }
`;
