import './slick_css.css';

import SlickCarousel from 'components/carousel/slickCarousel';
import React from 'react';
import styled from 'styled-components';

export default function SnsCarousel({ carouselMap, settings }) {
  return (
    <CarouselArea>
      <SlickCarousel
        carouselMap={carouselMap}
        settings={settings}
        dotsClass="dots_custom"
      />
    </CarouselArea>
  );
}

const CarouselArea = styled.div`
  /* margin-left: auto;
  margin-right: auto; */
  width: 100%;
  max-width: 100%;
  min-width: 100%;
`;
