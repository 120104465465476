import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React from 'react';
import Slider from 'react-slick';

import CarouselItem from './carouseltem';

export default function SlickCarousel({ carouselMap, settings, dotsClass }) {
  return (
    <Slider {...settings} dotsClass={dotsClass}>
      {carouselMap?.map((carouselItem) => (
        <CarouselItem key={carouselItem.id} cItem={carouselItem.cItem} />
      ))}
    </Slider>
  );
}
