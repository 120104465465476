import { GlobalStyle } from 'assets/styles/styled/GlobalStyle';
import Browser from 'browser/browser';
import UseScrollToTop from 'hooks/useScrollToTop';
import Mobile from 'mobile/mobile';
import React from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import styled from 'styled-components';

function App() {
  const viewTypeObj = {
    broswer: <Browser />,
    mobile: <Mobile />,
    exception: <Mobile />,
  };

  const onViewRender = () => {
    if (isBrowser) return viewTypeObj['broswer'];
    if (isMobile) return viewTypeObj['mobile'];
    return viewTypeObj['exception'];
  };

  const { NODE_ENV } = process.env;
  // 환경변수의 경우 반환값이 항상 string

  const isPro = NODE_ENV === 'production';
  const isDev = NODE_ENV === 'development';
  const isTest = NODE_ENV === 'test';

  if (isDev) {
    // 개발자 모드
    console.log('개발');
  } else if (isPro) {
    //프로덕션 모드
    console.log('프로덕션');
  } else if (isTest) {
    //테스트 모드
    console.log('테스트');
  } else {
    // 에러!!
    console.log('예외');
  }

  return (
    <AppArea>
      <GlobalStyle />
      <UseScrollToTop />
      {onViewRender()}
    </AppArea>
  );
}

const AppArea = styled.div`
  @font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 100;
    src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2)
        format('woff2'),
      url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff)
        format('woff'),
      url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf)
        format('opentype');
  }

  @font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 300;
    src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2)
        format('woff2'),
      url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff)
        format('woff'),
      url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf)
        format('opentype');
  }

  @font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2)
        format('woff2'),
      url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff)
        format('woff'),
      url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf)
        format('opentype');
  }

  @font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2)
        format('woff2'),
      url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff)
        format('woff'),
      url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf)
        format('opentype');
  }

  @font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2)
        format('woff2'),
      url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff)
        format('woff'),
      url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf)
        format('opentype');
  }

  @font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 900;
    src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2)
        format('woff2'),
      url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff)
        format('woff'),
      url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf)
        format('opentype');
  }

  * {
    font-family: 'Noto Sans KR', sans-serif;
  }
`;

export default App;
