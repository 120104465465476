export const PATH_MAP = [
  {
    id: 1,
    path: '/contact',
    title: 'CONTACT',
  },
  {
    id: 2,
    path: '/solution',
    title: 'SOLUTION',
  },
  {
    id: 3,
    path: '/analysis',
    title: 'ANALYSIS',
  },
  {
    id: 4,
    path: '/press',
    title: 'PRESS',
  },
  {
    id: 5,
    path: '/company',
    title: 'COMPANY',
  },
];
