import { getIssueList } from 'api/customAxios';
import { issueDetailBoolState } from 'atoms/issueDetailBoolState';
import useNaviCustom from 'hooks/useNaviCustom';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

export default function PressInfo() {
  const arr = new Array(5).fill(0);
  const [detailBool, setDetailBool] = useRecoilState(issueDetailBoolState);
  const [pathname, navigate] = useNaviCustom();
  const [issueData, setIssueData] = useState([]);

  const getIssueData = () => {
    try {
      (async () => {
        const { data } = await getIssueList(0, 5)('');
        setIssueData(data);
      })();
    } catch (e) {
      alert(`
      ${e}
      내부 오류로인해 게시글을 받아오지 못했습니다.
      `);
    }
  };
  useEffect(getIssueData, []);

  return (
    <PressInfoArea>
      {issueData?.content?.map(
        (issue, i) =>
          i < 1 && (
            <CardArea key={issue?.id}>
              <ImgWrapper>
                <img
                  src={`https://secureappimgbucket.s3.ap-northeast-2.amazonaws.com/${issue?.filepath}`}
                  alt="NO_IMAGE"
                />
              </ImgWrapper>
              <InfoWrapper>
                <p>{issue?.title}</p>
                <p>{issue?.desc}</p>
                <div>
                  <span>{issue?.createdDate?.slice(0, 10)}</span>
                  <button
                    onClick={() => {
                      navigate(`/press/${issue?.id}`);
                      setDetailBool(true);
                    }}
                  >
                    + 내용보기
                  </button>
                </div>
              </InfoWrapper>
            </CardArea>
          ),
      )}
      {issueData?.content?.map((issue) => (
        <ListWrapper key={issue?.id}>
          <span>issue</span>
          <p
            onClick={() => {
              navigate(`/press/${issue?.id}`);
              setDetailBool(true);
            }}
          >
            {issue?.title}
          </p>
        </ListWrapper>
      ))}
    </PressInfoArea>
  );
}

const PressInfoArea = styled.div``;

const CardArea = styled.div`
  background-color: #ececec;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0.9em 0.9em;
  gap: 15px;
  border-top: 0.5px solid #9e9e9e;
  border-bottom: 0.5px solid #9e9e9e !important;
`;

const ImgWrapper = styled.div`
  /* background-color: red; */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  gap: 10px;

  p:nth-of-type(1) {
    color: #434343;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.4rem;

    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;

    display: -webkit-box;
    -webkit-line-clamp: 2; // 원하는 라인수
    -webkit-box-orient: vertical;
  }
  p:nth-of-type(2) {
    color: #434343;
    font-size: 1rem;
    line-height: 1.2rem;

    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;

    display: -webkit-box;
    -webkit-line-clamp: 5; // 원하는 라인수
    -webkit-box-orient: vertical;
  }
  div:nth-of-type(1) {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    span {
      color: #9e9e9e;
      font-size: 0.8rem;
      font-weight: bold;
    }
    button {
      color: #1588dc;
      font-size: 0.9rem;
      font-weight: 500;

      background: #ffffff;
      border: 1px solid #72bbf0 !important;
      padding: 0.4em 0.6em 0.2em 0.6em;
    }
  }
`;

const ListWrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 1em 0.9em;
  border-bottom: 0.5px solid #9e9e9e !important;

  gap: 10px;

  span {
    color: #d81a26;
    font-weight: 500;
  }
  p {
    color: #434343;
    font-size: 1.125rem;

    /* width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;

    display: -webkit-box;
    -webkit-line-clamp: 1; // 원하는 라인수
    -webkit-box-orient: vertical; */
    max-width: 100%;
    width: 100%;
    line-height: 1.325rem;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
