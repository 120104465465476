import { onSignin } from 'api/customAxios';
import { recoilTokenState } from 'atoms/recoilTokenState';
import useNavi from 'hooks/useNavi';
import useSetToken from 'hooks/useSetToken';
import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';

export default function AdminSignin() {
  const navigate = useNavi();
  const setRecoilToken = useSetRecoilState(recoilTokenState);
  const [loginValue, setLoginValue] = useState({
    username: '',
    password: '',
  });
  const { username, password } = loginValue;
  const onChangeLoginValue = (e) => {
    const { value, name } = e.target;
    setLoginValue((loginData) => ({ ...loginData, [name]: value }));
  };
  const setToken = useSetToken(setRecoilToken);

  const onSigninFetch = async (e) => {
    e.preventDefault();
    try {
      const { data } = await onSignin()({
        username,
        password,
      });
      setToken(data.token);
      navigate('/admin');
    } catch (e) {
      alert('로그인 실패');
    }
  };

  return (
    <AdminSigninArea>
      <AdminSigninWrapper onSubmit={onSigninFetch}>
        <div>
          <label htmlFor="id">아이디</label>
          <input
            id="id"
            type="text"
            name="username"
            value={username}
            onChange={onChangeLoginValue}
          />
        </div>
        <div>
          <label htmlFor="password">비밀번호</label>
          <input
            id="password"
            type="password"
            name="password"
            value={password}
            onChange={onChangeLoginValue}
          />
        </div>

        <button type="submit">로그인</button>
      </AdminSigninWrapper>
    </AdminSigninArea>
  );
}

const AdminSigninArea = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AdminSigninWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3em;
    label {
      font-weight: bold;
      font-size: 1.125rem;
    }

    input {
      border: 1px solid black !important;
      width: 20rem;
      height: 2.5rem;
      padding: 0 1em;
    }
  }
  button {
    width: 100%;
    height: 2.5rem;
    background-color: #1588dc;
    color: #ffffff;
    cursor: pointer;
  }
`;
