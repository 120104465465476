import styled from 'styled-components';

export default function ImgBox({
  imgPath,
  alt = 'NO_IMAGE',
  children,
  marginBottom = '',
  marginTop = '',
  bkColor = '',
}) {
  return (
    <ImgBoxArea
      marginBottom={marginBottom}
      marginTop={marginTop}
      bkColor={bkColor}
    >
      <ImgWrapper>
        <Img src={imgPath} alt={alt} />
      </ImgWrapper>
      {children}
    </ImgBoxArea>
  );
}

const ImgBoxArea = styled.div`
  max-width: 100%;
  position: relative;

  margin-bottom: ${({ marginBottom }) => marginBottom};
  background-color: ${({ bkColor }) => bkColor};
  margin-top: ${({ marginTop }) => marginTop};
`;

const ImgWrapper = styled.div`
  /* background: ${({ imgPath }) => `url(${imgPath}) no-repeat top center`}; */
  /* background-size: cover; */
`;

const Img = styled.img`
  max-width: 100%;
`;
