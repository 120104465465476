import { detailBoolState } from 'atoms/detailBoolState';
import { navState } from 'atoms/navState';
import useNaviCustom from 'hooks/useNaviCustom';
import React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

export default function Nav() {
  const [pathname, navigate] = useNaviCustom();
  const [navStatus, setNavStatus] = useRecoilState(navState);
  const [detailBool, setDetailBool] = useRecoilState(detailBoolState);
  const onChangeStatus = () => {
    setNavStatus(false);
    setDetailBool(false);
  };
  return (
    <NavArea navStatus={navStatus}>
      <NavWrapper>
        <NavBox>
          <ul>
            <NavItem pathname={pathname} link="/contact">
              <Link to="/contact" onClick={onChangeStatus}>
                CONTACT
              </Link>
            </NavItem>
            <NavItem pathname={pathname} link="/solution">
              <Link to="/solution" onClick={onChangeStatus}>
                SOLUTION
              </Link>
            </NavItem>
            <NavItem pathname={pathname} link="/analysis">
              <Link to="/analysis" onClick={onChangeStatus}>
                ANALYSIS
              </Link>
            </NavItem>
            <NavItem pathname={pathname} link="/press">
              <Link to="/press" onClick={onChangeStatus}>
                PRESS
              </Link>
            </NavItem>
            <NavItem pathname={pathname} link="/company">
              <Link to="/company" onClick={onChangeStatus}>
                COMPANY
              </Link>
            </NavItem>
          </ul>
        </NavBox>
        <Exic onClick={() => setNavStatus(false)}>X</Exic>
      </NavWrapper>
    </NavArea>
  );
}

const NavArea = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  top: 3.5em;
  height: 100vh;
  width: 100vw;
  left: ${({ navStatus }) => (navStatus ? '0' : '100vw')};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: left 0.6s ease;
  z-index: 2;
`;

const NavWrapper = styled.nav`
  z-index: 2;
  background-color: #ffffff;
  width: 83%;
  height: 12.4em;
  position: relative;
`;

const NavBox = styled.ul`
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 4.5em;

  ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

const NavItem = styled.li`
  width: 100%;
  border-top: 1px solid rgba(53, 69, 95, 0.1) !important;

  padding: 0.8em 1em 0.85em 1em;
  font-size: 1.25rem;
  background-color: ${({ pathname, link }) =>
    pathname === link ? '#35455f' : '#ffffff'};
  a {
    color: ${({ pathname, link }) =>
      pathname === link ? '#ffffff' : '#35455f'};
    font-weight: 500;
  }

  transition: 0.6s;
`;

const Exic = styled.div`
  position: absolute;
  z-index: 2;
  bottom: -1.9em;
  right: 102%;
  font-size: 2.5rem;
  color: #ffffff;
`;
