import { solutionInfoOnState } from 'atoms/solutionInfoOnState';
import { solutionInfoState } from 'atoms/solutionInfoState';
import useNavi from 'hooks/useNavi';
import React from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

export default function CarouselItem({
  imgPath,
  vi,
  title,
  desc,
  btnTitle,
  onClick,
}) {
  const [solutionInfo, setSolutionInfo] = useRecoilState(solutionInfoState);
  const [solutionInfoOn, setSolutionInfoOn] =
    useRecoilState(solutionInfoOnState);
  const navigate = useNavi();
  return (
    <CarouselItemArea>
      <DescWrapper>
        <div>
          <img src={imgPath} alt="NO_IMAGE" />
        </div>
        <h2>{title}</h2>
        <p>{desc}</p>
      </DescWrapper>
      <VideoBox>
        <video
          width="100%"
          preload="auto"
          // height="180em"
          autoPlay
          playsInline
          muted="muted"
          poster="img.jpg"
          src={vi}
        />
      </VideoBox>
      <BtnBox>
        <button
          onClick={() => {
            navigate('/solution');
            setSolutionInfoOn(title);
            setSolutionInfo(title);
          }}
        >
          <span>
            <b>{`+ ${btnTitle}`}</b> 상세 더보기
          </span>
        </button>
      </BtnBox>
    </CarouselItemArea>
  );
}

const CarouselItemArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  width: 100%;
  box-sizing: border-box;

  padding: 3em 1em 2.5em 1em;

  @media screen and (max-width: 300px) {
    gap: 0;
  }
`;

const DescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  height: 10rem;

  h2 {
    font-weight: bold;
    font-size: 1.125rem;
    color: #434343;
  }
  p {
    font-weight: 350;
    font-size: 0.9rem;
    color: #434343;
    text-align: center;
  }
`;

const BtnBox = styled.div`
  button {
    background: #1588dc;
    border-radius: 10px;
    padding: 1em 1.5em 0.8em 1.5em;
    cursor: pointer;
    span {
      color: #ffffff;
      font-size: 1.125rem;

      b {
        font-weight: bold;
      }
    }
  }

  @media screen and (max-width: 300px) {
    button {
      padding: 0.8em 1em 0.6em 1em;

      span {
        font-size: 0.8rem;
      }
    }
  }
`;

const VideoBox = styled.div`
  height: 13em;

  video {
    height: 10em;
  }
  @media screen and (max-width: 300px) {
    height: 8em;
    video {
      /* height: 180em !important; */
      height: 10em;
    }
    margin-bottom: 2em;
  }
`;
