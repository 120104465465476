import { postReport } from 'api/customAxios';
import { reportDataState } from 'atoms/reportDataState';
import Law from 'components/law';
import debounce from 'lodash.debounce';
import { useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import styled from 'styled-components';

export default function SubmitArea() {
  const [reportData, setReportData] = useRecoilState(reportDataState);
  const resetReportData = useResetRecoilState(reportDataState);
  const [passwordCheck, setPasswordCheck] = useState('');

  const {
    name,
    birthday,
    victimNumber,
    phoneModel,
    firstContactTime,
    damageOccurrenceTime,
    damageOccurrence,
    secureAppContactPath,
    content,
  } = reportData;

  const [infoBool, setInfoBool] = useState(false);

  const onChangeReportData = (e) => {
    const { value, name } = e.target;
    setReportData((report) => ({ ...report, [name]: value }));
  };

  const debouncedOnChangeReportData = debounce(onChangeReportData, 500);

  const onSubmitReportData = async (e) => {
    e.preventDefault();

    if (!infoBool) return;
    setInfoBool(false);

    if (passwordCheck !== victimNumber) {
      alert(`피해 번호를 다시 확인해주세요
      "-" 나 번호가 정확히 일치하여야 합니다.`);
      return;
    }

    try {
      await postReport()({
        name, // 성명
        birthday, // 생년월일
        victimNumber, // 피해당한 번호
        phoneModel, // 폰 기종 (아이폰, 안드로이드)
        firstContactTime, // 처음 접촉시간
        damageOccurrenceTime, // 피해발생 시간
        damageOccurrence, // 피해 금액 발생 유무
        secureAppContactPath, // 접총방식
        content, // 피해내용
      });
      resetReportData();
      alert('제출 되었습니다.');
    } catch (e) {
      alert(
        `${e} 
        정상적으로 제출되지 않았습니다. 
        다시 시도해 주세요.`,
      );
    }
  };

  return (
    <Form onSubmit={onSubmitReportData}>
      <InputWrapper>
        <InputBox>
          <Label htmlFor="name">성명</Label>
          <Input
            type="text"
            id="name"
            placeholder="이름을 입력해주세요"
            name="name"
            // value={name}
            onChange={debouncedOnChangeReportData}
            required
          />
        </InputBox>
        <InputBox>
          <Label htmlFor="birth">생년월일</Label>
          <Input
            type="text"
            id="birth"
            name="birthday"
            placeholder="생년월일을 입력해주세요"
            // value={reportData.birthday}
            onChange={debouncedOnChangeReportData}
          />
        </InputBox>
      </InputWrapper>
      <InputWrapper>
        <InputBox>
          <Label htmlFor="phone">
            상담가능번호 <span>(연락가능 번호)</span>
          </Label>
          <Input
            type="text"
            id="phone"
            placeholder="( ex: 01012345678 or 010-1234-5678 )"
            name="victimNumber"
            // value={reportData.victimNumber}
            onChange={debouncedOnChangeReportData}
            required
          />
        </InputBox>
        <InputBox>
          <Label htmlFor="phone_check">
            상담가능번호 <span>(연락가능 번호 재확인)</span>
          </Label>
          <Input
            type="text"
            id="phone_check"
            placeholder="피해 번호확인"
            value={passwordCheck}
            onChange={(e) => setPasswordCheck(e.target.value)}
            required
          />
        </InputBox>
      </InputWrapper>
      <InputBox
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          // backgroundColor: 'red',
          width: '100%',
        }}
      >
        <Label htmlFor="">휴대폰 기종</Label>
        <RadioWrapper>
          <RadioBox>
            <div>
              <Radio
                type="radio"
                id="ad"
                name="phoneModel"
                value={'안드로이드'}
                onChange={debouncedOnChangeReportData}
              />
              <label htmlFor="ad">안드로이드</label>
            </div>
            <div>
              <Radio
                type="radio"
                id="ios"
                name="phoneModel"
                value={'아이폰'}
                onChange={debouncedOnChangeReportData}
              />
              <label htmlFor="ios">아이폰</label>
            </div>
          </RadioBox>
        </RadioWrapper>
      </InputBox>
      <InputWrapper>
        <InputBox>
          <Label htmlFor="fTime">처음 접촉방식 및 시간</Label>
          <Input
            type="text"
            id="fTime"
            placeholder="( ex: 오픈채팅, 앙통, 텔레그램 )"
            name="firstContactTime"
            // value={reportData.firstContactTime}
            onChange={debouncedOnChangeReportData}
          />
        </InputBox>
        <InputBox>
          <Label htmlFor="damageTime">
            피해발생 시간 <span>(협박 발생 시간)</span>
          </Label>
          <Input
            type="text"
            id="damageTime"
            placeholder="협박 발생 시간을 입력해주세요"
            name="damageOccurrenceTime"
            // value={reportData.damageOccurrenceTime}
            onChange={debouncedOnChangeReportData}
          />
        </InputBox>
      </InputWrapper>
      <InputWrapper>
        <InputBox>
          <Label htmlFor="money">피해 금액 발생 유무 및 피해금액</Label>
          <Input
            type="text"
            id="money"
            placeholder="피해 금액을 입력해주세요"
            name="damageOccurrence"
            // value={reportData.damageOccurrence}
            onChange={debouncedOnChangeReportData}
          />
        </InputBox>
        <InputBox>
          <Label htmlFor="keyword">시큐어앱 접촉 경로 및 검색키워드</Label>
          <Input
            type="text"
            id="keyword"
            placeholder="( ex: 네이버 몸캠피싱 검색 등 )"
            name="secureAppContactPath"
            // value={reportData.secureAppContactPath}
            onChange={debouncedOnChangeReportData}
          />
        </InputBox>
      </InputWrapper>
      <InputBox>
        <Label htmlFor="content">피해내용</Label>
        <TextArea
          id="content"
          rows={8}
          cols={33}
          placeholder="최대'1000자' 입력가능합니다."
          name="content"
          //   value={reportData.content}
          onChange={debouncedOnChangeReportData}
        />
      </InputBox>
      <Info>
        {/* <p>제1장 </p>
        <br></br>
        <p>총칙제1조 </p>
        <br></br>

        <p>
          목적이 약관은 시큐어 앱에서 제공하는 서비스 이용조건 및 절차에 관한
          사항과 기타 필요한 사항을 시큐어 앱과(와) 이용자의 권리, 의미 및
          책임사항 등을 규정함을 목적으로 합니다.
        </p>
        <br></br>

        <p>제2조 약관의 효력과 변경</p> */}
        <Law />
      </Info>
      <RadioBox style={{ marginLeft: '-33.5em', marginTop: '-1em' }}>
        <div>
          <input
            type={'checkbox'}
            checked={infoBool}
            id="info"
            value={infoBool}
            onChange={() => setInfoBool((info) => !info)}
          />
          <label htmlFor="info">개인정보 취급방침 동의함</label>
        </div>
      </RadioBox>
      <SubmitBtn type="sumbit" disabled={!infoBool}>
        상담하기
      </SubmitBtn>
    </Form>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4.6em;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Input = styled.input`
  background: #f4f4f6;
  border: 1px solid #d3d3d3 !important;
  border-radius: 5px;
  padding: 0.2em 0.8em;
  width: 327.27px;
  height: 37.09;

  &::-webkit-input-placeholder {
    color: #9e9e9e;
    font-size: 0.8rem;
  }
`;

const Label = styled.label`
  font-weight: 500;
  color: #434343;
`;

const TextArea = styled.textarea`
  width: 727.27px;
  resize: none;
  background: #f4f4f6;
  border: 1px solid #d3d3d3 !important;
  border-radius: 5px;
  padding: 1em 0.8em;

  &::-webkit-input-placeholder {
    color: #9e9e9e;
  }
`;

const RadioWrapper = styled.div`
  width: 327.27px;
`;
const RadioBox = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  div {
    display: flex;
    align-items: center;
    gap: 10px;

    label {
      color: #434343;
      cursor: pointer;
    }
    input {
      cursor: pointer;
    }
  }
`;
const Radio = styled.input``;
const SubmitBtn = styled.button`
  background: #1588dc;
  border-radius: 10px;
  color: #ffffff;
  padding: 1em 2em;
  font-size: 0.9rem;
  cursor: pointer;
`;
const Info = styled.div`
  width: 727.27px;
  background: #f4f4f6;
  border: 1px solid #d3d3d3 !important;
  border-radius: 5px;
  padding: 1em 0.8em;
  height: 170px;
  margin-top: -18px;
  overflow: scroll;
  color: #9e9e9e;

  p {
  }
`;
